import React, {memo, ReactElement, useMemo} from "react";
import {EditIcon, Flex, MoreIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {ListProps} from "../List/List.types";
import {translations} from "../../../translations";
import {List} from "../List/List";
import {useAppListColumns} from "./hooks/useAppListColumns";
import {Props} from "./AppList.types";
import {LaunchpadApp} from "common";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const AppList = memo((props: Props): ReactElement | null => {
    const {confirmDeleteDialog, launchpadAppDialog} = useDialogContext();
    const columns = useAppListColumns(props);

    const actions: ListProps<LaunchpadApp>["actions"] = useMemo(() => ({
        items: [
            {
                key: "edit",
                icon: <EditIcon outline/>,
                label: translations.get("Edit"),
                onClick: app => launchpadAppDialog.dispatch("open", app?.id)()
            },
            {
                key: "delete",
                icon: <TrashCanIcon styles={{color: "red !important"}} outline/>,
                onClick: app => {
                    if (!app) return;
                    confirmDeleteDialog.dispatch("open", {
                        title: translations.get("DeleteApp"),
                        subtitle: translations.get("DeleteAppConfirmation", {name: app.name}),
                        callback: () => props.onRemoveApp(app.id)
                    })();
                },
                styles: {color: "red !important"},
                label: translations.get("Delete")
            }
        ],
        icon: <MoreIcon/>
    }), [props.onRemoveApp]);

    const items = props.apps ?? Array.from({length: 10}).map(() => null);

    return (
        <Flex fill>
            <List<LaunchpadApp>
                items={items}
                columns={columns}
                actions={actions}
                sortLocally
                noItemsMessage={translations.get("NoApp")}
            />
        </Flex>
    )
}, (pp, np) => pp.apps === np.apps);