import {State} from "./UserSettingsDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";
import {useBestPracticesCache} from "../../../hooks/cache/bestPracticesCache";
import {useChannelsStep} from "./hooks/useChannelsStep";
import {useInterestsStep} from "./hooks/useInterestsStep";
import {useJobFamilyStep} from "./hooks/useJobFamilyStep";
import {useTopicsStep} from "./hooks/useTopicsStep";

export const initialState: State = {
    isSaving: false,
    step: 0
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    updateUserData: ReturnType<typeof useUserDataCache>["updateUserData"],
    refetchBestPractices: ReturnType<typeof useBestPracticesCache>["refetch"],
    channelsStep: ReturnType<typeof useChannelsStep>,
    interestsStep: ReturnType<typeof useInterestsStep>,
    jobFamilyStep: ReturnType<typeof useJobFamilyStep>,
    topicsStep: ReturnType<typeof useTopicsStep>,
}) => ({
    open: ({setState}) => {
        setState({isSaving: false, step: 0});
        config.dialogRef.dispatch("open")();
    },
    save: async ({setState}) => {
        setState({isSaving: true});
        await config.updateUserData({
            interests: {
                topics: [...config.topicsStep.selectedItems],
                channels: [...config.channelsStep.selectedItems],
                jobFamilies: [...config.jobFamilyStep.selectedItems],
                tags: [...config.interestsStep.selectedItems],
            }
        });
        config.dialogRef.dispatch("close")();
        await config.refetchBestPractices();
    },
    nextStep: ({state, setState}) => {
        setState({step: state.step + 1});
    }
}) satisfies MagicReducerObject<State>;

const toggleListItem = (list: Immutable<Array<string>>, item: string) => {
    return list.includes(item) ? list.filter((t) => t !== item) : [...list, item];
}