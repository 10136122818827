import {cloneElement, isValidElement} from "react";
import {Immutable, ImmutableObject, Mutable} from "@witivio_teamspro/use-reducer";
import {CompareModule} from "./Compare.module";
import {UpdatedField} from "common";

const deepClone = <T>(source: Immutable<T>): Mutable<T> => {
    if (source === null || typeof source !== 'object') return source as any;
    if (isValidElement(source)) return cloneElement(source) as any;
    if (Array.isArray(source)) return source.map((item) => deepClone(item)) as any;
    const clonedObj = {} as any;
    for (const key in source) {
        // @ts-ignore
        if (source.hasOwnProperty(key)) clonedObj[key] = deepClone(source[key]);
    }
    return clonedObj as any;
}

const flattenUpdatedField = (obj: any, prefix: string = ''): UpdatedField[] => {
    return Object.entries(obj).reduce((acc: UpdatedField[], [key, value]) => {
        const newKey = prefix ? `${prefix}_${key}` : key;
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            acc.push(...flattenUpdatedField(value, newKey));
        } else {
            acc.push({field: newKey, value: JSON.stringify(value)});
        }
        return acc;
    }, []);
};

const mapUpdatedFields = <T extends object>(fields: Partial<T>, eligibleFields?: Array<keyof T>): UpdatedField[] => {
    return Object.entries(fields)
        .filter(([field]) => !eligibleFields || eligibleFields.includes(field as keyof T))
        .flatMap(([field, value]) => {
            return [{field: field.toLowerCase(), value: JSON.stringify(value ?? null)}];
        })
        .filter(({field, value}) => !!field && !!value)
};

const findUpdatedFields = <T extends object>(prevItem: Immutable<T> | undefined, newItem: Immutable<T> | undefined) => {
    const updatedFields: Partial<Immutable<T>> = {};
    if (!prevItem || !newItem) return updatedFields;
    Object.entries(newItem).forEach(([key, value]) => {
        const property = key as keyof Immutable<T>;
        const prevProperty = prevItem[property];
        const newProperty = newItem[property];
        if (typeof value === 'object' && !Array.isArray(value) && prevProperty && newProperty) {
            const objectUpdatedFields = findUpdatedFields(prevProperty as Immutable<object>, newProperty as Immutable<object>);
            Object.keys(objectUpdatedFields).forEach(subKey => {
                const subValue = objectUpdatedFields[subKey as keyof typeof objectUpdatedFields];
                if (typeof subValue === 'object' && !Array.isArray(subValue) && subValue !== null && Object.keys(subValue).length === 0)
                    delete objectUpdatedFields[subKey as keyof typeof objectUpdatedFields];
            });
            if (Object.keys(objectUpdatedFields).length) updatedFields[property] = objectUpdatedFields as Immutable<T>[typeof property];
        } else {
            const areEquals = CompareModule.areValuesEqual(prevProperty, newProperty, true);
            if (!areEquals) updatedFields[property] = value;
        }
    });
    return updatedFields;
}

const clearNullValues = <T extends object>(object: T) => {
    for (const [key, value] of Object.entries(object)) {
        const property = key as keyof T;
        if (value === null) delete object[property];
    }
    return object;
}

const isISODateString = (value: any): boolean => {
    return typeof value === 'string' && !isNaN(Date.parse(value));
}

const convertDates = <T>(item: T): T => {
    if (typeof item !== 'object' || !item) return item;
    if (Array.isArray(item)) return item.map(item => convertDates(item)) as T;
    const result: any = {};
    for (const key in item) {
        if (item.hasOwnProperty(key)) {
            const value = (item as any)[key];
            if (isISODateString(value)) {
                result[key] = new Date(value);
            } else if (typeof value === 'object') {
                result[key] = convertDates(value);
            } else {
                result[key] = value;
            }
        }
    }
    return result as T;
}

const filterByProperties = <T extends object>(
    properties: (keyof T)[]
) => (
    items: readonly ImmutableObject<ImmutableObject<T>>[] | undefined,
    filter: string,
): Immutable<T>[] => {
    const lowerFilter = filter.toLowerCase();
    return items?.filter(i => {
        for (let j = 0; j < properties.length; j++) {
            const property = properties[j];
            if (property) {
                const value = ((i[property] ?? "") + "").toLowerCase();
                if (value.includes(lowerFilter)) return true;
            }
        }
        return false;
    }) as Immutable<T>[];
}

const typedKeys = <T extends object>(obj: T): (keyof T)[] => {
    return Object.keys(obj) as (keyof T)[];
}

export const ObjectModule = {
    deepClone,
    mapUpdatedFields,
    findUpdatedFields,
    clearNullValues,
    convertDates,
    filterByProperties,
    typedKeys
}