"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeeplinkModule = void 0;
const generate = (config) => {
    const { entityId, deeplinkContext, manifestId } = config;
    let deeplink = `https://teams.microsoft.com/l/entity/${manifestId}/${entityId !== null && entityId !== void 0 ? entityId : "0"}`;
    if (!!deeplinkContext) {
        const context = encodeURIComponent(JSON.stringify({
            subEntityId: JSON.stringify(deeplinkContext),
        }));
        deeplink += `?context=${context}`;
    }
    return deeplink;
};
exports.DeeplinkModule = {
    generate,
};
