import {ItemWithExpiration, StorageType} from "./CacheService.types";

const CacheService = (storageType: StorageType) => {
    const getItem = <T>(key: string): T | null => {
        const storage = getStorage();
        const itemString = storage.getItem(key);
        if (!itemString) return null;
        let item: ItemWithExpiration<T> | T;
        try {
            item = JSON.parse(itemString) as ItemWithExpiration<T> | T;
        } catch (_) {
            item = JSON.parse("\"" + itemString + "\"") as ItemWithExpiration<T> | T;
        }
        if (isExpirationItem(item)) {
            const expirationTime = item.___itemExpirationTime___;
            if (expirationTime < Date.now()) {
                removeItem(key);
                return null;
            }
            return item.value;
        }
        return item;
    }

    const setItem = <T>(key: string, value: T, expiration?: Date) => {
        const storage = getStorage();
        try {
            if (expiration) {
                const item: ItemWithExpiration<T> = {
                    ___itemExpirationTime___: expiration.getTime(),
                    value,
                }
                storage.setItem(key, JSON.stringify(item));
            } else {
                storage.setItem(key, JSON.stringify(value));
            }
        } catch (e) {
            console.error("Can't set item in " + StorageType[storageType] + " storage");
        }
    }

    const removeItem = (key: string) => {
        const storage = getStorage();
        storage.removeItem(key);
    }

    const removeItems = (keys: Array<string>) => {
        const storage = getStorage();
        keys.forEach(key => storage.removeItem(key));
    }

    const clear = () => {
        const storage = getStorage();
        storage.clear();
    }

    const clearFromBeginKey = (beginKey: string) => {
        const storage = getStorage();
        Object.keys(storage).forEach(key => {
            if (key.startsWith(beginKey)) storage.removeItem(key);
        });
    }

    const getAndRemoveItem = <T>(key: string): T | null => {
        const item = getItem<T>(key);
        removeItem(key);
        return item
    }

    const getStorage = (): Storage => {
        let storage: Storage;
        switch (storageType) {
            case StorageType.Local:
                storage = localStorage;
                break;
            case StorageType.Session:
                storage = sessionStorage;
                break;
            default:
                throw new Error("Invalid storage type : " + storageType);
        }
        if (!storage) throw new Error(StorageType[storageType] + " storageType is not available");
        return storage;
    }

    return {
        getItem,
        setItem,
        removeItem,
        removeItems,
        clear,
        getAndRemoveItem,
        clearFromBeginKey
    }
}

const isExpirationItem = <T>(item: any): item is ItemWithExpiration<T> => {
    return typeof item === "object" && item?.hasOwnProperty("___itemExpirationTime___");
}

export const LocalCacheService = CacheService(StorageType.Local);

export const SessionCacheService = CacheService(StorageType.Session);