import {State} from "./ItemButton.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

export const initialState: State = {
    buttonWidth: 0,
}

export const reducer = {
    buttonRef: ({state, setState}, [ref]: [HTMLButtonElement | null]) => {
        const width = ref?.clientWidth ?? 0;
        if (!width || state.buttonWidth === width) return;
        setState({buttonWidth: width});
    }
} satisfies MagicReducerObject<State>;