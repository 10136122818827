import {useAllCRUDCache, UseCRUDCacheBaseProps, useSingleCRUDCache} from "./generic/useCRUDCache";
import {Guid, LaunchpadApp} from "common";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {LaunchpadAppApi} from "../../apis/LaunchpadApp/LaunchpadAppApi";

const launchpadAppCacheKey = 'launchpad-app';

const baseConfig: UseCRUDCacheBaseProps<LaunchpadApp> = {
    cacheKey: launchpadAppCacheKey,
    ...LaunchpadAppApi,
}

export const useAllLaunchpadAppsCache = () => {
    const data = useAllCRUDCache({...baseConfig, fetchAll: LaunchpadAppApi.getAll});

    return {
        ...data,
        apps: data.items,
    }
}

export const useLaunchpadAppCache = (id: Immutable<Guid> | undefined) => {
    const data = useSingleCRUDCache({...baseConfig, id});

    return {
        ...data,
        app: data.item,
    }
}