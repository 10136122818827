import {ReportingApi} from "../../apis/Reporting/ReportingApi";
import {queryClient} from "../../index";
import {useQuery} from "@tanstack/react-query";
import {UserReport} from "common";

const userReportCacheKey = "userReport";

export const useUserReportCache = () => {
    const {data: userReport} = useQuery({
        queryKey: [userReportCacheKey],
        queryFn: ReportingApi.getMyReport,
        staleTime: Infinity
    });

    return {
        userReport,
        incrementReportValue,
        decrementReportValue,
    };
};

const incrementReportValue = (key: keyof UserReport, value: number = 1) => {
    const localUserReport = queryClient.getQueryData<UserReport | undefined>([userReportCacheKey]);
    if (!localUserReport) return;
    const report = {...localUserReport, [key]: localUserReport[key] + value};
    queryClient.setQueryData([userReportCacheKey], report);
}

const decrementReportValue = (key: keyof UserReport, value: number = 1) => {
    incrementReportValue(key, -value);
}

export const UserReportCacheActions = {
    incrementReportValue,
    decrementReportValue,
}