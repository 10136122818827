import {FilePickerDialogProps, State} from "./FilePickerDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {GraphApi} from "../../../apis/Graph/GraphApi";
import {FileData, SearchDriveItemsType} from "common";

export const initialState: State = {
    onFileSelected: () => 0,
    searchTimeout: undefined,
    files: [],
    isSearching: true,
    initialFiles: [],
    selectedFileId: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: async ({setState}, _, props: FilePickerDialogProps) => {
        setState({...initialState, ...props});
        config.dialogRef.dispatch("open")();
        const initialFiles = await GraphApi.searchDriveItems({
            query: "",
            types: (props.types as Array<SearchDriveItemsType> | undefined),
            take: 30
        });
        setState({initialFiles, files: initialFiles, isSearching: false});
    },
    searchQueryChanged: ({state, setState}, _, searchQuery: string | undefined) => {
        if (state.searchTimeout) clearTimeout(state.searchTimeout);
        setState({
            isSearching: true,
            searchTimeout: setTimeout(async () => {
                if (!searchQuery) return setState({isSearching: false, files: state.initialFiles});
                setState({isSearching: true});
                const files = await GraphApi.searchDriveItems({
                    query: searchQuery,
                    types: (state.types as Array<SearchDriveItemsType> | undefined),
                    take: 30
                });
                setState({files: files, isSearching: false});
            }, !searchQuery ? 0 : 800)
        })
    },
    selectFile: ({setState}, [file]: [Immutable<FileData>]) => {
        setState({selectedFileId: file.id + ""});
    },
    confirmSelection: ({state}) => {
        const selectedFile = state.files.find(file => file.id + "" === state.selectedFileId);
        if (!selectedFile) return;
        state.onFileSelected(selectedFile);
        config.dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;