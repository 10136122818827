import {IListColumn} from "../../List/List.types";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {LaunchpadApp} from "common";
import {translations} from "../../../../translations";
import React, {useMemo} from "react";
import {Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {Props} from "../AppList.types";
import {useDialogContext} from "../../../../services/DialogContext/DialogContext";
import {DialogContextValue} from "../../../../services/DialogContext/DialogContext.types";

export const useAppListColumns = (props: Props): Array<IListColumn<Immutable<LaunchpadApp> | null>> => {
    const {launchpadAppDialog} = useDialogContext();

    return useMemo(() => [
        generateAppColumn(launchpadAppDialog),
        generateLinkColumn(),
    ], []);
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateAppColumn = (launchpadAppDialog: DialogContextValue["launchpadAppDialog"]): IListColumn<Immutable<LaunchpadApp> | null> => ({
    field: "app",
    label: translations.get("Application"),
    render: item => (
        <Flex vAlign={"center"} gap={"gap.small"} className={"overflow-hidden"}>
            <img style={{height: "28px", width: "28px", borderRadius: "4px", objectFit: "cover"}} src={item?.iconUrl}
                 alt={item?.name}/>
            <Text truncated content={item?.name ?? ""}/>
        </Flex>
    ),
    minWidth: "100px",
    maxWidth: "50%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Shape style={{height: "28px", width: "28px", borderRadius: "4px"}}/>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    onClick: item => launchpadAppDialog.dispatch("open", item?.id)(),
    sort: (a, b) => a?.name.localeCompare(b?.name ?? "") ?? 0,
});

const generateLinkColumn = (): IListColumn<Immutable<LaunchpadApp> | null> => ({
    field: "link",
    label: translations.get("Link"),
    render: item => item?.link ?? "",
    minWidth: "100px",
    maxWidth: "50%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    onClick: item => window.open(item?.link, "_blank"),
    sort: (a, b) => a?.link.localeCompare(b?.link ?? "") ?? 0,
});