import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {UserData, UserDataDTO} from "common";

const getUserData = async (): Promise<UserData | undefined> => {
    try {
        const response = await AxiosConfig.instance.get("/users/me/data");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user data");
        return undefined;
    }
}

const createUserData = async (userData: UserDataDTO) => {
    try {
        const response = await AxiosConfig.instance.post("/users/me/data", userData);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create user data");
        return undefined;
    }
}

const updateUserData = async (userData: UserDataDTO) => {
    try {
        const response = await AxiosConfig.instance.put("/users/me/data", userData);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update user data");
        return undefined;
    }
}

const getUsersLocations = async (): Promise<Array<string>> => {
    try {
        const response = await AxiosConfig.instance.get("/users/locations");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get users locations");
        return [];
    }
}

export const UserApi = {
    getUserData,
    createUserData,
    updateUserData,
    getUsersLocations,
}