import {HomeLogic} from "../Home.interfaces";
import {Button, EditIcon, EyeSlashIcon, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import MarkdownView from "react-showdown";
import {ColorModule} from "../../../modules/Color.module";
import React, {useLayoutEffect, useMemo} from "react";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {useAllNewsCache} from "../../../hooks/cache/useNewsCache";
import {AutoWidthMenu} from "../../../components/others/AutoWidthMenu/AutoWidthMenu";
import {ScreenModule} from "../../../modules/Screen.module";
import {useAllCommunitiesCache} from "../../../hooks/cache/useCommunityCache";
import {CommunityModule} from "../../../modules/Community.module";
import {GraphVideoPlayer} from "../../../components/others/GraphVideoPlayer/GraphVideoPlayer";
import {useDeeplinkContext} from "../../../hooks/useDeeplinkContext";
import {DeeplinkContextType} from "common";
import {useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";

export const News = (logic: HomeLogic) => {
    const {news} = useAllNewsCache();
    const {isAdmin, isBasicUser} = useUserRolesCache();
    const {userData} = useUserDataCache();
    const {communities} = useAllCommunitiesCache();
    const {deeplinkContext, clearDeeplinkContext} = useDeeplinkContext();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const autoWidthMenuRef = useMagicReducerRef(AutoWidthMenu);

    const visibleCommunityNews = useMemo(() => {
        return news?.filter(n => n.show && (n.community === "General" || userData?.interests?.channels.includes(n.community)))
            .map(n => n.community) ?? [];
    }, [news, userData?.interests?.channels]);

    const menuItems = useMemo(() => {
        return CommunityModule.generateMenuItems(!isAdmin ? visibleCommunityNews : communities);
    }, [visibleCommunityNews, isAdmin]);

    useLayoutEffect(function showUpdatedNewsFromNotification() {
        if (!menuItems || !deeplinkContext) return;
        if (deeplinkContext?.type === DeeplinkContextType.NewsUpdated) {
            const index = menuItems?.findIndex(i => i.id === deeplinkContext.data) ?? 0;
            autoWidthMenuRef.dispatch("setActiveMenuIndex")(index);
            clearDeeplinkContext();
        }
    }, [deeplinkContext, menuItems]);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const activeCommunity = menuItems?.[activeIndex]?.id;

    const activeNews = news?.find(n => n.community === activeCommunity);

    if (logic.showSearchResults || (visibleCommunityNews.length === 0 && isBasicUser)) return null;

    const showButtons = (activeNews?.buttons?.length ?? 0) > 0;

    return (
        <Flex column className={"home-message-of-the-day-part"}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                {news && !activeNews?.show && !isBasicUser &&
                    <EyeSlashIcon className={"primary-color"}/>
                }
                <Text
                    className={"home-view-content-title"}
                    size={"larger"}
                    weight={"bold"}
                    content={translations.get("News")}
                />
                {!isBasicUser &&
                    <Button
                        styles={{marginTop: "2px", marginLeft: "-2px"}}
                        primary
                        iconOnly
                        icon={<EditIcon/>}
                        text
                        onClick={logic.dispatch("handleEditMessageOfTheDay", activeCommunity)}
                    />
                }
            </Flex>
            <Text
                className={"home-view-content-subtitle"}
                content={translations.get("HomeViewMessageOfTheDayPartSubtitle")}
            />
            <Flex className={"w-100"} styles={{marginBottom: isSmallScreen ? "0" : "10px"}}>
                <AutoWidthMenu
                    defaultMenuIndex={0}
                    items={menuItems}
                    onIndexChange={i => setActiveIndex(i)}
                    externalRef={autoWidthMenuRef}
                />
            </Flex>
            <Flex className={"home-message-of-the-day-container"}>
                <Flex fill column style={{gap: "10px", padding: "10px"}} className={"overflow-hidden"}>
                    <MarkdownView className={"markdown-view"} markdown={activeNews?.message ?? ""}/>
                    {showButtons &&
                        <Flex gap={"gap.small"}>
                            {activeNews?.buttons?.map(b => (
                                <Button
                                    key={b.id}
                                    primary
                                    className={"custom-button"}
                                    styles={{
                                        backgroundColor: b.color + " !important",
                                        color: ColorModule.getContrastingTextColor(b.color) + " !important",
                                    }}
                                    content={b.title}
                                    onClick={logic.dispatch("handleOpenLink", b.link)}
                                />
                            ))}
                        </Flex>
                    }
                </Flex>
                {activeNews?.selectedVideo &&
                    <GraphVideoPlayer
                        {...activeNews.selectedVideo}
                        height={"200px"} width={"350px"}
                        style={{borderRadius: "0"}}
                    />
                }
            </Flex>
        </Flex>
    )
}