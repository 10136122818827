import React, {ReactElement, useMemo} from "react";
import {ImmutableObject, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {bestPracticesViewCacheKey, initialState, reducer} from "./BestPractices.reducer";
import "./BestPractices.styles.scss";
import {ItemDataModule} from "../../modules/ItemData.module";
import {BestPracticesModule} from "../../modules/BestPractices.module";
import {Flex, SearchIcon, Text} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import moment from "moment";
import {ScreenModule} from "../../modules/Screen.module";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {ItemDialog} from "../../components/dialogs/ItemDialog/ItemDialog";
import {Searchbar} from "../../components/others/Searchbar/Searchbar";
import {AutoWidthMenu} from "../../components/others/AutoWidthMenu/AutoWidthMenu";
import {useBestPracticesCache} from "../../hooks/cache/bestPracticesCache";
import {useUserDataCache} from "../../hooks/cache/userDataCache";
import {useMyRecommendationsCache} from "../../hooks/cache/recommendationsCache";
import {IFilterItem} from "../../components/others/Filter/Filter.types";
import {ItemDialogRef} from "../../components/dialogs/ItemDialog/ItemDialog.interfaces";
import {VirtualizedGrid} from "../../components/others/VirtualizedGrid/VirtualizedGrid";
import {ItemCard} from "../../components/others/ItemCard/ItemCard";
import {ItemData} from "common";

export const BestPractices = (): ReactElement => {
    const {userId, userMail} = useMsTeamsSelector("userId", "userMail");
    const [state, dispatch] = useMagicReducer(reducer(userId), initialState());
    const itemDialogRef = useMagicReducerRef(ItemDialog);
    const searchBarRef = useMagicReducerRef(Searchbar);
    const menuRef = useMagicReducerRef(AutoWidthMenu);
    const {bestPractices} = useBestPracticesCache();
    const {hasUserVisitedItem} = useUserDataCache();
    const {canRecommendItem} = useMyRecommendationsCache();

    const isSmallScreen = ScreenModule.isSmallScreen();

    const categories = useMemo(() => ItemDataModule.generateCategories(bestPractices), [bestPractices]);

    let selectedFromDate = (searchBarRef.state?.filterItems?.["from-date"]?.inputValue ?? "") + "";
    if (!selectedFromDate) selectedFromDate = moment().startOf("day").add(-1, "year").format("YYYY-MM-DD");
    let selectedToDate = (searchBarRef.state?.filterItems?.["to-date"]?.inputValue ?? "") + "";
    if (!selectedToDate) selectedToDate = moment().startOf("day").format("YYYY-MM-DD");

    const filters = useMemo(() => {
        const items = BestPracticesModule.generateFilterItems(bestPractices) as Array<IFilterItem>;
        const fromDateItem = items.find(i => i.key === "from-date");
        if (fromDateItem?.input) {
            fromDateItem.input.max = selectedToDate;
            fromDateItem.input.value = selectedFromDate;
        }
        const toDateItem = items.find(i => i.key === "to-date");
        if (toDateItem?.input) {
            toDateItem.input.min = selectedFromDate;
            toDateItem.input.value = selectedToDate;
        }
        return items;
    }, [bestPractices, selectedFromDate, selectedToDate]);

    const query = (searchBarRef.state?.query ?? "").toLowerCase();

    const filterItems = searchBarRef.state?.filterItems;

    const filteredBestPractices = useMemo(() => {
        const selectedCategory = state.selectedCategoryIndex !== 0 ? categories?.[state.selectedCategoryIndex] : undefined;
        return ItemDataModule.filterItems({
            items: bestPractices,
            query,
            filterItems,
            selectedCategoryKey: selectedCategory?.key,
            hasUserVisitedItem,
            userUpn: userMail,
        });
    }, [state.selectedCategoryIndex, bestPractices, categories, query, filterItems]);

    const itemDialog = <ItemDialog externalRef={itemDialogRef}/>;

    const header = (
        <Flex className={"w-100"} column gap={"gap.medium"}>
            {!isSmallScreen &&
                <Flex column hAlign={"center"} styles={{gap: "3px"}}>
                    <Text
                        className={"primary-color"}
                        size={"larger"}
                        weight={"bold"}
                        content={translations.get("BestPractices")}
                    />
                    <Text
                        className={"primary-color"}
                        content={translations.get("BestPracticesSubtitle")}
                    />
                </Flex>
            }
            <Flex column className={"w-100"} hAlign={"center"} gap={isSmallScreen ? "gap.medium" : "gap.small"}>
                <Searchbar
                    id={bestPracticesViewCacheKey + "searchbar"}
                    throttling={600}
                    externalRef={searchBarRef}
                    styles={isSmallScreen ? {width: "100%"} : {minWidth: "500px", maxWidth: "800px"}}
                    placeholder={translations.get("SearchByKeyword") + "..."}
                    onSearch={dispatch("handleSearch")}
                    filterItems={filters}
                />
                <Flex className={"w-100"}>
                    <AutoWidthMenu
                        externalRef={menuRef}
                        align={"center"}
                        defaultMenuIndex={state.selectedCategoryIndex}
                        items={categories}
                        onIndexChange={dispatch("handleClickMenuItem", categories)}
                    />
                </Flex>
            </Flex>
        </Flex>
    )

    return (
        <Flex fill column className={"best-practices-view no-select"}>
            <Flex fill hAlign={"center"} column className={"best-practices-view-content"}>
                {header}
                {renderItemsList(filteredBestPractices, itemDialogRef, canRecommendItem)}
            </Flex>
            {itemDialog}
        </Flex>
    )
}

const renderItemsList = (
    filteredBestPractices: ImmutableObject<ItemData>[] | undefined,
    itemDialogRef: ItemDialogRef,
    canRecommendItem: boolean,
) => {
    return (
        <Flex column fill hAlign={"center"} vAlign={"center"} className={"best-practices-grid-container"}>
            {filteredBestPractices?.length === 0 ?
                <Flex column hAlign={"center"} vAlign={"center"} gap={"gap.small"} styles={{marginTop: "50px"}}>
                    <SearchIcon outline size={"largest"}/>
                    <Text size={"large"} content={translations.get("NoResults")}/>
                </Flex>
                :
                <VirtualizedGrid
                    styles={{marginTop: "5px", marginBottom: "50px", flexShrink: 0}}
                    renderSkeleton={renderSkeleton}
                    itemHeight={248}
                    gridGap={30}
                    itemMinWidth={300}
                    gridPadding={12}
                    showSkeletons={!filteredBestPractices}
                    scrollToTopOnNewChildren
                    showAlwaysScrollbar
                    visibleLinesOffset={0}>
                    {filteredBestPractices?.map(bp => (
                        <ItemCard
                            key={bp.id} item={bp}
                            itemDialogRef={itemDialogRef}
                            hideRecommendButton={!canRecommendItem}
                        />
                    ))}
                </VirtualizedGrid>
            }
        </Flex>
    )
}

const renderSkeleton = (key: string) => <ItemCard key={key} item={undefined} hideRecommendButton={true}/>;