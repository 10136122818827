import {QueryClient, useQueryClient} from "@tanstack/react-query";
import {useLayoutEffect, useState} from "react";

export const usePropertySelectorRendering = <T extends object>(config: {
    initialData: T,
    getLocalData: (queryClient: QueryClient) => T | undefined,
    args: (keyof T)[],
    cacheKey: string,
}) => {
    const queryClient = useQueryClient();

    const [data, setData] = useState<T>(config.getLocalData(queryClient) ?? {...config.initialData});

    useLayoutEffect(function listenCachedItems() {
        return queryClient.getQueryCache().subscribe((event) => {
            if (event?.query.queryKey.length !== 1) return;
            if (event?.query.queryKey[0] !== config.cacheKey) return;
            if (event.type !== "updated") return;
            if (!event.query.state.data) return;
            const updatedKeys = config.args.filter(key => event.query.state.data[key] !== data[key]);
            if (updatedKeys.length === 0) return;
            setData(event.query.state.data);
        });
    }, [data]);

    return data as { [key in ((keyof T)[])[number]]: T[key] };
}