import React, {useEffect} from "react";
import {FormItem, useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../../translations";
import {Button, Flex, FlexItem, SaveIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "../../../services/DialogContext/DialogContext.types";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {Guid} from "common";

type CRUDFormData = {
    id: Immutable<Guid>,
}

type FormItems = Record<string, FormItem>;

type UseCRUDFormReturn<DATA extends CRUDFormData, F_ITEMS extends FormItems> = ReturnType<typeof useCRUDForm<DATA, F_ITEMS>>;

type CRUDFormProps<DATA extends CRUDFormData, F_ITEMS extends FormItems> = {
    oldData: Immutable<DATA> | undefined,
    formItems: F_ITEMS,
    upsertData: (oldData: Immutable<DATA> | undefined, formData: UseCRUDFormReturn<DATA, F_ITEMS>["state"]["data"]) => Promise<void>,
    removeData?: (id: Immutable<Guid>) => Promise<void>,
    removeTitle?: string,
    removeSubtitle?: string,
    readOnly?: boolean,
}

export const useCRUDForm = <DATA extends CRUDFormData, F_ITEMS extends FormItems>(props: CRUDFormProps<DATA, F_ITEMS>) => {
    const {confirmDeleteDialog} = useDialogContext();
    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const {readOnly, formItems, removeData, upsertData, oldData, removeTitle, removeSubtitle} = props;

    const form = useForm({
        disabled: isSaving || readOnly,
        items: formItems,
    });

    const reset = (initialValues?: typeof form["state"]["data"]) => {
        if (initialValues) {
            // @ts-ignore
            form.setFieldsInitialValues(initialValues);
        }
        form.reset();
    }

    const renderSaveButton = (callback: () => void) => readOnly ? null : (
        <Button
            primary
            content={translations.get("Save")}
            disabled={!form.isValid || !form.state.hasChanged || isSaving}
            loading={isSaving}
            icon={<SaveIcon outline size={"large"}/>}
            onClick={handleSave(oldData, form.state.data, upsertData, setIsSaving, form.reset, callback)}
        />
    );

    const renderDeleteButton = (callback: () => void) => (readOnly || !oldData?.id || !props.removeData) ? null : (
        <Button
            content={translations.get("Delete")}
            icon={<TrashCanIcon outline/>}
            text
            disabled={isSaving}
            className={isSaving ? "" : "delete-btn"}
            onClick={handleDelete(
                oldData?.id,
                callback,
                removeData,
                confirmDeleteDialog,
                removeTitle,
                removeSubtitle
            )}
        />
    );

    const renderButtons = (callback: () => void) => readOnly ? null : (
        <Flex fill hAlign={"end"}>
            {renderDeleteButton(callback)}
            <FlexItem push>
                {renderSaveButton(callback) ?? <div/>}
            </FlexItem>
        </Flex>
    )

    return {
        ...form,
        renderSaveButton,
        renderDeleteButton,
        renderButtons,
        reset,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSave = <DATA extends CRUDFormData, F_ITEMS extends FormItems>(
    oldData: CRUDFormProps<DATA, F_ITEMS>["oldData"],
    formData: UseCRUDFormReturn<DATA, F_ITEMS>["state"]["data"],
    upsertData: CRUDFormProps<DATA, F_ITEMS>["upsertData"],
    setIsSaving: (value: boolean) => void,
    resetForm: () => void,
    callback: () => void,
) => async () => {
    setIsSaving(true);
    await upsertData(oldData, formData);
    callback();
    resetForm();
    setIsSaving(false);
}

const handleDelete = <DATA extends CRUDFormData, F_ITEMS extends FormItems>(
    id: Immutable<Guid> | undefined,
    callback: () => void,
    removeData: CRUDFormProps<DATA, F_ITEMS>["removeData"],
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
    removeTitle: CRUDFormProps<DATA, F_ITEMS>["removeTitle"],
    removeSubtitle: CRUDFormProps<DATA, F_ITEMS>["removeSubtitle"],
) => () => {
    if (!id || !removeData) return;
    confirmDeleteDialog.dispatch("open", {
        title: removeTitle ?? translations.get("DeleteItem"),
        subtitle: removeSubtitle ?? translations.get("ConfirmDeleteItem"),
        callback: async () => {
            await removeData(id);
            callback();
        }
    })();
}