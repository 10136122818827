import {ReactElement} from "react";
import {Immutable, MagicDispatch, MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./List.reducer";
import {ComponentSlotStyle} from "@fluentui/react-northstar";
import {Color, Guid} from "common";

export type State = {
    mounted: boolean,
    columnSort: string | null,
    columnFilters: Map<string, ColumnFilterData>,
    sortAscending: boolean,
    visibleItemsCount: number,
    visibleRange: [number, number],
    editedItem: IListEditedItem | undefined,
    prevSkip: number | undefined,
    skip: number | undefined,
    columnFiltersTimeout: NodeJS.Timeout | undefined,
    listContainer: HTMLDivElement | null,
    list: HTMLDivElement | null,
}

export type IListActions<T> = {
    items: Array<IListRowAction<IListItem<T>>>,
    icon?: ReactElement | ((item: IListItem<T>) => ReactElement),
    backgroundColor?: Color | ((item: IListItem<T>) => Color),
    disabled?: boolean | ((item: IListItem<T>) => boolean),
}

export type Props<T> = {
    show?: boolean,
    items: Immutable<Array<IListItem<T>>> | undefined,
    columns: Array<IListColumn<IListItem<T>>>,
    actions?: IListActions<T>,
    rowHeight?: number,
    debugMode?: boolean,
    initialSortedColumn?: string,
    initialSortOrder?: "ascending" | "descending",
    fill?: boolean,
    fetchOnScroll?: boolean,
    fetchNextItems?: ((skip: number, take: number) => void | Promise<void>),
    onFilterByColumn?: ((columnFilters: Record<string, string>) => void),
    onSortColumn?: ((columnSort: string | null, sortAscending: boolean) => void),
    filterByColumnThrottlingInMs?: number,
    defaultColumnFilters?: Record<string, string>,
    externalRef?: MagicReducerExternalRef<typeof reducer<T>>,
    sortLocally?: boolean,
    noItemsMessage?: string,
    hideHeader?: boolean,
}

export type ListRef<T> = Exclude<Props<T>["externalRef"], undefined>;

export type ListProps<T> = Props<T>;

export type Logic<T> = {
    show: boolean,
    columns: Array<IListColumn<IListItem<T>>>
    sortAscending: boolean
    columnSort: string | null
    getColumnByField: (field: string) => IListColumn<IListItem<T>>
    visibleRange: Immutable<State["visibleRange"]>,
    actionColumnWidth: number
    editedItem: IListEditedItem | undefined
    showActionColumn: boolean
    items: Array<IListItem<any>>
    actions?: IListActions<T>,
    columnFilters: Immutable<State["columnFilters"]>,
    isTouchScreen: boolean,
    hideFilters: boolean,
    dispatch: MagicDispatch<ReturnType<typeof reducer<T>>>,
}

export type ColumnFilterData = { type: ListColumnFilterType, text: string, regexp?: RegExp };

export enum ListColumnFilterType {
    Contains, StartWith, EndWith
}

export interface IListEditedItem {
    itemId: string,
    field: string,
}

export type IListColumnWidth = `${number}px` | `${number}%` | "0";

export type IListColumnLabelFunc = (sortIndicator: ReactElement | null) => ReactElement;

export interface IListColumn<T> {
    field: string,
    label: string | IListColumnLabelFunc,
    labelClassName?: string,
    labelStyles?: ComponentSlotStyle,
    icon?: ReactElement,
    minWidth?: IListColumnWidth,
    maxWidth?: IListColumnWidth,
    render: (item: IListItem<T>) => string | number | ReactElement | null,
    sort?: (a: IListItem<T>, b: IListItem<T>) => number,
    skeleton?: ReactElement,
    backgroundColor?: Color | ((item: IListItem<T>) => Color),
    renderEditField?: (item: IListItem<T>, onEditDone: () => void) => ReactElement,
    onClick?: (item: IListItem<T>) => void,
    getFilterValue?: (item: IListItem<T>) => string,
    filterPlaceholder?: string,
    itemClassName?: string,
    itemStyles?: ComponentSlotStyle,
}

export interface IListRowAction<T> {
    label: string | ((item: IListItem<T>) => string),
    icon?: ReactElement | ((item: IListItem<T>) => ReactElement | null) | null,
    onClick?: (item: IListItem<T>) => void,
    isVisible?: (item: IListItem<T>) => boolean,
    menu?: Array<IListRowAction<T>>,
}

export type IListItem<T> = null | (T & ({ id: Immutable<Guid> | string } | { key: string }));