import React, {memo, ReactElement, useMemo} from "react";
import {EyeIcon, Flex, MoreIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {ListProps} from "../List/List.types";
import {translations} from "../../../translations";
import {List} from "../List/List";
import {useFileListColumns} from "./hooks/useFileListColumns";
import {Props} from "./FileList.types";
import {FileData} from "common";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {Immutable} from "@witivio_teamspro/use-reducer";

export const FileList = memo((props: Props): ReactElement | null => {
    const {filePreviewDialog} = useDialogContext();
    const columns = useFileListColumns(props);

    const actions: ListProps<FileData>["actions"] = useMemo(() => ({
        items: [
            {
                key: "preview",
                icon: <EyeIcon outline/>,
                onClick: file => filePreviewDialog.dispatch("open", {
                    file: file as Immutable<FileData>,
                    hideActions: true
                })(),
                label: translations.get("Show")
            },
            {
                key: "delete",
                icon: <TrashCanIcon styles={{color: "red !important"}} outline/>,
                onClick: file => !file ? 0 : props.onRemoveFile(file),
                styles: {color: "red !important"},
                label: translations.get("Delete")
            }
        ],
        icon: <MoreIcon/>
    }), [props.onRemoveFile]);

    const items = props.files ?? Array.from({length: 10}).map(() => null);

    return (
        <Flex fill>
            <List<FileData>
                items={items}
                columns={columns}
                actions={actions}
                sortLocally
                hideHeader
                noItemsMessage={translations.get("NoFile")}
            />
        </Flex>
    )
}, (pp, np) => pp.files === np.files);