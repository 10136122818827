import {ErrorModule} from "components/others/ErrorBoundary/ErrorBoundary";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {FileData, Guid} from "common";
import {AxiosResponse} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {CRUDApi} from "../CRUD/CRUDApi";

const crudApi = CRUDApi.generate<FileData>("files");

const updateName = async (id: Immutable<Guid>, name: string): Promise<Immutable<FileData> | undefined> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.patch(`/files/${id}/name`, {name});
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update file name", error);
        return undefined;
    }
}

const getByProjectId = async (id: Immutable<Guid> | undefined) => {
    try {
        const response: AxiosResponse<Immutable<Array<FileData>>> = await AxiosConfig.instance.get("/files/project/" + id);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get files by project id", error);
        return [];
    }
}

const getDownloadUrl = async (id: Immutable<Guid> | undefined) => {
    try {
        const response: AxiosResponse<string> = await AxiosConfig.instance.get("/files/" + id + "/download-url");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get file download url", error);
        return "";
    }
}

export const FileApi = {
    create: crudApi.create,
    remove: crudApi.remove,
    getById: crudApi.getById,
    getAll: crudApi.getAll,
    updateName,
    getByProjectId,
    getDownloadUrl,
}