import {QueryObserverOptions, useQueries, useQuery} from "@tanstack/react-query";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {GraphUserData, Guid} from "common";
import {GraphApi} from "../../apis/Graph/GraphApi";
import hash from "object-hash";
import {useMemo} from "react";

export const usersCacheKey = "users";

const getUserQueryArgs = (id: Immutable<Guid> | undefined): QueryObserverOptions<Immutable<GraphUserData> | undefined> => ({
    queryKey: [usersCacheKey, id],
    queryFn: () => GraphApi.getUser(id!),
    staleTime: Infinity,
    enabled: !!id,
});

export const useUsersCache = (ids: Immutable<Array<Guid>> | undefined) => {
    const results = useQueries({
        queries: ids?.map(getUserQueryArgs) ?? []
    });

    const isLoading = results.some(r => r.isLoading || r.isFetching);
    const itemsData = !ids ? undefined : results.map(r => r.data).filter(Boolean) as Immutable<GraphUserData>[];
    const itemsHash = hash.sha1(itemsData || null);

    const users = useMemo(() => itemsData?.filter(Boolean), [itemsHash]);

    return {
        users,
        isLoading
    };
};

export const useUserCache = (id: Immutable<Guid> | undefined) => {
    const {data: user, isLoading} = useQuery(getUserQueryArgs(id));

    return {user, isLoading};
}