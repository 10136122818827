import React, {memo, ReactElement} from "react";
import {Props} from "./LaunchpadApp.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./LaunchpadApp.reducer";
import {CompareModule} from "modules/Compare.module";
import "./LaunchpadApp.styles.scss";
import {Flex, Text} from "@fluentui/react-northstar";
import {useLaunchpadAppCache} from "../../../../hooks/cache/launchpadAppCache";

export const LaunchpadApp = memo((props: Props): ReactElement | null => {
    const {app} = useLaunchpadAppCache(props.appId);
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    if (!app) return null;

    return (
        <Flex column className={"launchpad-app"} gap={"gap.small"} hAlign={"center"} vAlign={"center"}
              onClick={dispatch("openLink", app.link)}>
            <img src={app.iconUrl} alt={app.name}/>
            <Text truncated content={app.name}/>
        </Flex>
    )
}, CompareModule.areObjectsEqual);