import React, {CSSProperties, useEffect, useState} from "react";
import "./ErrorBoundary.styles.scss";
import {Alert, CloseIcon, Divider, ExclamationTriangleIcon, Flex, Text, Tooltip} from "@fluentui/react-northstar";
import {translations} from "translations";
import {AxiosError} from "axios";
import {MagicReducerRef, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "../../dialogs/Dialog/Dialog";

type ErrorData = {
    title?: string,
    subtitle?: string,
    inDialog: boolean,
    canDismiss?: boolean,
    error?: unknown,
}

const defaultShowErrorAlertFunc: ((title?: string, error?: unknown) => void) = (title) => {
    if (title) console.error(title);
}

const defaultShowErrorDialogFunc: ((data: {
    title?: string,
    subtitle?: string,
    canDismiss?: boolean,
    error?: unknown,
}) => void) = (data) => {
    console.error(data.title, ":", data.subtitle);
}

export const ErrorModule = {
    showErrorAlert: defaultShowErrorAlertFunc,
    showErrorDialog: defaultShowErrorDialogFunc,
}

const ErrorBoundary = () => {
    const [errorData, setErrorData] = useState<ErrorData>();
    const dialogRef = useMagicReducerRef(Dialog);

    useEffect(function onMount() {
        ErrorModule.showErrorAlert = (title, error) => setErrorData({...(title && {title}), error, inDialog: false});
        ErrorModule.showErrorDialog = (data) => {
            setErrorData({...data, inDialog: true});
            dialogRef.dispatch("open")();
        };
        return () => {
            ErrorModule.showErrorAlert = defaultShowErrorAlertFunc;
            ErrorModule.showErrorDialog = defaultShowErrorDialogFunc;
        }
    }, []);

    const handleClose = (e?: React.SyntheticEvent) => {
        e?.stopPropagation();
        setErrorData(undefined);
    };

    const style: CSSProperties = {
        pointerEvents: (!errorData || !errorData?.inDialog) ? "none" : "auto",
    }

    return (
        <Flex className={"errors-container"} style={style}>
            {renderErrorDialog(errorData, handleClose, dialogRef)}
            {renderErrorAlert(errorData, handleClose)}
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const isAxiosError = (error: unknown): error is AxiosError => {
    if (!error) return false;
    return typeof error === "object" && "name" in error && error?.name === "AxiosError";
}

const renderAxiosErrorDetails = (error: AxiosError) => {
    const endpoint = error.config.method?.toUpperCase() + " " + error.request?.responseURL;
    const body = JSON.stringify(error.config.data);
    const responseData = (error.response?.data as any)?.detail;
    return (
        <Flex column gap={"gap.smaller"}>
            <Divider/>
            {endpoint && <Text size={"medium"} content={endpoint}/>}
            {body && <Text size={"medium"} content={body}/>}
            {endpoint && responseData && <Divider/>}
            {responseData && <Text size={"medium"} content={responseData}/>}
        </Flex>
    )
}

const renderErrorDetails = (errorData: ErrorData) => {
    const axiosError = isAxiosError(errorData.error) ? errorData.error : undefined;
    return (
        <Flex column gap={"gap.smaller"} style={{maxHeight: "50vh", maxWidth: "100vw", overflow: "scroll"}}>
            {!errorData.title ? null :
                <Text
                    weight={"semibold"} size={"medium"}
                    content={errorData.title}
                />
            }
            {!errorData.error ? null :
                <>
                    <Text size={"medium"} content={axiosError?.message}/>
                    {!axiosError ? null : renderAxiosErrorDetails(axiosError)}
                </>
            }
        </Flex>
    )
}

const renderErrorAlert = (errorData: ErrorData | undefined, onClose: (e?: React.SyntheticEvent) => void) => {
    if (!errorData || errorData?.inDialog) return null;
    return (
        <Tooltip
            mouseLeaveDelay={500}
            trigger={
                <Alert
                    styles={{padding: "0 5px", pointerEvents: "visible"}}
                    danger
                    content={
                        <Flex vAlign={"center"} styles={{gap: "-10px"}}>
                            <Text styles={{padding: "5px"}} content={translations.get("AnErrorOccured")}/>
                            <CloseIcon styles={{padding: "5px"}} className={"cursor-pointer"} size={"small"}
                                       onClick={onClose}/>
                        </Flex>
                    }
                />
            }
            content={renderErrorDetails(errorData)}
            position={"above"}
            align={"end"}
        />
    )
}

const renderErrorDialog = (errorData: ErrorData | undefined, handleClose: () => void, dialogRef: MagicReducerRef<typeof Dialog>) => {
    const title = !errorData?.title ? translations.get("AnErrorOccured") : errorData.title;
    return (
        <Dialog
            externalRef={dialogRef}
            width={400}
            onClose={handleClose}
            closeOnClick={errorData?.canDismiss ?? true}
            closeOnOutsideClick={errorData?.canDismiss ?? true}
            content={
                <Flex fill className={"h-100"} column vAlign={"center"} hAlign={"center"} gap={"gap.small"}>
                    <ExclamationTriangleIcon
                        size={"largest"}
                        styles={{color: "rgb(196, 49, 75)", transform: "scale(2)", padding: "25px 0"}}
                    />
                    <Text content={title} size={"large"} weight={"semibold"} align={"center"}/>
                    {!errorData?.subtitle ? null : <Text content={errorData.subtitle} align={"center"}/>}
                </Flex>
            }
        />
    )
}

let errorBoundaryInstance: JSX.Element | null = null;

export const wrapWithErrorBoundary = (children: JSX.Element) => {
    if (!errorBoundaryInstance) errorBoundaryInstance = <ErrorBoundary/>;
    return <>
        {children}
        {errorBoundaryInstance}
    </>
}