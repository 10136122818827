import React, {memo, ReactElement} from "react";
import {Props} from "./NewsDialogButton.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./NewsDialogButton.reducer";
import {CompareModule} from "modules/Compare.module";
import "./NewsDialogButton.styles.scss";
import {Button, Flex, PopupIcon, Text, TrashCanIcon} from "@fluentui/react-northstar";
import {ScreenModule} from "../../../../modules/Screen.module";

export const NewsDialogButton = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const isSmallScreen = ScreenModule.isSmallScreen();

    return (
        <Flex column key={props.button.id}>
            <Flex styles={{gap: "6px"}} vAlign={"center"}>
                <PopupIcon outline/>
                <Text content={props.label}/>
            </Flex>
            <Flex gap={"gap.smaller"} vAlign={isSmallScreen ? "center" : "start"}>
                <Flex className={"button-color-picker"}>
                    <input
                        defaultValue={props.button.color}
                        onChange={props.onChangeColor}
                        type={"color"}
                        disabled={props.disabled}
                        style={{opacity: props.disabled ? 0.5 : 1}}
                    />
                </Flex>
                <Flex fill gap={"gap.smaller"} column={isSmallScreen} vAlign={"start"}>
                    {props.titleInput}
                    {props.linkInput}
                </Flex>
                <Button
                    className={"button-delete-button"}
                    text iconOnly
                    icon={<TrashCanIcon outline/>}
                    onClick={props.onDelete}
                    disabled={props.disabled}
                />
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);