import {Props, State} from "./TemplateCard.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";

export const initialState: State = {}

export const reducer = (props: Props) => ({
    selectCard: () => {
        if (!props.notification) return;
        props.onSelected?.(props.notification);
    },
    deleteCard: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        if (!props.notification) return;
        props.onDelete?.(props.notification);
    }
}) satisfies MagicReducerObject<State>;