import {MentionItem} from "@witivio_teamspro/northstar-form";
import {GraphApi} from "../apis/Graph/GraphApi";

export const fetchSuggestions = async (query: string): Promise<Array<MentionItem>> => {
    const users = !query ?
        await GraphApi.relevantPeople(3) : await GraphApi.searchUsers(query);
    return users.map(u => ({
        id: u.userPrincipalName,
        name: u.displayName,
        pictureUrl: u.picture,
    }));
}

const extractMentions = (inputText: string): Array<{ mentionId: string, mentionText: string }> => {
    const formattedText = inputText.replace(/\n/g, '').replace(/\s+/g, ' ').replace(/<br>/g, '');
    const mentionsRegex = /<span class="mention" data-index="0" data-denotation-char="" data-id="(.+?)" data-value="(.+?)">/g;
    const matches = Array.from(formattedText.matchAll(mentionsRegex));
    return matches.filter(match => !!match[1] && !!match[2]).map(match => ({
        mentionId: match[1].toLowerCase(),
        mentionText: match[2]
    }));
};

export const MentionModule = {
    fetchSuggestions,
    extractMentions
}