import React, {memo, ReactElement} from "react";
import {Props} from "./SelectPeopleDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SelectPeopleDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./SelectPeopleDialog.styles.scss";
import {translations} from "../../../translations";
import {useForm} from "@witivio_teamspro/northstar-form";
import {AcceptIcon, Button, Flex} from "@fluentui/react-northstar";
import {Guid} from "common";
import {GraphApi} from "../../../apis/Graph/GraphApi";
import {PeopleFilled, PersonFilled} from "@fluentui/react-icons";

export const SelectPeopleDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const defaultTitle = translations.get(state.multiple ? "SelectPeople" : "SelectPerson")

    const form = useForm({
        disabled: state.isLoading,
        items: {
            people: {
                type: "peoplePicker",
                required: true,
                multiple: state.multiple,
                fetchInitialUsers: state.fetchInitialUsers ?? ((ids) => GraphApi.getUsers(ids as Array<Guid>)),
                fetchUsers: state.fetchUsers ?? ((search) => GraphApi.searchUsers(search)),
                placeholder: defaultTitle,
                cacheEmptySearchUsers: false,
                autoFocus: true,
            }
        }
    });

    const usersIds = (form.state.data.people ?? []) as Array<Guid>;

    const isConfirmButtonDisabled = !form.isValid || state.isLoading;

    const confirmButton = (
        <Button
            primary
            content={translations.get("Confirm")}
            icon={<AcceptIcon outline/>}
            disabled={isConfirmButtonDisabled}
            loading={state.isLoading}
            onClick={dispatch("confirm", usersIds)}
        />
    )

    const Icon = state.multiple ? PeopleFilled : PersonFilled;

    return (
        <Dialog
            externalRef={dialogRef}
            icon={<Icon fontSize={24} className={"primary-color"}/>}
            width={450}
            title={state.title || defaultTitle}
            onClose={form.reset}
            content={form.renderForm()}
            footer={
                <Flex fill hAlign={"end"}>
                    {confirmButton}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);