import {Props, State} from "./PopupMenuButton.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import * as React from "react";
import {MouseEventHandler} from "react";
import {PopupProps} from "@fluentui/react-northstar";

export const initialState: State = {
    open: false,
    trigger: null,
    closeTimeout: undefined,
    contentWidth: 0,
}

export const reducer = (config: {
    props: Props
}) => ({
    open: ({state, setState}, [e]: [React.MouseEvent | undefined]) => {
        e?.stopPropagation();
        if (state.open) return;
        setState({open: true});
    },
    close: ({state, setState},
            [e]: [React.MouseEvent | MouseEvent | TouchEvent | undefined], immediate: boolean = true) => {
        e?.stopPropagation();
        if (state.closeTimeout) {
            clearTimeout(state.closeTimeout);
            setState({closeTimeout: undefined}, false);
        }
        if (!state.open) return;
        if (immediate) {
            setState({open: false});
            return;
        }
        const timeout = setTimeout(() => {
            setState({open: false});
        }, config.props.mouseLeaveDelay ?? 300);
        setState({closeTimeout: timeout}, false);
    },
    toggleOpen: ({state, setState}, [e]: [React.MouseEvent | undefined]) => {
        e?.stopPropagation();
        setState({open: !state.open});
    },
    handlePopupHoverOpenChange: (reducerData, payload: [React.SyntheticEvent, PopupProps | undefined]) => {
        const {state} = reducerData;
        const [e, data] = payload;
        if (e.type === "mouseenter" && state.closeTimeout) clearTimeout(state.closeTimeout);
        if (e.type !== "mouseleave") return;
        const triggerRect = state.trigger?.getBoundingClientRect();
        if (!triggerRect) return;
        const mouseEvent = e as unknown as Parameters<MouseEventHandler<HTMLDivElement>>[0];
        const x = mouseEvent.clientX;
        const y = mouseEvent.clientY;
        const isCursorInTrigger = x >= triggerRect.left && x <= triggerRect.right && y >= triggerRect.top && y <= triggerRect.bottom;
        if (data?.open || isCursorInTrigger) return;
        reducer(config).close(reducerData, [mouseEvent]);
    },
    handleLeaveTrigger: (reducerData, [e]: [Parameters<MouseEventHandler<HTMLDivElement>>[0]]) => {
        if (!e.relatedTarget || !("className" in e.relatedTarget) || !(e.relatedTarget as any).className) {
            reducer(config).close(reducerData, [e], false);
            return;
        }
        const classList = (e.relatedTarget?.className ?? "") + "";
        if (classList?.includes("ui-popup__content__content") || classList?.includes("ui-menu")) return;
        reducer(config).close(reducerData, [e], false);
    },
    triggerRef: ({setState}, [ref]: [HTMLDivElement | null]) => {
        setState({trigger: ref}, false);
    },
    contentRef: ({state, setState}, [ref]: [HTMLDivElement | null]) => {
        if (!ref?.clientWidth) return;
        const containerPadding = 5;
        const width = ref?.clientWidth + containerPadding;
        if (width === state.contentWidth) return;
        setState({contentWidth: width});
    },
}) satisfies MagicReducerObject<State>;

export const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
}