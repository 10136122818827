import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./Resources.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Resources.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Resources.styles.scss";
import {Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../../translations";
import {DocumentArrowUpRegular} from "@fluentui/react-icons";
import {FileList} from "../../../../lists/FileList/FileList";
import {InfoTooltip} from "../../../../others/InfoTooltip/InfoTooltip";

export const Resources = memo((props: Props): ReactElement | null => {
    const [, dispatch] = useMagicReducer(reducer(props), initialState);

    const selectedFile = props.form.state.data.file;

    useEffect(function onFileChanged() {
        dispatch("fileSelected", selectedFile)();
    }, [selectedFile]);

    return (
        <Flex fill column gap={"gap.small"} className={"share-item-dialog-resources-part"}>
            <Flex space={"between"} gap={"gap.small"} vAlign={"center"} style={{minHeight: "32px"}}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    <Text
                        className={"primary-color"}
                        weight={"semibold"}
                        content={translations.get("ContentAndDocuments")}
                        size={"large"}
                    />
                    <Flex styles={{marginLeft: "-10px", marginBottom: "-5px"}}>
                        <InfoTooltip content={translations.get("ShareItemDialogContentAndDocumentsTooltip")}/>
                    </Flex>
                </Flex>
                <Flex gap={"gap.medium"}>
                    {!!props.resources.length && props.form.formItems.file}
                </Flex>
            </Flex>
            {!props.resources.length ?
                <Flex fill column gap={"gap.medium"} vAlign={"center"} hAlign={"center"}>
                    <Flex column gap={"gap.smaller"} vAlign={"center"} hAlign={"center"}>
                        <DocumentArrowUpRegular className={"primary-color"} fontSize={60}/>
                        <Text className={"primary-color"} content={translations.get("UploadYourFirstResource")}/>
                    </Flex>
                    {props.form.formItems.file}
                </Flex>
                :
                <FileList files={props.resources} onRemoveFile={props.onRemoveResource}/>
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);