import {memo, ReactElement} from "react";
import {Props} from "./ConfirmCancelChangesDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ConfirmCancelChangesDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Button, Flex, Text, UndoIcon} from "@fluentui/react-northstar";
import "./ConfirmCancelChangesDialog.styles.scss";
import {translations} from "../../../translations";

export const ConfirmCancelChangesDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    return (
        <Dialog
            externalRef={dialogRef}
            width={450}
            title={translations.get("CancelChanges")}
            onClose={dispatch("cancel")}
            content={
                <Text content={translations.get("AreYouSureToCancelChanges")}/>
            }
            footer={
                <Flex fill hAlign={"end"}>
                    <Button
                        content={translations.get("Confirm")}
                        icon={<UndoIcon outline/>}
                        onClick={dispatch("confirm")}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);