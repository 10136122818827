import {State} from "./WelcomeDialog.types";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {UserSettingsDialog} from "../UserSettingsDialog/UserSettingsDialog";

export const initialState: State = {}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    userSettingsDialog: MagicReducerRef<typeof UserSettingsDialog>,
}) => ({
    open: () => {
        config.dialogRef.dispatch("open")();
    },
    start: () => {
        config.dialogRef.dispatch("close")();
        config.userSettingsDialog.dispatch("open")();
    }
}) satisfies MagicReducerObject<State>;