"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileType = void 0;
var FileType;
(function (FileType) {
    FileType[FileType["Word"] = 0] = "Word";
    FileType[FileType["Excel"] = 1] = "Excel";
    FileType[FileType["PowerPoint"] = 2] = "PowerPoint";
    FileType[FileType["OneNote"] = 3] = "OneNote";
    FileType[FileType["Text"] = 4] = "Text";
    FileType[FileType["Pdf"] = 5] = "Pdf";
    FileType[FileType["Html"] = 6] = "Html";
    FileType[FileType["Code"] = 7] = "Code";
    FileType[FileType["Archive"] = 8] = "Archive";
    FileType[FileType["Image"] = 9] = "Image";
    FileType[FileType["Sound"] = 10] = "Sound";
    FileType[FileType["Video"] = 11] = "Video";
    FileType[FileType["Other"] = 12] = "Other";
})(FileType || (exports.FileType = FileType = {}));
