import {Immutable} from "@witivio_teamspro/use-reducer";
import {
    FileData,
    GraphTeamData,
    GraphUserData,
    Guid,
    GuidModule,
    SearchDriveItemsRequest,
    SendNotificationRequest
} from "common";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {AxiosResponse} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {FileModule} from "../../modules/File.module";
import {DriveItem} from "@microsoft/microsoft-graph-types";

const me = async (): Promise<Immutable<GraphUserData> | undefined> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.get("/graph/me");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get my infos", error);
        return undefined;
    }
}

const searchUsers = async (query: string, top: number = 5): Promise<Array<GraphUserData>> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.get(`/graph/users/search`, {
            params: {query, top}
        });
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't search users", error);
        return [];
    }
};

const relevantPeople = async (top: number = 10): Promise<Array<GraphUserData>> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.get("/graph/me/relevant-people", {
            params: {top}
        });
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get relevant people", error);
        return [];
    }
};

const areUsersInTeam = async (teamId: string, userIds: string[]): Promise<any> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.post(`/graph/teams/${teamId}/users`, {userIds});
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't check if users are in team", error);
        return undefined;
    }
};

const getUser = async (userId: Immutable<Guid>): Promise<any> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.get(`/graph/user/${userId}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user info", error);
        return undefined;
    }
};

const getUsers = async (userIds: Immutable<Guid[]>): Promise<any> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.post("/graph/users", {userIds});
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get users info", error);
        return undefined;
    }
};

const getUserPhoto = async (userIdOrMail: string): Promise<string | undefined> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.get(`/graph/user/${userIdOrMail}/photo`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user photo", error);
        return undefined;
    }
};

const getUsersPhotos = async (userIds: Immutable<Guid[]>): Promise<any> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.post("/graph/users/photos", {userIds});
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get users photos", error);
        return undefined;
    }
};

const getFilePreviewUrl = async (config: { driveId: string, fileId: string }): Promise<string | undefined> => {
    try {
        const {driveId, fileId} = config;
        const response: AxiosResponse = await AxiosConfig.instance.get(`/graph/drives/${driveId}/items/${fileId}/preview`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get file preview URL", error);
        return undefined;
    }
};

const getFileDownloadUrl = async (config: { driveId: string, fileId: string }): Promise<string | undefined> => {
    try {
        const {driveId, fileId} = config;
        const response: AxiosResponse = await AxiosConfig.instance.get(`/graph/drives/${driveId}/items/${fileId}/download`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get file download URL", error);
        return undefined;
    }
};

const getFileSharedLink = async (config: { driveId: string, fileId: string }): Promise<string | undefined> => {
    try {
        const {driveId, fileId} = config;
        const response: AxiosResponse = await AxiosConfig.instance.get(`/graph/drives/${driveId}/items/${fileId}/sharedLink`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get file shared link", error);
        return undefined;
    }
};

const getMessageContent = async (messageId: string): Promise<string | undefined> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.get(`/graph/messages/${messageId}/content`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get message content", error);
        return undefined;
    }
};

const getAllTeams = async (): Promise<Array<GraphTeamData>> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.get("/graph/teams");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get teams", error);
        return [];
    }
};

const sendNotification = async (body: SendNotificationRequest): Promise<boolean> => {
    try {
        await AxiosConfig.instance.post("/graph/notifications", body);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't send notification", error);
        return false;
    }
};

const searchDriveItems = async (body: SearchDriveItemsRequest): Promise<Array<FileData>> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.post("/graph/search/driveItems", body);
        return response.data.map((file: DriveItem) => FileModule.parseRawFile(GuidModule.generateGUID(), file));
    } catch (error) {
        ErrorModule.showErrorAlert("Can't search drive items", error);
        return [];
    }
}

export const GraphApi = {
    me,
    searchUsers,
    relevantPeople,
    areUsersInTeam,
    getUser,
    getUsers,
    getUserPhoto,
    getUsersPhotos,
    getFilePreviewUrl,
    getFileDownloadUrl,
    getMessageContent,
    getAllTeams,
    sendNotification,
    getFileSharedLink,
    searchDriveItems
};