import {TrainingApi} from "../../apis/Training/TrainingApi";
import {useQuery} from "@tanstack/react-query";

export const trainingsCacheKey = "trainings";

export const useTrainingsCache = () => {
    const {data: trainings} = useQuery({
        queryKey: [trainingsCacheKey],
        queryFn: TrainingApi.getAll,
        staleTime: Infinity,
    });

    return {trainings};
};