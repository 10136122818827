import {UserSettingsDialogStep, UserSettingsStepData} from "../UserSettingsDialog.types";
import {useBestPracticesCache} from "../../../../hooks/cache/bestPracticesCache";
import {useEffect, useMemo, useState} from "react";
import {BestPracticesModule} from "../../../../modules/BestPractices.module";
import {translations} from "../../../../translations";
import {useUserDataCache} from "../../../../hooks/cache/userDataCache";

export const useJobFamilyStep = (): UserSettingsStepData => {
    const {userData} = useUserDataCache();
    const {bestPractices} = useBestPracticesCache();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        if (!userData?.interests?.jobFamilies) return;
        setSelectedItems([...userData.interests?.jobFamilies]);
    }, [userData?.interests?.jobFamilies]);

    const items = useMemo(() => BestPracticesModule.getJobFamilies(bestPractices), [bestPractices]);

    return {
        step: UserSettingsDialogStep.JobFamily,
        isValid: selectedItems.length > 0,
        items,
        selectedItems,
        title: translations.get("UserSettingsJobFamilyTitle"),
        subtitle: translations.get("UserSettingsJobFamilySubtitle"),
        toggleItem: (item) => setSelectedItems((prev) => prev.includes(item) ? prev.filter((t) => t !== item) : [...prev, item]),
        fluidItems: true,
        maxSelectedItems: 5,
        searchPlaceholder: translations.get("SearchJobFamily"),
        selectedItemsLabel: translations.get("YouHaveSelectedXJobFamily", {count: selectedItems.length}),
    }
}