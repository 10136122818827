import {UserSettingsDialogStep, UserSettingsStepData} from "../UserSettingsDialog.types";
import {useBestPracticesCache} from "../../../../hooks/cache/bestPracticesCache";
import {useEffect, useMemo, useState} from "react";
import {translations} from "../../../../translations";
import {BestPracticesModule} from "../../../../modules/BestPractices.module";
import {useUserDataCache} from "../../../../hooks/cache/userDataCache";

export const useChannelsStep = (): UserSettingsStepData => {
    const {userData} = useUserDataCache();
    const {bestPractices} = useBestPracticesCache();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        if (!userData?.interests?.channels) return;
        setSelectedItems([...userData.interests?.channels]);
    }, [userData?.interests?.channels]);

    const items = useMemo(() => BestPracticesModule.getChannels(bestPractices), [bestPractices]);

    const maxSelectedItems = 5;

    return {
        step: UserSettingsDialogStep.Interests,
        isValid: selectedItems.length > 0 && selectedItems.length <= maxSelectedItems,
        items,
        selectedItems,
        title: translations.get("UserSettingsChannelsTitle"),
        subtitle: translations.get("UserSettingsChannelsSubtitle"),
        toggleItem: (item) => setSelectedItems((prev) => prev.includes(item) ? prev.filter((t) => t !== item) : [...prev, item]),
        fluidItems: true,
        searchPlaceholder: translations.get("SearchChannel"),
        maxSelectedItems,
        selectedItemsLabel: translations.get("YouHaveSelectedXChannels", {count: selectedItems.length + "/" + maxSelectedItems}),
    }
}