import {Props, State} from "./VirtualizedGrid.types";
import {Immutable, MagicDispatch, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {CompareModule} from "modules/Compare.module";
import {startTransition, useEffect} from "react";

export const initialState: State = {
    itemsPerLine: 0,
    visibleLines: 0,
    visibleRange: [0, 0],
    container: null,
    isResizing: false,
    resizeTimeout: undefined,
}

export const reducer = (props: Props) => ({
    initialize: (reducerData) => {
        const {state, setState} = reducerData;
        const itemsPerLine = getItemsPerLine(state.container, props.gridGap, props.gridPadding, props.itemMinWidth);
        const itemTotalHeight = props.itemHeight + props.gridGap;
        const visibleLines = getVisibleLines(state.container, itemTotalHeight);
        const visibleRange: State["visibleRange"] = [0, getVisibleRangeEnd(visibleLines, itemsPerLine, props.visibleLinesOffset)];
        const isSameItemsPerLine = state.itemsPerLine === itemsPerLine;
        const isSameVisibleLines = state.visibleLines === visibleLines;
        const isSameVisibleRange = CompareModule.areArraysEqual(state.visibleRange as [], visibleRange);
        const render = !isSameItemsPerLine || !isSameVisibleLines || !isSameVisibleRange;
        setState({itemsPerLine, visibleLines, visibleRange}, render);
    },
    containerRef: ({setState}, [ref]: [HTMLDivElement | null]) => {
        setState({container: ref}, false);
    },
    scroll: (reducerData) => {
        const {state, setState} = reducerData;
        const {container} = state;
        if (!container) return;
        const itemTotalHeight = props.itemHeight + props.gridGap;
        let rangeStart = (Math.floor(container.scrollTop / itemTotalHeight) - props.visibleLinesOffset) * state.itemsPerLine;
        if (rangeStart < 0) rangeStart = 0;
        const rangeEnd = rangeStart + getVisibleRangeEnd(state.visibleLines, state.itemsPerLine, props.visibleLinesOffset);
        const visibleRange: State["visibleRange"] = [rangeStart, rangeEnd];
        const render = state.visibleRange[0] !== rangeStart;
        startTransition(() => setState({visibleRange}, render));
    },
    childrenUpdated: ({state}) => {
        if (props.scrollToTopOnNewChildren) {
            state.container?.scrollTo(0, 0);
        }
    },
    resizeStart: ({setState}) => {
        setState({isResizing: true});
    },
    resizeEnd: (reducerData) => {
        const {state, setState} = reducerData;
        throttle({
            throttling: 500,
            timeout: state.resizeTimeout as NodeJS.Timeout,
            updateTimeout: (timeout) => setState({resizeTimeout: timeout}, false),
            notifyChange: () => {
                reducer(props).initialize(reducerData);
                setState({isResizing: false});
            }
        })
    },
}) satisfies MagicReducerObject<State>;

const getItemsPerLine = (
    grid: Immutable<HTMLDivElement | null>,
    gridGap: number,
    gridPadding: number,
    itemMinWidth: number
): number => {
    if (!grid) return 0;
    const gridWidth = grid.clientWidth;
    return Math.floor((gridWidth + gridGap - (gridPadding * 2)) / (itemMinWidth + gridGap));
}

const getVisibleLines = (
    grid: Immutable<HTMLDivElement | null>,
    itemTotalHeight: number,
): number => {
    if (!grid) return 0;
    const gridHeight = grid.clientHeight;
    return Math.ceil(gridHeight / itemTotalHeight);
}

const getVisibleRangeEnd = (visibleLines: number, itemsPerLine: number, visibleLinesOffset: number) => {
    return ((visibleLines + visibleLinesOffset + 1) * itemsPerLine) + (visibleLinesOffset * itemsPerLine);
}

const throttle = (data: {
    throttling: number,
    timeout: NodeJS.Timeout | undefined,
    updateTimeout: (timeout: NodeJS.Timeout | undefined) => void,
    notifyChange: () => void
}) => {
    const {throttling, timeout, updateTimeout, notifyChange} = data;
    if (timeout) clearTimeout(timeout);
    const newTimeout = setTimeout(() => {
        notifyChange();
        updateTimeout(undefined);
    }, throttling);
    updateTimeout(newTimeout)
}

export const useResizeEffect = (dispatch: MagicDispatch<typeof reducer>) => {
    useEffect(() => {
        const resizeStart = dispatch("resizeStart");
        const resizeEnd = dispatch("resizeEnd");
        window.addEventListener("resize", resizeStart);
        window.addEventListener("resize", resizeEnd);
        return () => {
            window.removeEventListener("resize", resizeStart);
            window.removeEventListener("resize", resizeEnd);
        }
    }, []);
}