import {ColumnFilterData, IListColumn, IListItem, ListColumnFilterType, Props, State} from "./List.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {DropdownProps, InputProps} from "@fluentui/react-northstar";

export const initialState = <T, >(props: Props<T>): State => {
    const columnFilters = new Map<string, {
        type: ListColumnFilterType;
        text: string;
        regexp?: RegExp
    }>();
    Object.keys(props.defaultColumnFilters ?? {}).forEach(key => {
        const text = props.defaultColumnFilters?.[key];
        if (text) columnFilters.set(key, {
            text,
            type: ListColumnFilterType.Contains,
            regexp: new RegExp(".*" + text + ".*"),
        })
    });
    return {
        mounted: false,
        columnSort: props.initialSortedColumn ?? null,
        columnFilters,
        sortAscending: !props.initialSortOrder ? true : props.initialSortOrder === "ascending",
        visibleItemsCount: 0,
        visibleRange: [0, 0],
        editedItem: undefined,
        prevSkip: undefined,
        skip: 999999,
        columnFiltersTimeout: undefined,
        listContainer: null,
        list: null,
    }
}

const visibleRangeOffset = 2;
const defaultRowHeight = 50;

export const reducer = <T, >(props: Props<T>) => ({
    initialize: (reducerData) => {
        const {setState} = reducerData;
        setState({mounted: true}, false);
        if (props.debugMode) {
            const totalPercentageWidth = props.columns.reduce((total, col) => {
                if (!!col.maxWidth && col.maxWidth?.endsWith("%"))
                    total += Number(col.maxWidth.slice(0, col.maxWidth.length - 1));
                return total;
            }, 0)
            console.log("[List debug mode] total columns percentage width : " + totalPercentageWidth + "%");
        }
        reducer(props).updateVisibleItemsCount(reducerData);
        return () => {
            setState({mounted: false}, false);
        }
    },
    listContainerRef: ({setState}, [listContainer]: [HTMLDivElement | null]) => {
        setState({listContainer}, false);
    },
    listRef: ({setState}, [list]: [HTMLDivElement | null]) => {
        setState({list}, false);
    },
    updateVisibleItemsCount: (reducerData) => {
        const {state} = reducerData;
        const rowHeight = props.rowHeight ?? 50;
        const visibleRangeOffset = 2;
        let intervalCount = 0;
        let interval: NodeJS.Timeout | undefined = undefined;
        const getVisibleItemsCount = () => {
            if (interval !== undefined && intervalCount >= 10) clearInterval(interval);
            intervalCount += 1;
            if (!state.listContainer) return;
            const height = state.listContainer.clientHeight;
            if (height <= 100) return;
            if (interval !== undefined) clearInterval(interval);
            const visibleItemsCount = Math.round(height / rowHeight) + visibleRangeOffset;
            if (props.debugMode) console.log("[List debug mode] visible items count : " + visibleItemsCount);
            if (state.visibleItemsCount === visibleItemsCount) return;
            reducer(props).setVisibleItemsCount(reducerData, [visibleItemsCount]);
            reducer(props).setVisibleRange(reducerData, [0, visibleItemsCount]);
        }
        getVisibleItemsCount();
        interval = setInterval(() => getVisibleItemsCount(), 50);
    },
    toggleSortAscending: ({state, setState}) => {
        setState({
            prevSkip: undefined,
            sortAscending: !state.sortAscending,
        })
        props.onSortColumn?.(state.columnSort, !state.sortAscending);
    },
    setSortedColumn: ({state, setState}, [columnSort]: [string]) => {
        setState({
            prevSkip: undefined,
            columnSort,
        });
        props.onSortColumn?.(state.columnSort, !state.sortAscending);
    },
    setColumnFilter: ({state, setState}, [{field, value}]: [{
        field: string,
        value: ColumnFilterData
    }]) => {
        const newColumnFilters = new Map(state.columnFilters);
        newColumnFilters.set(field, value);
        setState({columnFilters: newColumnFilters});
    },
    setVisibleItemsCount: ({setState}, [count]: [number]) => {
        setState({visibleItemsCount: count});
    },
    setVisibleRange: ({setState}, visibleRange: State["visibleRange"]) => {
        setState({visibleRange});
    },
    clearEditedItem: ({setState}) => {
        setState({editedItem: undefined});
    },
    editItem: ({setState}, [{itemId, field}]: [{
        itemId: string,
        field: string
    }]) => {
        setState({editedItem: {itemId, field}});
    },
    setIsMounted: ({setState}, [mounted]: [boolean]) => {
        setState({mounted}, false);
    },
    setSkip: ({state, setState}, [nextPageIndex]: [number | undefined]) => {
        if (state.skip !== 999999) setState({prevSkip: state.skip, skip: nextPageIndex}, false);
        else setState({skip: nextPageIndex}, false);
    },
    setColumnFiltersTimeout: ({setState}, [columnFiltersTimeout]: [State["columnFiltersTimeout"]]) => {
        setState({columnFiltersTimeout}, false);
    },
    resetNextPageIndex: ({setState}) => {
        setState({prevSkip: undefined, skip: 999999});
    },
    updateColumnFilter: (reducerData, [{field, type, text}]: [{
        field: string,
        type: ListColumnFilterType,
        text: string
    }]) => {
        let regexp: RegExp;
        switch (type) {
            case ListColumnFilterType.Contains:
                regexp = new RegExp(".*" + text + ".*");
                break;
            case ListColumnFilterType.StartWith:
                regexp = new RegExp("^" + text + ".*");
                break;
            case ListColumnFilterType.EndWith:
                regexp = new RegExp(".*" + text + "$");
                break;
            default:
                regexp = new RegExp(".*");
                break;
        }
        reducer(props).setColumnFilter(reducerData, [{field, value: {type, text, regexp}}]);
        reducer(props).resetNextPageIndex(reducerData);
        reducer(props).notifyParentColumnFiltersUpdated(reducerData, [text.length > 0]);
    },
    notifyParentColumnFiltersUpdated: (reducerData, [throttle]: [boolean]) => {
        const {state} = reducerData;
        const data: Record<string, string> = {};
        state.columnFilters.forEach((value, key) => {
            if (value.text) data[key] = value.text;
        });
        if (!throttle || !props.filterByColumnThrottlingInMs) return props.onFilterByColumn?.(data);
        throttleNotification({
            throttling: props.filterByColumnThrottlingInMs,
            timeout: state.columnFiltersTimeout,
            updateTimeout: (timeout) => reducer(props).setColumnFiltersTimeout(reducerData, [timeout]),
            notifyChange: () => props.onFilterByColumn?.(data),
        });
    },
    stopEditOnOutsideClick: (reducerData) => {
        const {state} = reducerData;
        if (!state.mounted) return;
        const handler = () => reducer(props).clearEditedItem(reducerData);
        document.body.addEventListener("click", handler);
        return () => document.body.removeEventListener("click", handler);
    },
    handleScrollList: (reducerData, [e]: [React.SyntheticEvent]) => {
        const {state} = reducerData;
        const element = e.target as HTMLDivElement;
        let rangeStart = Math.floor(element.scrollTop / (props.rowHeight ?? defaultRowHeight)) - visibleRangeOffset;
        if (rangeStart < 0) rangeStart = 0;
        const rangeEnd = Math.floor(state.visibleItemsCount + rangeStart) + visibleRangeOffset;
        reducer(props).setVisibleRange(reducerData, [rangeStart, rangeEnd]);
    },
    handleResetColumnFilters: (reducerData, _, field: string) => {
        reducer(props).updateColumnFilter(reducerData, [{field, type: ListColumnFilterType.Contains, text: ""}]);
    },
    handleChangeColumnFilterType: (reducerData, [, data]: [React.SyntheticEvent | null, DropdownProps], field: string) => {
        const {state} = reducerData;
        const type = (data.value as {
            value: ListColumnFilterType
        }).value;
        if (type === undefined) return;
        let filterData = state.columnFilters.get(field);
        const text = filterData?.text ?? "";
        reducer(props).updateColumnFilter(reducerData, [{field, type, text}]);
    },
    handleChangeColumnFilterText: (reducerData, [, data]: [React.SyntheticEvent, InputProps | undefined], field: string) => {
        const {state} = reducerData;
        const text = ((data?.value ?? "") + "");
        let filterData = state.columnFilters.get(field);
        const type = filterData?.type ?? ListColumnFilterType.Contains;
        reducer(props).updateColumnFilter(reducerData, [{field, type, text}]);
    },
    handleSortColumn: (reducerData, _, extraArg: {
        columns: IListColumn<IListItem<T>>[],
        columnName: string
    }) => {
        const {state} = reducerData;
        const {columns, columnName} = extraArg;
        const column = columns.find(c => c.field === columnName);
        if (!column || !column.sort) return;
        if (columnName === state.columnSort) {
            reducer(props).toggleSortAscending(reducerData);
            return;
        }
        if (props.debugMode) console.log("[List debug mode] Sorted by column : " + columnName);
        else reducer(props).setSortedColumn(reducerData, [columnName]);
    },
    handleEditItem: (reducerData, [e]: [React.SyntheticEvent], extraArg: {
        itemId: string,
        field: string
    }) => {
        const {itemId, field} = extraArg;
        e.stopPropagation();
        reducer(props).editItem(reducerData, [{itemId, field}]);
        // Fix issue when opening dropdowns and date pickers
        setTimeout(() => {
            reducer(props).editItem(reducerData, [{itemId, field}]);
        }, 0);
    },
    checkIfNeedToFetchNextItems: (reducerData, [{items, filteredItems}]: [{
        items: IListItem<T>[] | undefined,
        filteredItems: IListItem<T>[]
    }]) => {
        const {state} = reducerData;
        const isFetchOnScrollEnabled = !!props.fetchOnScroll && !!props.fetchNextItems;
        if (!isFetchOnScrollEnabled) return;
        const areIndexesValid = !!state.skip && !!state.visibleRange[1];
        if (!areIndexesValid) return;
        const realVisibleRange: [number, number] = [
            (state.visibleRange[0] ?? 0) + visibleRangeOffset,
            (state.visibleRange[1] ?? 0) - visibleRangeOffset
        ];
        const hasReachNextPageIndex = filteredItems.length < realVisibleRange[1] || realVisibleRange[1] >= state.skip!;
        const areItemsSkeletons = items?.every(i => i == null);
        if (!items || areItemsSkeletons || !hasReachNextPageIndex || state.skip === 999999) return;
        reducer(props).setSkip(reducerData, [999999]);
        props.fetchNextItems!(items?.length, state.visibleItemsCount);
    }
}) satisfies MagicReducerObject<State>;

const throttleNotification = (data: {
    throttling: number,
    timeout: NodeJS.Timeout | undefined,
    updateTimeout: (timeout: NodeJS.Timeout | undefined) => void,
    notifyChange: () => void
}) => {
    const {throttling, timeout, updateTimeout, notifyChange} = data;
    if (timeout) clearTimeout(timeout);
    const newTimeout = setTimeout(() => {
        notifyChange();
        updateTimeout(undefined);
    }, throttling);
    updateTimeout(newTimeout)
}

export const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();