import React, {memo, ReactElement} from "react";
import {Props} from "./ImagePreviewDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ImagePreviewDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Image} from "@fluentui/react-northstar";
import "./ImagePreviewDialog.styles.scss";
import {translations} from "../../../translations";
import {ImageFilled} from "@fluentui/react-icons";

export const ImagePreviewDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    return (
        <Dialog
            externalRef={dialogRef}
            title={translations.get("ImagePreview")}
            width={1500}
            onClose={dispatch("reset")}
            icon={<ImageFilled fontSize={22} className={"primary-color"}/>}
            content={<Image fluid style={{objectFit: "contain"}} src={state.source}/>}
        />
    )
}, CompareModule.areObjectsEqual);