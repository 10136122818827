import {GraphScopesData} from "../types/GraphScopesData";
import {translations} from "../translations";
import {TeamsUserCredential} from "@microsoft/teamsfx";
import {TokenApi} from "../apis/Token/TokenApi";
import {AuthProvider} from "@microsoft/microsoft-graph-client/src/IAuthProvider";
import {Client} from "@microsoft/microsoft-graph-client";
import * as microsoftTeams from "@microsoft/teams-js";
import {QueryClient, useQuery, useQueryClient} from "@tanstack/react-query";
import {GraphScopesInitArgs} from "../types/GraphScopesInitArgs";
import {TeamsFxConfig} from "../types/TeamsFxConfig";
import {ErrorModule} from "../components/others/ErrorBoundary/ErrorBoundary";
import {useCallback} from "react";
import {usePropertySelectorRendering} from "./usePropertySelectorRendering";

export let teamsFxGraphClient: Client | undefined = undefined;
export let teamsFxGraphBetaClient: Client | undefined = undefined;
export let scopesServiceButtonAction: (() => Promise<void>) | undefined = undefined;

export const graphScopesCacheKey = "graphScopes";

const initialData: GraphScopesData = {
    loaded: false,
    needToConsent: false,
    isConsenting: false,
    teamsFxConfig: undefined,
}

export const useGraphScopes = () => {
    const queryClient = useQueryClient();

    const {data} = useQuery({
        queryKey: [graphScopesCacheKey],
        queryFn: () => ({...initialData}),
        staleTime: Infinity,
    });

    const bindConsentButton = useCallback(async (ref: HTMLButtonElement | null) => {
        if (!ref) return;
        if (!scopesServiceButtonAction) return ErrorModule.showErrorAlert("Consent button action is undefined");
        await handleConsentButtonClick(ref, scopesServiceButtonAction)
    }, []);

    return {
        data: data ?? initialData,
        initializeScope: initializeScope(queryClient),
        bindConsentButton,
    }
}

export const useGraphScopesSelector = <ARG extends (keyof GraphScopesData)[]>(...args: ARG): { [key in ARG[number]]: GraphScopesData[key] } => {
    return usePropertySelectorRendering({args, getLocalData, initialData, cacheKey: graphScopesCacheKey});
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getLocalData = (queryClient: QueryClient) => {
    return queryClient.getQueryData([graphScopesCacheKey]) as GraphScopesData | undefined;
}

const setLocalData = (queryClient: QueryClient, data: Partial<GraphScopesData>) => {
    const localData = getLocalData(queryClient);
    queryClient.setQueryData([graphScopesCacheKey], {...localData, ...data});
}

export const initializeScope = (queryClient: QueryClient) => async (data: {
    login: (graphScopes: Array<string>, consent: () => void) => Promise<void>,
    initArgs: GraphScopesInitArgs
}) => {
    const {initArgs} = data;
    checkConfig(initArgs);
    const scopes = initArgs.scopes?.map(s => "https://graph.microsoft.com/" + s) ?? [];
    const teamsFxConfig: TeamsFxConfig = {
        initiateLoginEndpoint: initArgs.apiBaseUrl + "/auth-start.html",
        apiEndpoint: initArgs.apiBaseUrl + "",
        clientId: initArgs.clientId + "",
        tenantId: initArgs.tenantId + "",
        scopes: scopes.join(" "),
        authorityHost: "https://login.microsoftonline.com/common/v2.0",
        applicationIdUri: initArgs.apiBaseUrl + "/auth-start.html",
        loginPageTitle: initArgs.loginPageTitle ?? translations.get("NeedToConsent"),
        loginPageSubtitle: initArgs.loginPageSubtitle ?? translations.get("ClickButtonToContinue"),
    };
    setLocalData(queryClient, {teamsFxConfig});
    const teamsFxCredential = new TeamsUserCredential(teamsFxConfig);
    await getGraphClient(queryClient, {teamsFxCredential, scopes, initArgs, login: data.login})
}

export const initGraphClient = async (queryClient: QueryClient) => {
    const graphToken = await TokenApi.getGraphToken();
    if (!graphToken) throw new Error("Invalid graph token");
    const authProvider: AuthProvider = (callback) => callback(undefined, graphToken);
    teamsFxGraphClient = Client.init({authProvider});
    teamsFxGraphBetaClient = Client.init({authProvider, defaultVersion: "beta"});
    setLocalData(queryClient, {loaded: true, needToConsent: false, isConsenting: false});
}

const getGraphClient = async (queryClient: QueryClient, data: {
    initArgs: GraphScopesInitArgs,
    scopes: Array<string>,
    teamsFxCredential: TeamsUserCredential,
    login: (graphScopes: Array<string>, consent: () => void) => Promise<void>,
}) => {
    const {teamsFxCredential, scopes, initArgs} = data;
    try {
        return await initGraphClient(queryClient);
    } catch (error) {
        try {
            if (initArgs.isOnMobile && initArgs.inTeams) {
                await teamsFxCredential.login(scopes);
            } else {
                scopesServiceButtonAction = async (): Promise<void> => {
                    setLocalData(queryClient, {isConsenting: true});
                    try {
                        if (initArgs.inTeams) await teamsFxCredential.login(scopes);
                        else await data.login(scopes, () => setLocalData(queryClient, {needToConsent: true}));
                    } catch (e: any) {
                        window.location.reload();
                    }
                }
                setLocalData(queryClient, {needToConsent: true});
                microsoftTeams.app.notifySuccess();
            }
            const initGraphClientInterval = setInterval(async () => {
                try {
                    await initGraphClient(queryClient);
                    clearInterval(initGraphClientInterval);
                } catch (_) {
                    return;
                }
            }, 5000);
        } catch (loginError: any) {
            window.location.reload();
        }
        return;
    }
}

export const checkConfig = (config: GraphScopesInitArgs) => {
    if (!config.scopes || config.scopes.length === 0)
        config.scopes = [".default"];
    if (!config.clientId) return ErrorModule.showErrorAlert("TeamsFx client id is invalid");
    if (!config.apiBaseUrl) return ErrorModule.showErrorAlert("TeamsFx api base url is invalid");
    if (config.apiBaseUrl.lastIndexOf('/') === config.apiBaseUrl.length - 1)
        config.apiBaseUrl = config.apiBaseUrl.substring(0, config.apiBaseUrl.length - 1);
};

const handleConsentButtonClick = (button: HTMLButtonElement | null, action: () => Promise<void>): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        if (!button) return reject(ErrorModule.showErrorAlert("Consent button ref is undefined"));
        const onClickLogin = async () => {
            await action();
            button?.removeEventListener("click", onClickLogin);
            resolve();
        };
        button?.addEventListener("click", onClickLogin);
    });
};