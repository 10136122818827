import {memo, ReactElement, useMemo} from "react";
import {Props} from "./IDidItFormDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./IDidItFormDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex} from "@fluentui/react-northstar";
import "./IDidItFormDialog.styles.scss";
import {translations} from "../../../translations";
import {useBestPracticesCache} from "../../../hooks/cache/bestPracticesCache";
import {useMsTeamsSelector} from "../../../hooks/useMsTeams";
import {getAppConfiguration} from "../../../services/ConfigurationService/ConfigurationService";

export const IDidItFormDialog = memo((props: Props): ReactElement | null => {
    const {userMail} = useMsTeamsSelector("userMail");
    const {bestPractices} = useBestPracticesCache();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const formUrl = useMemo(() => {
        const appConfiguration = getAppConfiguration();
        if (!appConfiguration) throw new Error("App configuration is not loaded");
        const bestPractice = bestPractices?.find(bp => bp.id === state.bestPracticeId);
        const url = new URL(appConfiguration.iDidItFormUrl);
        if (bestPractice) {
            url.searchParams.set(appConfiguration.iDidItFormBPFieldId, bestPractice.title.replace(/ /g, "_"));
        }
        url.searchParams.set(appConfiguration.iDidItFormEmailFieldId, userMail);
        return url.toString();
    }, [state.bestPracticeId, bestPractices]);

    return (
        <Dialog
            externalRef={dialogRef}
            width={600}
            height={800}
            title={translations.get("IDidIt")}
            content={
                <Flex fill className={"i-did-it-form-dialog"}>
                    <iframe
                        src={formUrl}
                        key={state.bestPracticeId}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);