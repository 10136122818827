import {Config, State} from "./SelectPeopleDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Guid} from "common";

export const initialState: State = {
    multiple: false,
    callback: Promise.resolve,
    isLoading: false,
    title: undefined,
    fetchInitialUsers: undefined,
    fetchUsers: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, options: Config) => {
        setState({...initialState, ...options});
        config.dialogRef.dispatch("open")();
    },
    confirm: async ({state, setState}, _, usersIds: Immutable<Array<Guid>>) => {
        if (usersIds.length === 0) return;
        if (state.callback !== Promise.resolve) {
            const result = state.callback?.(usersIds);
            if (result instanceof Promise) {
                setState({isLoading: true});
                await result;
            }
        }
        config.dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;