import {memo, ReactElement} from "react";
import {Props} from "./ConfirmDeleteDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ConfirmDeleteDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Button, Flex, Text, TrashCanIcon} from "@fluentui/react-northstar";
import "./ConfirmDeleteDialog.styles.scss";
import {translations} from "../../../translations";

export const ConfirmDeleteDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    return (
        <Dialog
            externalRef={dialogRef}
            width={450}
            title={state.title}
            content={
                <Flex fill>
                    <Text dangerouslySetInnerHTML={{__html: state.subtitle}}/>
                </Flex>
            }
            footer={
                <Flex fill hAlign={"end"}>
                    <Button
                        className={state.isDeleting ? "" : "delete-btn-primary"}
                        primary content={translations.get("Delete")}
                        icon={<TrashCanIcon outline/>}
                        onClick={dispatch("confirm")}
                        loading={state.isDeleting}
                        disabled={state.isDeleting}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);