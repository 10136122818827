import React, {memo, ReactElement, useMemo, useState} from "react";
import {Props, ShareDialogStep, ShareDialogStepData} from "./ShareItemDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShareItemDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./ShareItemDialog.styles.scss";
import {translations} from "../../../translations";
import {useShareItemForm} from "../../../hooks/forms/useShareItemForm";
import {ArrowLeftIcon, ArrowRightIcon, Button, CloseIcon, Flex, SendIcon, Text} from "@fluentui/react-northstar";
import variables from "variables.module.scss";
import {ChevronRightFilled} from "@fluentui/react-icons";
import {Details} from "./steps/Details/Details";
import {Images} from "./steps/Images/Images";
import {Resources} from "./steps/Resources/Resources";
import {Publish} from "./steps/Publish/Publish";
import {Home} from "./steps/Home/Home";

export const ShareItemDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [disableForm, setDisableForm] = useState(false);
    const form = useShareItemForm(disableForm);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        form,
        setDisableForm,
    }), initialState, props.externalRef);

    const stepItems = useMemo(generateStepsItems, []);

    let content: ReactElement;
    let isStepValid: boolean;

    switch (state.step) {
        case ShareDialogStep.Process:
            content = <Home/>;
            isStepValid = true;
            break;
        case ShareDialogStep.Overview:
            content = <Details form={form.detailsForm}/>;
            isStepValid = form.detailsForm.isValid;
            break;
        case ShareDialogStep.Illustrations:
            content = <Images form={form.imagesForm}/>;
            isStepValid = form.imagesForm.isValid;
            break;
        case ShareDialogStep.Content:
            content = (
                <Resources
                    resources={state.resources}
                    form={form.resourcesForm}
                    onAddResource={dispatch("addResource")}
                    onRemoveResource={dispatch("removeResource")}
                    disabled={state.isPublishing}
                />
            );
            isStepValid = form.resourcesForm.isValid;
            break;
        case ShareDialogStep.Publish:
            content = <Publish isPublishing={state.isPublishing}/>;
            isStepValid = true;
            break;
    }

    const showBackButton = state.step > ShareDialogStep.Process && state.step < ShareDialogStep.Publish;

    let rightButton: ReactElement;
    switch (state.step) {
        case ShareDialogStep.Publish:
            rightButton = renderCloseButton(dialogRef.dispatch("close"));
            break;
        case ShareDialogStep.Content:
            rightButton = renderShareButton(dispatch("publish"), isStepValid, state.isPublishing)
            break;
        default:
            rightButton = renderNextButton(dispatch("nextStep"), isStepValid);
    }

    return (
        <Dialog
            externalRef={dialogRef}
            width={900}
            height={600}
            title={translations.get("ShareBestPractice")}
            className={"share-item-dialog"}
            noPadding
            content={
                <Flex fill column>
                    <Flex className={"share-item-dialog-header"}>
                        {stepItems.map(item => renderStepItem(item, state.step, stepItems.length))}
                    </Flex>
                    <Flex fill className={"share-item-dialog-content"}>
                        {content}
                    </Flex>
                </Flex>
            }
            footerShadow
            footer={
                <Flex hAlign={"end"}>
                    <Flex fill>
                        {showBackButton && renderBackButton(dispatch("prevStep"), state.isPublishing)}
                    </Flex>
                    {rightButton}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateStepsItems = (): Array<ShareDialogStepData> => [
    {
        step: ShareDialogStep.Process,
        title: translations.get("Process"),
    },
    {
        step: ShareDialogStep.Overview,
        title: translations.get("Overview"),
    },
    {
        step: ShareDialogStep.Illustrations,
        title: translations.get("Illustrations"),
    },
    {
        step: ShareDialogStep.Content,
        title: translations.get("Content"),
    }
]

const renderStepItem = (item: ShareDialogStepData, currentStep: number, itemsCount: number) => {
    const isDisabled = item.step > currentStep;
    const color = isDisabled ? "darkgray" : variables.primaryColor;
    return (
        <Flex key={item.step} vAlign={"center"} style={{gap: "5px", color}}>
            <Flex vAlign={"center"} hAlign={"center"} style={{background: color}}
                  className={"share-item-dialog-header-badge"}>
                {item.step + 1}
            </Flex>
            <Text weight={"semibold"} content={item.title}/>
            {item.step < itemsCount - 1 &&
                <ChevronRightFilled style={{marginBottom: "-3px"}} fontSize={16}/>
            }
        </Flex>
    )
}

const renderNextButton = (onClick: () => void, isStepValid: boolean) => (
    <Button
        primary
        className={"no-shrink"}
        content={translations.get("Next")}
        onClick={onClick}
        icon={<ArrowRightIcon/>}
        iconPosition={"after"}
        disabled={!isStepValid}
    />
)

const renderShareButton = (onClick: () => void, isStepValid: boolean, isPublishing: boolean) => (
    <Button
        primary
        className={"no-shrink"}
        content={translations.get("Share")}
        onClick={onClick}
        icon={<SendIcon outline/>}
        disabled={!isStepValid || isPublishing}
        loading={isPublishing}
    />
)

const renderBackButton = (onClick: () => void, isPublishing: boolean) => (
    <Button
        className={"no-shrink"}
        content={translations.get("Back")}
        onClick={onClick}
        icon={<ArrowLeftIcon/>}
        disabled={isPublishing}
    />
)

const renderCloseButton = (onClick: () => void) => (
    <Button
        className={"no-shrink"}
        content={translations.get("Close")}
        onClick={onClick}
        icon={<CloseIcon/>}
    />
)