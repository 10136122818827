import {Dropdown, useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {useAllCommunitiesCache} from "../cache/useCommunityCache";
import {useBestPracticesCache} from "../cache/bestPracticesCache";
import {useMemo} from "react";
import {useUsersLocationsCache} from "../cache/useUsersLocationsCache";

export const useNotificationConfigurationForm = (disabled: boolean) => {
    const {bestPractices} = useBestPracticesCache();
    const {communities} = useAllCommunitiesCache();
    const {locations} = useUsersLocationsCache();

    const jobFamilies = useMemo(() => {
        return Array.from(new Set(bestPractices?.flatMap(bp => bp.jobFamilies) ?? []));
    }, [bestPractices]);

    return useForm({
        disabled,
        items: {
            title: {
                type: "input",
                label: translations.get("Title"),
                inputMode: "text",
                required: true,
                maxLength: 128,
                placeholder: translations.get("EnterTitle"),
            },
            subtitle: {
                type: "textArea",
                label: translations.get("Subtitle"),
                required: true,
                maxLength: 128,
                minHeight: "80px",
                placeholder: translations.get("EnterSubtitle"),
            },
            community: Dropdown({
                label: translations.get("Community"),
                placeholder: translations.get("SelectACommunity"),
                items: (communities ?? []) as Array<string>,
                search: true,
            }),
            location: Dropdown({
                label: translations.get("GeographicalPosition"),
                placeholder: translations.get("SelectAPosition"),
                items: (locations ?? []),
                search: true,
            }),
            jobFamily: Dropdown({
                label: translations.get("JobFamily"),
                placeholder: translations.get("SelectAJobFamily"),
                items: jobFamilies,
                search: true,
            }),
        }
    });
}