import {createContext, ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "./DialogContext.types";
import {
    ConfirmCancelChangesDialog
} from "../../components/dialogs/ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog/ConfirmDialog";
import {ConfirmDeleteDialog} from "../../components/dialogs/ConfirmDeleteDialog/ConfirmDeleteDialog";
import {SelectPeopleDialog} from "../../components/dialogs/SelectPeopleDialog/SelectPeopleDialog";
import {FilePreviewDialog} from "../../components/dialogs/FilePreviewDialog/FilePreviewDialog";
import {RenameFileDialog} from "../../components/dialogs/RenameFileDialog/RenameFileDialog";
import {ImagePreviewDialog} from "../../components/dialogs/ImagePreviewDialog/ImagePreviewDialog";
import {NewsDialog} from "../../components/dialogs/NewsDialog/NewsDialog";
import {ShareItemDialog} from "../../components/dialogs/ShareItemDialog/ShareItemDialog";
import {WelcomeDialog} from "../../components/dialogs/WelcomeDialog/WelcomeDialog";
import {UserSettingsDialog} from "../../components/dialogs/UserSettingsDialog/UserSettingsDialog";
import {FilePickerDialog} from "../../components/dialogs/FilePickerDialog/FilePickerDialog";
import {LaunchpadAppsListDialog} from "../../components/dialogs/LaunchpadAppsListDialog/LaunchpadAppsListDialog";
import {LaunchpadAppDialog} from "../../components/dialogs/LaunchpadAppDialog/LaunchpadAppDialog";
import {Top3ContentDialog} from "../../components/dialogs/Top3ContentDialog/Top3ContentDialog";
import {IDidItFormDialog} from "../../components/dialogs/IDidItFormDialog/IDidItFormDialog";

export const useDialogContextValue = () => {
    const confirmDeleteDialog = useMagicReducerRef(ConfirmDeleteDialog);
    const selectPeopleDialog = useMagicReducerRef(SelectPeopleDialog);
    const confirmCancelChangesDialog = useMagicReducerRef(ConfirmCancelChangesDialog);
    const confirmDialog = useMagicReducerRef(ConfirmDialog);
    const filePreviewDialog = useMagicReducerRef(FilePreviewDialog);
    const renameFileDialog = useMagicReducerRef(RenameFileDialog);
    const imagePreviewDialog = useMagicReducerRef(ImagePreviewDialog);
    const newsDialog = useMagicReducerRef(NewsDialog);
    const shareItemDialog = useMagicReducerRef(ShareItemDialog);
    const welcomeDialog = useMagicReducerRef(WelcomeDialog);
    const userSettingsDialog = useMagicReducerRef(UserSettingsDialog);
    const filePickerDialog = useMagicReducerRef(FilePickerDialog);
    const launchpadAppsListDialog = useMagicReducerRef(LaunchpadAppsListDialog);
    const launchpadAppDialog = useMagicReducerRef(LaunchpadAppDialog);
    const top3ContentDialog = useMagicReducerRef(Top3ContentDialog);
    const iDidItDialog = useMagicReducerRef(IDidItFormDialog);

    return useMemo(() => ({
        confirmDeleteDialog,
        selectPeopleDialog,
        confirmCancelChangesDialog,
        confirmDialog,
        filePreviewDialog,
        renameFileDialog,
        imagePreviewDialog,
        newsDialog,
        shareItemDialog,
        welcomeDialog,
        userSettingsDialog,
        filePickerDialog,
        launchpadAppsListDialog,
        launchpadAppDialog,
        top3ContentDialog,
        iDidItDialog,
    }), []);
}

const DialogContext = createContext<DialogContextValue | undefined>(undefined);

export const DialogProvider = (props: { children: ReactElement }) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => setInitialized(true), []);

    const value = useDialogContextValue();

    return (
        <DialogContext.Provider value={value}>
            {initialized && props.children}
            <RenameFileDialog externalRef={value.renameFileDialog}/>
            <NewsDialog externalRef={value.newsDialog}/>
            <ShareItemDialog externalRef={value.shareItemDialog}/>
            <WelcomeDialog externalRef={value.welcomeDialog}/>
            <UserSettingsDialog externalRef={value.userSettingsDialog}/>
            <LaunchpadAppsListDialog externalRef={value.launchpadAppsListDialog}/>
            <LaunchpadAppDialog externalRef={value.launchpadAppDialog}/>
            <Top3ContentDialog externalRef={value.top3ContentDialog}/>
            <IDidItFormDialog externalRef={value.iDidItDialog}/>
            {/*TOP DIALOGS */}
            <FilePickerDialog externalRef={value.filePickerDialog}/>
            <FilePreviewDialog externalRef={value.filePreviewDialog}/>
            <ImagePreviewDialog externalRef={value.imagePreviewDialog}/>
            <ConfirmDeleteDialog externalRef={value.confirmDeleteDialog}/>
            <ConfirmDialog externalRef={value.confirmDialog}/>
            <ConfirmCancelChangesDialog externalRef={value.confirmCancelChangesDialog}/>
            <SelectPeopleDialog externalRef={value.selectPeopleDialog}/>
        </DialogContext.Provider>
    );
};

export const useDialogContext = () => {
    const context = useContext(DialogContext);
    if (!context) throw new Error('useDialogContext must be used within a DialogContextProvider');
    return context;
};