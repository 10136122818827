import * as React from "react";
import {ReactElement} from "react";
import {ReactComponent as SharepointSvg} from "./Sharepoint.svg";
import {bundleIcon, FluentIcon, MegaphoneLoudFilled, MegaphoneLoudRegular} from "@fluentui/react-icons";
import {FluentIconsProps} from "@fluentui/react-icons/lib/utils/FluentIconsProps.types";

export type IIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
export type IconProps = React.SVGProps<SVGSVGElement> & { title?: string };
export type IconFunc = (props: IconProps) => ReactElement;

const renderSvgIcon = (Icon: IIcon): IconFunc => (props: IconProps) => {
    const propsClone: IconProps = {...props};
    propsClone.width = propsClone.width ?? 20;
    propsClone.height = propsClone.height ?? 20;
    return <Icon className={"custom-icon"} {...propsClone}/>;
}

export const SharepointIcon = renderSvgIcon(SharepointSvg);

//////////////////////////////////////// FLUENT TOGGLE ICONS ////////////////////////////////////////

const renderFluentToggleIcon = (IconFilled: FluentIcon, IconRegular: FluentIcon): FluentIcon => (props: FluentIconsProps) => {
    const Icon = bundleIcon(IconFilled, IconRegular);
    return <Icon {...props} className={"custom-icon"}/>;
}

export const MegaphoneLoud = renderFluentToggleIcon(MegaphoneLoudFilled, MegaphoneLoudRegular);