import {useEffect, useState} from "react";
import {ItemDataType, ManagementUserData, ManagementUserRole} from "common";
import {queryClient} from "../../index";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ManagementApi} from "../../apis/Management/ManagementApi";
import {useQuery} from "@tanstack/react-query";
import {useUserRolesCache} from "./useUserRoleCache";

const managerRecommendationsCacheKey = "manager_recommended_items";

const myRecommendationsCacheKey = "my_recommended_items";

const retrieveRecommendations = (): Immutable<ManagementUserData["recommendedItems"]> => {
    const managementUserData = queryClient.getQueryData<Immutable<ManagementUserData>>([myRecommendationsCacheKey]);
    return managementUserData?.recommendedItems ?? {bestPractices: [], trainings: []};
}

const updateRecommendations = (data: Immutable<ManagementUserData["recommendedItems"]>) => {
    const managementUserData = queryClient.getQueryData<Immutable<ManagementUserData>>([myRecommendationsCacheKey]);
    if (!managementUserData) return;
    const newManagementUserData = {...managementUserData, recommendedItems: data};
    queryClient.setQueryData([myRecommendationsCacheKey], newManagementUserData);
}

const isItemRecommended = (type: ItemDataType | undefined, itemId: string | undefined) => {
    if (type === undefined || !itemId) return false;
    const recommendations = retrieveRecommendations();
    switch (type) {
        case ItemDataType.BestPractice:
            return recommendations?.bestPractices.includes(itemId) ?? false;
        case ItemDataType.Training:
            return recommendations?.trainings.includes(itemId) ?? false;
    }
}

const toggleRecommendation = (
    setIsRecommended: (value: boolean) => void,
    type: ItemDataType | undefined,
    itemId: string | undefined
) => async () => {
    if (type === undefined || !itemId) return;
    const recommendations = retrieveRecommendations();
    let updatedData: ManagementUserData["recommendedItems"] = {
        bestPractices: [...recommendations.bestPractices],
        trainings: [...recommendations.trainings],
    };
    const hasBeenRecommended = !isItemRecommended(type, itemId);
    let arrayKey: keyof ManagementUserData["recommendedItems"] = "bestPractices";
    if (type === ItemDataType.Training) arrayKey = "trainings";
    if (hasBeenRecommended) {
        if (!updatedData[arrayKey].includes(itemId)) updatedData[arrayKey].push(itemId);
    } else {
        updatedData[arrayKey] = updatedData[arrayKey].filter(key => key !== itemId);
    }
    setIsRecommended(hasBeenRecommended);
    updateRecommendations(updatedData);
    await ManagementApi.updateItemRecommendation({itemId, type, isRecommended: hasBeenRecommended});
}

export const useMyManagerRecommendationsCache = () => {
    const {data: managerRecommendations} = useQuery({
        queryKey: [managerRecommendationsCacheKey],
        queryFn: ManagementApi.getManagerRecommendations,
        staleTime: Infinity,
    });

    return {
        managerRecommendations,
    };
}

export const useMyRecommendationsCache = () => {
    const {isBasicUser} = useUserRolesCache();

    const {data: userRecommendationsData} = useQuery({
        queryKey: [myRecommendationsCacheKey],
        queryFn: ManagementApi.getMyRecommendations,
        staleTime: Infinity,
    });

    const canRecommendItem = !isBasicUser && userRecommendationsData?.role !== ManagementUserRole.Report;

    const recommendations = userRecommendationsData?.recommendedItems;

    return {
        managementRole: userRecommendationsData?.role,
        canRecommendItem,
        recommendations,
    };
};

export const useItemRecommendationCache = (type: ItemDataType | undefined, itemId: string | undefined) => {
    const [isRecommended, setIsRecommended] = useState<boolean>(isItemRecommended(type, itemId));

    useEffect(() => {
        setIsRecommended(isItemRecommended(type, itemId));
    }, [type, itemId]);

    return {
        isRecommended,
        toggleRecommendation: toggleRecommendation(setIsRecommended, type, itemId),
    }
}