import {useUserDataCache} from "./userDataCache";

export const useUserRolesCache = (enabled: boolean = true) => {
    const {userData} = useUserDataCache(enabled);

    return {
        ...userData?.role,
        isBasicUser: !userData?.role?.isAdmin && !userData?.role?.isCommunityManager,
        isLoading: !enabled || !userData,
    }
}