import {IListColumn} from "../../List/List.types";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {FileData} from "common";
import {translations} from "../../../../translations";
import React, {useMemo} from "react";
import {Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {Props} from "../FileList.types";
import {FileModule} from "../../../../modules/File.module";
import {useDialogContext} from "../../../../services/DialogContext/DialogContext";
import {DialogContextValue} from "../../../../services/DialogContext/DialogContext.types";

export const useFileListColumns = (props: Props): Array<IListColumn<Immutable<FileData> | null>> => {
    const {filePreviewDialog} = useDialogContext();

    return useMemo(() => [
        generateTitleColumn(filePreviewDialog),
    ], []);
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateTitleColumn = (filePreviewDialog: DialogContextValue["filePreviewDialog"]): IListColumn<Immutable<FileData> | null> => ({
    field: "file",
    label: translations.get("Name"),
    render: item => {
        const Icon = FileModule.getFileIcon(item?.fileType);
        return (
            <Flex vAlign={"center"} gap={"gap.small"}>
                <Icon/>
                <Text content={item?.name ?? ""}/>
            </Flex>
        )
    },
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    onClick: file => filePreviewDialog.dispatch("open", {file: file as Immutable<FileData>, hideActions: true})(),
});