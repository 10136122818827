import axios, {AxiosError, CancelTokenSource} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Guid, ReportingData, ReportingDataItem, UserReport} from "common";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";

let getReportCancelToken: CancelTokenSource | undefined = undefined;
let downloadReportCancelToken: CancelTokenSource | undefined = undefined;

const getReport = async (start: string, end: string): Promise<Immutable<ReportingData> | undefined> => {
    if (getReportCancelToken) getReportCancelToken.cancel();
    getReportCancelToken = axios.CancelToken.source();
    try {
        const response = await AxiosConfig.instance.post("/reporting", {
            start,
            end
        }, {cancelToken: getReportCancelToken.token});
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) return;
        ErrorModule.showErrorAlert("Can't get reporting data", error as AxiosError);
    }
}

const downloadReport = async (start: string, end: string) => {
    if (downloadReportCancelToken) downloadReportCancelToken.cancel();
    downloadReportCancelToken = axios.CancelToken.source();
    try {
        const response = await AxiosConfig.instance.post("/reporting/download", {
            start,
            end
        }, {
            cancelToken: downloadReportCancelToken.token,
            responseType: "blob"
        });
        return new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    } catch (error) {
        if (axios.isCancel(error)) return;
        ErrorModule.showErrorAlert("Can't download reporting data", error as AxiosError);
    }
}

const getSessionsUserIds = async (): Promise<Immutable<Array<Guid>>> => {
    try {
        const response = await AxiosConfig.instance.get(`/reporting/sessions/userIds`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user ids", error as AxiosError);
        return [];
    }
}

const getMyReport = async (): Promise<Immutable<UserReport | undefined>> => {
    try {
        const response = await AxiosConfig.instance.get(`/reporting/users/me`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user report", error as AxiosError);
    }
}

const reportEvent = async <T extends ReportingDataItem>(event: T) => {
    try {
        await AxiosConfig.instance.post(`/reporting/event`, event);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't report event", error as AxiosError);
    }
}

export const ReportingApi = {
    getReport,
    downloadReport,
    getSessionsUserIds,
    getMyReport,
    reportEvent
}