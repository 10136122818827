import {HostClientType} from "@microsoft/teams-js";
import {AppView} from "../types/AppView/AppView";
import {
    Guid,
    GuidModule,
    ItemDataType,
    OpenedItemReportingData,
    QueryReportingData,
    ReportEventType,
    SessionReportingData,
    SharedItemReportingData,
    TopicReportingData
} from "common";
import {ReportingApi} from "../apis/Reporting/ReportingApi";

const notifySession = async (
    userId: Guid | undefined,
    durationInSeconds: number,
    device: HostClientType = HostClientType.web,
    sessionId: string,
) => {
    if (!userId) return;
    await ReportingApi.reportEvent<SessionReportingData>({
        id: GuidModule.generateGUID(),
        eventType: ReportEventType.Session,
        userId,
        duration: durationInSeconds,
        device,
        sessionId,
    });
}

const notifyQuery = async (userId: Guid | undefined, query: string, view: AppView) => {
    if (!userId || !query) return;
    await ReportingApi.reportEvent<QueryReportingData>({
        id: GuidModule.generateGUID(),
        eventType: ReportEventType.Query,
        userId,
        query: query.toLowerCase(),
        view: AppView[view],
    });
}

const notifyItemOpened = async (userId: Guid | undefined, itemId: string | undefined, type: ItemDataType | undefined) => {
    if (!userId || itemId === undefined || type === undefined) return;
    await ReportingApi.reportEvent<OpenedItemReportingData>({
        id: GuidModule.generateGUID(),
        eventType: ReportEventType.ItemOpened,
        userId,
        itemId,
        type: ItemDataType[type],
    });
}

const notifyTopicSelected = async (userId: Guid | undefined, topic: string | undefined, view: AppView) => {
    if (!userId || topic === undefined || topic.toLowerCase() === "all") return;
    await ReportingApi.reportEvent<TopicReportingData>({
        id: GuidModule.generateGUID(),
        eventType: ReportEventType.TopicSelected,
        userId,
        topic,
        view: AppView[view],
    });
}

const notifyItemShared = async (userId: Guid | undefined, itemId: string | undefined, type: ItemDataType | undefined) => {
    if (!userId || itemId === undefined || type === undefined) return;
    await ReportingApi.reportEvent<SharedItemReportingData>({
        id: GuidModule.generateGUID(),
        eventType: ReportEventType.ItemShared,
        userId,
        itemId,
        type: ItemDataType[type],
    });
}

export const ReportingModule = {
    notifySession,
    notifyQuery,
    notifyItemOpened,
    notifyTopicSelected,
    notifyItemShared
}