import * as microsoftTeams from "@microsoft/teams-js";

const openPageInStageView = async (pageUrl: string, fullLocale?: string) => {
    if (!pageUrl) return;
    if (!SharepointModule.isPageUrl(pageUrl)) return window.open(pageUrl, "_blank");
    const linkWithQuery = new URL(pageUrl);
    linkWithQuery.searchParams.set("useTeamsAuth", "true");
    linkWithQuery.searchParams.set("env", "WebView");
    if (fullLocale) linkWithQuery.searchParams.set("locale", fullLocale);
    const vivaConnectionsAppId = "d2c6f111-ffad-42a0-b65e-ee00425598aa";
    const context = {contentUrl: linkWithQuery, websiteUrl: linkWithQuery};
    const encodeContext = encodeURIComponent(JSON.stringify(context));
    const deeplink = `https://teams.microsoft.com/l/stage/${vivaConnectionsAppId}/0?context=${encodeContext}`;
    await microsoftTeams.app.openLink(deeplink);
}

const isPageUrl = (url: string) => RegExp(/https:\/\/.*?\.sharepoint\.com\/sites\/.*\.aspx/g).test(url);

export const SharepointModule = {
    openPageInStageView,
    isPageUrl,
}