import {memo, ReactElement} from "react";
import {Props} from "./LaunchpadAppDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./LaunchpadAppDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./LaunchpadAppDialog.styles.scss";
import {useAppForm} from "../../../hooks/forms/useAppForm";
import {translations} from "../../../translations";

export const LaunchpadAppDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useAppForm(state.appId);

    const title = translations.get(!state.appId ? "NewApp" : "EditApp");

    return (
        <Dialog
            className={"launchpad-app-dialog"}
            externalRef={dialogRef}
            width={500}
            title={title}
            onClose={dispatch("reset", form.reset)}
            content={form.renderForm()}
            footer={form.renderButtons(dialogRef.dispatch("close"))}
        />
    )
}, CompareModule.areObjectsEqual);