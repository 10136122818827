import {State} from "./NotificationSentDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "../../../components/dialogs/Dialog/Dialog";

export const initialState: State = {}

export const reducer = (dialogRef: MagicReducerRef<typeof Dialog>) => ({
    open: () => {
        dialogRef.dispatch("open")();
    }
}) satisfies MagicReducerObject<State>;