import {memo, ReactElement} from "react";
import {Props} from "./GraphVideoPlayer.types";
import {CompareModule} from "modules/Compare.module";
import "./GraphVideoPlayer.styles.scss";
import {useGraphVideoCache} from "../../../hooks/cache/useGraphVideoCache";

export const GraphVideoPlayer = memo((props: Props): ReactElement | null => {
    const {src} = useGraphVideoCache(props.itemId, props.driveId);

    return (
        <iframe
            title={"News Video"}
            className={"graph-video-player"}
            src={src}
            style={{minHeight: props.height, minWidth: props.width, ...(props.style ?? {})}}
            allowFullScreen
        />
    )
}, CompareModule.areObjectsEqual);