import {Props, State} from "./Resources.types";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {IFilePickerFile} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/FilePicker/FilePicker";
import {FileModule} from "../../../../../modules/File.module";

export const initialState: State = {
    resources: [],
}

export const reducer = (props: Props) => ({
    fileSelected: ({}, _, fileData: Immutable<IFilePickerFile> | undefined) => {
        if (!fileData) return;
        const file = FileModule.generateFileDataFromFile(fileData);
        if (!file) return;
        props.onAddResource(file);
        props.form.reset();
    },
}) satisfies MagicReducerObject<State>;