import {isValidElement, ReactElement} from "react";
import {Immutable} from "@witivio_teamspro/use-reducer";

function areValuesEqual<T>(val1: T, val2: T, deepComparison: boolean = false): boolean {
    if (!val1 && !val2) return true;
    if ((!val1 && !!val2) || (!!val1 && !val2)) return false;
    if (typeof val1 !== typeof val2) return false;
    if (isValidElement(val1) && isValidElement(val2)) {
        const reactElement1 = val1 as ReactElement;
        const reactElement2 = val2 as ReactElement;
        const isSameKey = reactElement1.key === reactElement2.key;
        if (!isSameKey) return false;
        const isSameType = reactElement1.type === reactElement2.type;
        if (!isSameType) return false;
        return areObjectsEqual(reactElement1.props, reactElement2.props);
    } else if (val1 instanceof HTMLElement && val2 instanceof HTMLElement) {
        return val1.isEqualNode(val2);
    } else if (Array.isArray(val1) && Array.isArray(val2)) {
        return areArraysEqual(val1, val2, deepComparison);
    } else if (typeof val1 === 'object' && val1 instanceof Date && val2 instanceof Date) {
        return val1.toISOString() === val2.toISOString();
    } else if (typeof val1 === 'object' && typeof val2 === 'object') {
        return areObjectsEqual(val1, val2, deepComparison);
    }
    return val1 === val2;
}

function areObjectsEqual<T extends object | null | undefined>(obj1: T, obj2: T, deepComparison: boolean = false): boolean {
    if (!obj1 && !obj2) return true;
    if ((!obj1 && !!obj2) || (!!obj1 && !obj2)) return false;
    if (typeof obj1 !== typeof obj2) return false;
    if (Object.keys(obj1 ?? {}).length !== Object.keys(obj2 ?? {}).length) return false;
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            const val1 = obj1?.[key as never] as any;
            const val2 = obj2?.[key as never] as any;
            if (!areValuesEqual(val1, val2, deepComparison)) return false;
        }
    }
    return true;
}

function areArraysEqual(arr1: Immutable<any[]> | null | undefined, arr2: Immutable<any[]> | null | undefined, deepComparison: boolean = false): boolean {
    if (!arr1 && !arr2) return true;
    if ((!arr1 && !!arr2) || (!!arr1 && !arr2)) return false;
    if (arr1?.length !== arr2?.length) return false;
    if (arr1?.length === 0 && arr2?.length === 0) return true;
    const firstItemType = typeof arr1?.[0];
    if (deepComparison && firstItemType === 'object') {
        const visitedIndices = new Set<number>();
        for (let i = 0; i < (arr1?.length ?? 0); i++) {
            const val1 = arr1?.[i];
            let foundMatch = false;
            for (let j = 0; j < (arr2?.length ?? 0); j++) {
                if (visitedIndices.has(j)) continue;
                const val2 = arr2?.[j];
                if (areObjectsEqual(val1, val2, true)) {
                    visitedIndices.add(j);
                    foundMatch = true;
                    break;
                }
            }
            if (!foundMatch) return false;
        }
    } else {
        const sortedArr1 = [...(arr1 ?? [])].sort(compareElements);
        const sortedArr2 = [...(arr2 ?? [])].sort(compareElements);
        for (let i = 0; i < sortedArr1.length; i++) {
            const val1 = sortedArr1[i];
            const val2 = sortedArr2[i];
            if (val1 !== val2) return false;
        }
    }
    return true;
}

function compareElements(a: any, b: any): number {
    if (typeof a === 'number' && typeof b === 'number') return a - b;
    if (typeof a === 'string' && typeof b === 'string') return a.localeCompare(b);
    if (typeof a === 'boolean' && typeof b === 'boolean') return (a === b) ? 0 : a ? 1 : -1;
    if (a instanceof Date && b instanceof Date) return a.getTime() - b.getTime();
    return 0;
}

export const CompareModule = {
    areValuesEqual,
    areObjectsEqual,
    areArraysEqual,
}