import React, {memo, ReactElement} from "react";
import {Props} from "./FilePreviewDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./FilePreviewDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Button, CloseIcon, DownloadIcon, EditIcon, FilesErrorIcon, Flex, Loader, Text} from "@fluentui/react-northstar";
import "./FilePreviewDialog.styles.scss";
import {FileModule} from "modules/File.module";
import {translations} from "translations";
import {ScreenModule} from "modules/Screen.module";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {FileType} from "common";

export const FilePreviewDialog = memo((props: Props): ReactElement | null => {
    const {isTeamsApp} = useMsTeamsSelector("isTeamsApp");
    const {isOnMobile} = useMsTeamsSelector("isOnMobile");
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        isOnMobile,
        isTeamsApp
    }), initialState, props.externalRef);

    const hideOneDriveBanner = state.file && state.file?.fileType !== FileType.Video;

    const isSmallScreen = ScreenModule.isSmallScreen();

    const canEdit = FileModule.isFileEditable(state.file?.fileType);

    const FileIcon = FileModule.getFileIcon(state.file?.fileType);

    const editButton = (!canEdit || state.hideActions) ? null : (
        <Button
            tinted
            styles={{minWidth: "fit-content"}}
            icon={<EditIcon outline/>}
            content={translations.get("Edit")}
            fluid={isSmallScreen}
            onClick={dispatch("handleEditFile", "web")}
            disabled={state.blockActions}
        />
    )

    const downloadButton = state.hideActions ? null : (
        <Flex fill={isSmallScreen}>
            <Button
                tinted
                onClick={dispatch("handleDownload")}
                icon={<DownloadIcon outline/>}
                content={translations.get("Download")}
                fluid={isSmallScreen}
                disabled={state.blockActions}
            />
        </Flex>
    )

    const contentClassName = [
        hideOneDriveBanner ? "remove-onedrive-banner" : "",
    ].join(" ");

    const content = (
        <Flex fill vAlign={"center"} hAlign={"center"} className={contentClassName}>
            {state.loaded ? null : <Loader className={"h-100 w-100"}/>}
            {state.error ?
                <Flex column styles={{gap: "3rem"}} vAlign={"center"} hAlign={"center"}>
                    <FilesErrorIcon outline size={"largest"} styles={{transform: "scale(2)"}}/>
                    <Text size={"larger"} content={state.error}/>
                </Flex>
                :
                <iframe
                    key={state.file?.id.toString()}
                    className={"fullscreen-iframe " + (!state.loaded ? "hidden" : "")}
                    style={{visibility: state.loaded ? "visible" : "hidden"}}
                    ref={dispatch("iframeRef")}
                />
            }
        </Flex>
    )

    return (
        <Dialog
            externalRef={dialogRef}
            fullscreen
            icon={<FileIcon size={"large"}/>}
            title={state.file?.name}
            headerShadow
            onClose={dispatch("reset")}
            noPadding
            headerElement={
                <Flex className={"w-100"} gap={"gap.small"} hAlign={"end"} style={{marginRight: "10px"}}>
                    {editButton}
                    {downloadButton}
                </Flex>
            }
            content={
                <Flex fill column onClick={stopPropagation} className={"file-preview-dialog-content"}>
                    {content}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
}