import {AxiosError} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";

const prepareData = async () => {
    try {
        await AxiosConfig.instance.get("/prepare");
    } catch (error) {
        ErrorModule.showErrorAlert("Can't prepare data", error as AxiosError);
    }
}

export const PrepareApi = {
    prepareData
}