"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInfo = void 0;
class UserInfo {
    constructor(userId = UserInfo.AadIdDefault, tenantId = UserInfo.TenantIdDefault) {
        this.userId = userId;
        this.tenantId = tenantId;
    }
}
exports.UserInfo = UserInfo;
UserInfo.AadIdDefault = "system";
UserInfo.TenantIdDefault = "common";
