import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./FilePickerDialog.types";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./FilePickerDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AcceptIcon, Button, Flex, Loader, Text} from "@fluentui/react-northstar";
import "./FilePickerDialog.styles.scss";
import {Input, useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../../translations";
import {SharepointIcon} from "../../../assets/icons";
import {DocumentButton} from "../../buttons/DocumentButton/DocumentButton";
import {FileData} from "common";

export const FilePickerDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useForm({
        items: {
            search: Input({
                inputMode: "text",
                placeholder: state.searchPlaceholder || translations.get("Search"),
            })
        }
    });

    const searchQuery = form.state.data.search;

    useEffect(() => {
        dispatch("searchQueryChanged", searchQuery)();
    }, [searchQuery]);

    return (
        <Dialog
            externalRef={dialogRef}
            width={500}
            height={500}
            icon={<SharepointIcon/>}
            title={state.title || translations.get("SelectAFile")}
            content={
                <Flex fill column gap={"gap.medium"}>
                    {form.formItems.search}
                    {state.isSearching ?
                        renderLoader()
                        :
                        renderFiles(state.files, state.selectedFileId, dispatch("selectFile"))
                    }
                </Flex>
            }
            footer={
                <Flex fill hAlign={"end"}>
                    <Button
                        content={translations.get("Confirm")}
                        primary onClick={dispatch("confirmSelection")}
                        disabled={!state.selectedFileId}
                        icon={<AcceptIcon/>}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual)

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderLoader = () => (
    <Flex fill vAlign={"center"} hAlign={"center"}>
        <Loader/>
    </Flex>
)

const renderEmptyFiles = () => (
    <Flex fill vAlign={"center"} hAlign={"center"}>
        <Text size={"large"} styles={{color: "darkgray"}} content={translations.get("NoResults")}/>
    </Flex>
)

const renderFiles = (
    files: Immutable<Array<FileData>>,
    selectedFileId: string | undefined,
    onClick: (file: Immutable<FileData>) => void
) => files.length === 0 ? renderEmptyFiles() : (
    <Flex fill column gap={"gap.small"} className={"overflow-scroll"}>
        {files.map(f => (
            <DocumentButton
                key={f.id.toString()}
                toggle={f.id.toString() === selectedFileId}
                onClick={onClick}
                file={f}
                fluid
                canShare={false}
                canDownload={false}
            />
        ))}
    </Flex>
)