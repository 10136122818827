import React, {ReactElement, useCallback, useEffect, useMemo} from "react";
import {IListItem, Logic, Props} from "./List.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./List.reducer";
import "./List.styles.scss";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {useListHeader} from "./hooks/useListHeader";
import {useListItems} from "./hooks/useListItems";
import {Flex, Text} from "@fluentui/react-northstar";
import {Table as FUITable} from "@fluentui/react-northstar/dist/es/components/Table/Table";
import {translations} from "translations";
import variables from "variables.module.scss";

export const List = <T, >(props: Props<T>): ReactElement | null => {
    const {isTouchScreen} = useMsTeamsSelector("isTouchScreen");
    const [state, dispatch] = useMagicReducer(reducer(props), initialState(props), props.externalRef);

    useEffect(dispatch("initialize"), []);
    useEffect(dispatch("stopEditOnOutsideClick"), []);

    const getColumnByField = useCallback((field: string) => {
        const column = props.columns.find(c => c.field === field);
        if (!column) throw new Error("Can't find column of field : " + field);
        return column;
    }, [props.columns]);

    const filteredItems = useMemo(() => {
        if (!props.items) state.list?.scrollTo(0, 0);
        let skip: number | undefined = (!props.items || props.items?.length === 0) ? 0 : props.items?.length + 1;
        const stopFetchOnScroll = state.prevSkip === skip;
        if (stopFetchOnScroll) skip = undefined;
        dispatch({type: "setSkip", payload: [skip]});
        let items = props.sortLocally ?
            sortItems(props.columns, props.items, state.columnSort, state.sortAscending)
            :
            [...(props.items ?? [])] as Array<IListItem<T>>;
        items = items?.filter(i => i);
        if (props.items) items.push(...props.items.filter(i => i === null) as Array<IListItem<T>>);
        const showNextItemsSkeletons = props.fetchOnScroll && props.fetchNextItems && state.skip;
        if (!props.items || showNextItemsSkeletons) {
            const visibleItemsCountDivider = !props.items ? 1 : 3;
            let skeletonsCount = Math.floor(state.visibleItemsCount / visibleItemsCountDivider);
            if (skeletonsCount <= 0) skeletonsCount = 1;
            items.push(...Array.from({length: skeletonsCount}).map(_ => null));
        }
        return items;
    }, [props.columns, props.items, state.columnSort, state.sortAscending, state.visibleItemsCount]);

    useEffect(function onVisibleRangeChange() {
        if (!state.mounted) return;
        dispatch("checkIfNeedToFetchNextItems")({items: props.items as Array<IListItem<T>>, filteredItems});
    }, [filteredItems, state.visibleRange]);

    const showActionColumn = !!props.actions && props.actions.items.length > 0;

    const actionColumnWidth = showActionColumn ? 50 : 0;

    const hideFilters = !props.onFilterByColumn;

    const logic: Logic<T> = {
        show: props.show ?? true,
        showActionColumn,
        items: filteredItems,
        columnSort: state.columnSort,
        sortAscending: state.sortAscending,
        columns: props.columns,
        visibleRange: state.visibleRange,
        actions: props.actions,
        getColumnByField,
        actionColumnWidth,
        editedItem: state.editedItem,
        columnFilters: state.columnFilters,
        isTouchScreen,
        hideFilters,
        dispatch,
    }

    const header = useListHeader(logic);
    const items = useListItems(logic);

    if (!logic.show) return null;

    const className = [
        "list",
        props.hideHeader && "list-no-header",
    ].filter(Boolean).join(" ");

    return (
        <Flex fill column gap={"gap.large"} className={"list-container"} ref={dispatch("listContainerRef")}>
            <FUITable
                ref={dispatch("listRef")}
                className={className}
                styles={{height: items.length <= 0 ? "auto" : "100%"}}
                header={props.hideHeader ? undefined : {
                    className: "pos-sticky",
                    items: header,
                }}
                rows={items}
                onScroll={dispatch("handleScrollList")}
            />
            {items.length <= 0 && renderNoItemsMessage(props.noItemsMessage)}
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const sortItems = <T, >(
    columns: Props<T>["columns"],
    items: Props<T>["items"] | undefined,
    columnSort: string | null,
    sortAscending: boolean
): Array<IListItem<T>> => {
    if (!items) return [];
    const column = columns.find(c => c.field === columnSort);
    if (!column?.sort) return [...items] as Array<IListItem<T>>;
    let itemsCopy = [...items] as Array<IListItem<T>>;
    itemsCopy.sort(column.sort);
    if (!sortAscending) itemsCopy = itemsCopy.reverse();
    return [...itemsCopy] as Array<IListItem<T>>;
}

const renderNoItemsMessage = (message?: string) => (
    <Flex fill column vAlign={"center"} hAlign={"center"} styles={{minHeight: "200px"}}>
        <Text
            size={"large"} align={"center"}
            content={message || translations.get("NoResult")}
            styles={{color: variables.brandColor200}}
        />
    </Flex>
)