import {notifyManager, QueryClient, useQueries, useQuery, useQueryClient} from "@tanstack/react-query";
import {ObjectModule} from "modules/Object.module";
import {Immutable} from "@witivio_teamspro/use-reducer";
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {QueryModule} from "../../../modules/Query.module";
import {Guid, GuidModule} from "common";
import hash from "object-hash";

type CRUDCacheItem = {
    id: Immutable<Guid> | undefined,
}

export type UseCRUDCacheBaseProps<T extends CRUDCacheItem> = {
    cacheKey: string,
    getById: ((id: Immutable<Guid> | undefined) => Promise<Immutable<T> | undefined>) | undefined,
    create: ((item: Immutable<T>) => Promise<Immutable<T> | undefined>) | undefined,
    update: ((id: Immutable<Guid>, fields: Partial<Immutable<T>>) => Promise<boolean>) | undefined,
    remove: ((id: Immutable<Guid>) => Promise<boolean>) | undefined,
    createCallback?: (queryClient: QueryClient, item: Immutable<T>) => void,
    updateCallback?: (queryClient: QueryClient, item: Immutable<T>) => void,
    removeCallback?: (queryClient: QueryClient, item: Immutable<T>) => void,
}

export type UseSingleCRUDCache<T extends CRUDCacheItem> = ReturnType<typeof useSingleCRUDCache<T>>;

export const useCRUDCache = <T extends CRUDCacheItem>(config: UseCRUDCacheBaseProps<T>) => {
    const queryClient = useQueryClient();

    return {
        upsertItem: upsertItem(queryClient, config),
        deleteItem: deleteItem(queryClient, config),
    };
}

export const useSingleCRUDCache = <T extends CRUDCacheItem>(config: UseCRUDCacheBaseProps<T> & {
    id: Immutable<Guid> | undefined,
}) => {
    const queryClient = useQueryClient();

    const {cacheKey, id} = config;

    const itemIdRef = useRef(id);

    useLayoutEffect(function onIdChange() {
        itemIdRef.current = id;
    }, [id]);

    const {data: item, isLoading} = useQuery({
        queryKey: [cacheKey, id],
        queryFn: () => fetchItem(queryClient, config, id),
        staleTime: Infinity,
        enabled: GuidModule.isValidGuid(id),
    });

    return {
        item,
        isLoading,
        upsertItem: upsertItem(queryClient, config, itemIdRef),
        deleteItem: deleteCurrentItem(queryClient, config, itemIdRef),
        itemIdRef,
    };
}

export const useMultipleCRUDCache = <T extends CRUDCacheItem>(config: UseCRUDCacheBaseProps<T> & {
    ids: Immutable<Array<Guid>> | undefined,
}) => {
    const queryClient = useQueryClient();

    const {ids, cacheKey} = config;

    const results = useQueries({
        queries: ids?.map(id => ({
            queryKey: [cacheKey, id],
            queryFn: () => fetchItem(queryClient, config, id),
            staleTime: Infinity,
            enabled: GuidModule.isValidGuid(id),
        })) ?? [],
    });

    const isLoading = !ids || results.some(r => r.isLoading || r.isFetching);
    const itemsData = isLoading ? undefined : results.map(r => r.data) as Array<Immutable<T>>;
    const itemsHash = hash.sha1(itemsData || null);

    const items = useMemo(() => itemsData?.filter(Boolean), [itemsHash]);

    return {
        items,
        isLoading,
        upsertItem: upsertItem(queryClient, config),
        deleteItem: deleteItem(queryClient, config),
    }
}

export const useAllCRUDCache = <T extends CRUDCacheItem>(config: UseCRUDCacheBaseProps<T> & {
    fetchAll: () => Promise<Immutable<Array<T>>>,
}) => {
    return useCustomCRUDCache({
        ...config,
        customQueryKey: [`${config.cacheKey}-all`],
        fetchInitialData: config.fetchAll,
        validateItem: (item) => !!item,
        enabled: true,
    });
}

export const useCustomCRUDCache = <T extends CRUDCacheItem>(config: UseCRUDCacheBaseProps<T> & {
    customQueryKey: Immutable<Array<any>>,
    fetchInitialData: () => Promise<Immutable<Array<T>>>,
    validateItem: (item: Immutable<T> | undefined) => boolean,
    enabled: boolean,
}) => {
    const {cacheKey, customQueryKey, fetchInitialData, enabled} = config;

    const queryClient = useQueryClient();

    const {data: initialItems, isLoading} = useQuery({
        queryKey: customQueryKey,
        queryFn: () => fetchAllItems(queryClient, config.cacheKey, fetchInitialData),
        staleTime: Infinity,
        enabled
    });

    const [itemIds, setItemIds] = useState<Array<Immutable<Guid>>>();

    useEffect(() => {
        if (isLoading && !!itemIds) return setItemIds(undefined);
    }, [isLoading]);

    useEffect(() => {
        if (!initialItems) return setItemIds(prev => prev?.length === 0 ? prev : !config.enabled ? [] : undefined);
        let ids = initialItems.map(item => item.id);
        const alreadyCachedItemsIds = queryClient.getQueryCache().findAll({queryKey: [cacheKey]})
            .filter(q => q.queryKey.length === 2 && q.queryKey[1])
            .filter(q => config.validateItem(q.state.data as Immutable<T> | undefined))
            .map(q => q.queryKey[1] as Guid);
        ids = Array.from(new Set([...alreadyCachedItemsIds, ...ids])).filter(Boolean);
        setItemIds(ids as Array<Immutable<Guid>>);
    }, [initialItems]);

    useEffect(function listenCachedItems() {
        return queryClient.getQueryCache().subscribe((event) => {
            if (!QueryModule.isQueryEvent(event)) return;
            if (event.type === "added") return;
            const queryKey = event.query.queryKey;
            if (queryKey[0] !== cacheKey || !GuidModule.isValidGuid(queryKey[1])) return;
            const data = event.query.state.data as Immutable<T> | undefined | null;
            const isDataRemoved = data === undefined || data === null;
            const isValid = isDataRemoved || config.validateItem(data);
            if (!isValid) return;
            const itemId = queryKey[1] as Guid;
            setItemIds(prevIds => {
                if (!prevIds) return;
                if (isDataRemoved) return !prevIds.includes(itemId) ? prevIds : prevIds.filter(id => id !== itemId);
                return prevIds?.includes(itemId) ? prevIds : [...(prevIds ?? []), itemId];
            });
        });
    }, [cacheKey]);

    return useMultipleCRUDCache({...config, ids: itemIds});
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const fetchAllItems = async <T extends CRUDCacheItem>(
    queryClient: QueryClient,
    cacheKey: string,
    fetchAll: () => Promise<Immutable<Array<T>>>,
) => {
    const items = await fetchAll();
    notifyManager.batch(() => {
        items.forEach(i => {
            queryClient.setQueryData([cacheKey, i.id], i);
        });
    });
    return items;
}

const fetchItem = async <T extends CRUDCacheItem>(
    queryClient: QueryClient,
    config: UseCRUDCacheBaseProps<T>,
    id: Immutable<Guid> | undefined
) => {
    const {cacheKey, getById} = config;
    const localItem = getLocalItem<T>(queryClient, cacheKey, id);
    if (localItem) return localItem;
    if (!getById) {
        console.error("GetById method is required to fetch an item");
        return;
    }
    if (!id) return;
    return getById?.(id);
}

const getLocalItem = <T extends CRUDCacheItem>(queryClient: QueryClient, cacheKey: string, id: Immutable<Guid> | undefined) => {
    return queryClient.getQueryData<T>([cacheKey, id]);
}

const upsertItem = <T extends CRUDCacheItem>(
    queryClient: QueryClient,
    config: UseCRUDCacheBaseProps<T>,
    itemIdRef?: React.MutableRefObject<Immutable<Guid> | undefined>
) => async (item: Immutable<T> | undefined) => {
    if (!item) return;
    const {cacheKey, create, update} = config;
    let itemClone = {...item} as Immutable<T>;
    const isNewItem = !itemClone.id;
    if (isNewItem) {
        if (!create) {
            console.error("Create method is required to upsert an item");
            return;
        }
        const result = await create(item);
        if (!result) return;
        itemClone = result;
        if (!itemClone.id) return;
        queryClient.setQueryData<Immutable<T> | undefined>([cacheKey, itemClone.id], itemClone);
        config.createCallback?.(queryClient, itemClone);
    } else {
        if (!update) {
            console.error("Update method is required to upsert an item");
            return;
        }
        const oldItem = queryClient.getQueryData<Immutable<T> | undefined>([cacheKey, itemClone.id]);
        if (!oldItem) return;
        const updatedFields = ObjectModule.findUpdatedFields(oldItem, itemClone);
        if (Object.entries(updatedFields).length === 0) return oldItem;
        queryClient.setQueryData<Immutable<T> | undefined>([cacheKey, itemClone.id], itemClone);
        const result = await update(itemClone.id!, updatedFields);
        if (!result) {
            queryClient.setQueryData<Immutable<T> | undefined>([cacheKey, itemClone.id], oldItem);
            return;
        }
        config.updateCallback?.(queryClient, itemClone);
    }
    if (itemIdRef) itemIdRef.current = itemClone.id;
    return itemClone;
}

const deleteItem = <T extends CRUDCacheItem>(queryClient: QueryClient, config: UseCRUDCacheBaseProps<T>) => async (id: Immutable<Guid> | undefined) => {
    if (!id) return;
    const {cacheKey, remove} = config;
    if (!remove) {
        console.error("Remove method is required to delete an item");
        return;
    }
    const item = queryClient.getQueryData<Immutable<T> | undefined>([cacheKey, id]);
    if (!item) {
        console.error("Item not found in cache");
        return;
    }
    const result = await remove(id);
    if (!result) return;
    queryClient.setQueryData([cacheKey, id], null);
    config.removeCallback?.(queryClient, item);
}

const deleteCurrentItem = <T extends CRUDCacheItem>(queryClient: QueryClient, config: UseCRUDCacheBaseProps<T>, itemIdRef: React.MutableRefObject<Immutable<Guid> | undefined>) => async () => {
    await deleteItem(queryClient, config)(itemIdRef.current);
}

export const CRUDCacheModule = {
    getLocalItem,
    upsertItem,
    deleteItem,
}