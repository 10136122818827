import {MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./ShareItemDialog.reducer";
import {FileData} from "common";

export type State = {
    step: ShareDialogStep,
    resources: Array<FileData>,
    isPublishing: boolean,
}

export type Props = {
    externalRef: MagicReducerExternalRef<typeof reducer>,
}

export enum ShareDialogStep {
    Process, Overview, Illustrations, Content, Publish
}

export type ShareDialogStepData = {
    step: number,
    title: string,
}