const humanize = (text: string) => {
    let sanitizedRoute = text.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
    sanitizedRoute = sanitizedRoute.replace(/[-_]/g, ' ').toLowerCase();
    let singular: string;
    let plural: string;
    if (sanitizedRoute.endsWith('ies')) {
        singular = sanitizedRoute.replace(/ies$/, 'y');
        plural = sanitizedRoute;
    } else if (sanitizedRoute.endsWith('s')) {
        singular = sanitizedRoute.slice(0, -1);
        plural = sanitizedRoute;
    } else {
        singular = sanitizedRoute;
        plural = sanitizedRoute + 's';
    }
    return {singular, plural};
}

export const TextModule = {
    humanize,
}