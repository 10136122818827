import {Props, State} from "./ItemCard.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React, {startTransition} from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import {useItemLikeCache} from "../../../hooks/cache/likesCache";
import {ReportingModule} from "../../../modules/Reporting.module";
import {Guid} from "common";
import {SharepointModule} from "../../../modules/Sharepoint.module";
import {DialogContextValue} from "../../../services/DialogContext/DialogContext.types";

export const initialState: State = {
    imageLoaded: false,
}

export const reducer = (
    props: Props,
    toggleLike: ReturnType<typeof useItemLikeCache>["toggleLike"],
    userId: Guid,
    markItemVisited: () => void,
    fullLocale: string,
    iDidItDialog: DialogContextValue["iDidItDialog"],
) => ({
    handleClick: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        const link = props.item?.link;
        if (!link) return;
        SharepointModule.openPageInStageView(link, fullLocale).then();
        ReportingModule.notifyItemOpened(userId, props.item?.id, props.item?.type).then();
        markItemVisited();
    },
    handleToggleLike: async (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        if (!props.item?.id) return;
        await toggleLike();
    },
    handleShare: async (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        await microsoftTeams.sharing.shareWebContent({
            content: [{
                url: props.item?.link ?? "",
                type: "URL",
                preview: true,
            }]
        }).catch(() => 0);
        ReportingModule.notifyItemShared(userId, props.item?.id, props.item?.type).then();
    },
    handleAddComment: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        if (!props.item) return;
        props.itemDialogRef?.dispatch("handleOpen")(props.item);
    },
    handleImageLoaded: (reducerData) => {
        startTransition(() => reducerData.setState({imageLoaded: true}));
    },
    handleIDidIt: async (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        if (!props.item?.id) return;
        iDidItDialog.dispatch("open", props.item?.id)();
    }
}) satisfies MagicReducerObject<State>;

export const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();