import React, {ReactElement, useEffect, useMemo} from "react";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Dashboard.reducer";
import "./Dashboard.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import {ConsultedOrLikedContentCategory, RecommendedContentCategory} from "./Dashboard.interfaces";
import {useBestPracticesCache} from "../../hooks/cache/bestPracticesCache";
import {useTrainingsCache} from "../../hooks/cache/trainingsCache";
import {ItemDialog} from "../../components/dialogs/ItemDialog/ItemDialog";
import {useUserDataCache} from "../../hooks/cache/userDataCache";
import {useMyManagerRecommendationsCache, useMyRecommendationsCache} from "../../hooks/cache/recommendationsCache";
import {ItemsCarousel} from "../../components/others/ItemsCarousel/ItemsCarousel";
import {UserProfile} from "../../components/others/UserProfile/UserProfile";
import {ItemData, ManagementUserRole, UserData} from "common/dist/src";
import {MenuItem} from "../../components/others/AutoWidthMenu/AutoWidthMenu.interfaces";
import {ScreenModule} from "../../modules/Screen.module";

export const Dashboard = (): ReactElement => {
    const {bestPractices} = useBestPracticesCache();
    const {trainings} = useTrainingsCache();
    const itemDialogRef = useMagicReducerRef(ItemDialog);
    const {userData, hasUserVisitedItem} = useUserDataCache();
    const {managementRole} = useMyRecommendationsCache();
    const {managerRecommendations} = useMyManagerRecommendationsCache();

    const [, dispatch, render] = useMagicReducer(reducer(), initialState());

    useEffect(() => {
        window.addEventListener("resize", dispatch("windowResize"));
        return () => window.removeEventListener("resize", dispatch("windowResize"));
    }, []);

    const consultedOrLikedContentCarouselRef = useMagicReducerRef(ItemsCarousel);
    const recommendedContentCarouselRef = useMagicReducerRef(ItemsCarousel);

    const consultedOrLikedContentCategories = useMemo(() => generateConsultedOrLikedContentCategories(), []);
    const recommendedContentCategories = useMemo(() => generateRecommendedContentCategories(), []);

    const showSkeletons = !bestPractices || !trainings;

    const filteredConsultedOrLikedItems = useMemo(() => {
        if (showSkeletons) return undefined;
        return getFilteredConsultedOrLikedItems({
            bestPractices,
            trainings,
            selectedCategoryIndex: consultedOrLikedContentCarouselRef.state?.menuIndex ?? 0,
            categories: consultedOrLikedContentCategories,
            userData,
            hasUserVisitedItem,
        });
    }, [consultedOrLikedContentCarouselRef.state?.menuIndex, bestPractices, trainings, userData, showSkeletons]);

    const filteredRecommendedItems = useMemo(() => {
        if (showSkeletons || !managerRecommendations) return undefined;
        return getFilteredRecommendedItems({
            bestPractices,
            trainings,
            selectedCategoryIndex: recommendedContentCarouselRef.state?.menuIndex ?? 0,
            categories: recommendedContentCategories,
            recommendations: managerRecommendations,
        });
    }, [recommendedContentCarouselRef.state?.menuIndex, bestPractices, managerRecommendations, showSkeletons]);

    const isSmallScreen = ScreenModule.isSmallScreen();

    return (
        <Flex fill className={"dashboard-view no-select"} column={isSmallScreen}>
            <Flex className={"dashboard-view-profile-container"}>
                <UserProfile/>
            </Flex>
            <Flex fill column={isSmallScreen} className={"dashboard-view-right-content"}>
                <Flex fill column className={"overflow-visible"}>
                    <ItemsCarousel
                        externalRef={consultedOrLikedContentCarouselRef}
                        items={filteredConsultedOrLikedItems}
                        title={translations.get("ConsultedOrLikedContent")}
                        subtitle={translations.get("ConsultedOrLikedContentSubtitle")}
                        defaultMenuIndex={0}
                        menuItems={consultedOrLikedContentCategories}
                        onChangeMenuIndex={render}
                        cacheKey={"dashboardConsultedOrLikedContentCarousel"}
                        itemDialogRef={itemDialogRef}
                    />
                    {managementRole !== ManagementUserRole.Manager &&
                        <ItemsCarousel
                            externalRef={recommendedContentCarouselRef}
                            items={filteredRecommendedItems}
                            title={translations.get("RecommendedContent")}
                            subtitle={translations.get("RecommendedContentSubtitle")}
                            defaultMenuIndex={0}
                            menuItems={recommendedContentCategories}
                            onChangeMenuIndex={render}
                            cacheKey={"dashboardRecommendedContentCarousel"}
                            itemDialogRef={itemDialogRef}
                            hideRecommendationButton
                        />
                    }
                </Flex>
            </Flex>
            <ItemDialog externalRef={itemDialogRef}/>
        </Flex>
    );
}

const generateConsultedOrLikedContentCategories = (): Immutable<Array<MenuItem>> => {
    return [
        {key: ConsultedOrLikedContentCategory.All, content: translations.get("All")},
        {key: ConsultedOrLikedContentCategory.LikedBestPractices, content: translations.get("LikedBestPractices")},
        {key: ConsultedOrLikedContentCategory.LikedTrainings, content: translations.get("LikedTrainings")},
        {key: ConsultedOrLikedContentCategory.ViewedBestPractices, content: translations.get("ViewedBestPractices")},
        {key: ConsultedOrLikedContentCategory.ViewedTrainings, content: translations.get("ViewedTrainings")},
    ]
}

const generateRecommendedContentCategories = (): Immutable<Array<MenuItem>> => {
    return [
        {key: RecommendedContentCategory.All, content: translations.get("All")},
        {key: RecommendedContentCategory.BestPractices, content: translations.get("BestPractices")},
        {key: RecommendedContentCategory.Trainings, content: translations.get("Trainings")},
    ]
}

const getFilteredConsultedOrLikedItems = (config: {
    bestPractices: Array<Immutable<ItemData>> | undefined,
    trainings: Array<Immutable<ItemData>> | undefined,
    categories: Immutable<Array<MenuItem>>,
    selectedCategoryIndex: number,
    userData: Immutable<UserData> | undefined,
    hasUserVisitedItem: ReturnType<typeof useUserDataCache>["hasUserVisitedItem"],
}): Array<Immutable<ItemData>> => {
    let items: Array<Immutable<ItemData>> = [];
    const likedBestPractices = config.bestPractices?.filter(item => item.likes.includes(config.userData?.upn ?? ""));
    const likedTrainings = config.trainings?.filter(item => item.likes.includes(config.userData?.upn ?? ""));
    const viewedBestPractices = config.bestPractices?.filter(item => config.hasUserVisitedItem(item.type, item.id));
    const viewedTrainings = config.trainings?.filter(item => config.hasUserVisitedItem(item.type, item.id));
    const selectedCategory = config.categories[config.selectedCategoryIndex];
    if (!selectedCategory) return items;
    switch (selectedCategory.key) {
        case ConsultedOrLikedContentCategory.All:
            items.push(...likedBestPractices ?? []);
            items.push(...likedTrainings ?? []);
            items.push(...viewedBestPractices ?? []);
            items.push(...viewedTrainings ?? []);
            break;
        case ConsultedOrLikedContentCategory.LikedBestPractices:
            items.push(...likedBestPractices ?? []);
            break;
        case ConsultedOrLikedContentCategory.LikedTrainings:
            items.push(...likedTrainings ?? []);
            break;
        case ConsultedOrLikedContentCategory.ViewedBestPractices:
            items.push(...viewedBestPractices ?? []);
            break;
        case ConsultedOrLikedContentCategory.ViewedTrainings:
            items.push(...viewedTrainings ?? []);
            break;
    }
    const uniqueIds = new Set<string>();
    items = items.filter(item => {
        if (!uniqueIds.has(item.type + item.id)) {
            uniqueIds.add(item.type + item.id);
            return true;
        }
        return false;
    });
    return items;
}

const getFilteredRecommendedItems = (config: {
    bestPractices: Array<Immutable<ItemData>> | undefined,
    trainings: Array<Immutable<ItemData>> | undefined,
    categories: Immutable<Array<MenuItem>>,
    selectedCategoryIndex: number,
    recommendations: ReturnType<typeof useMyRecommendationsCache>["recommendations"],
}): Array<Immutable<ItemData>> => {
    const items: Array<Immutable<ItemData>> = [];
    const selectedCategory = config.categories[config.selectedCategoryIndex];
    if (!selectedCategory) return items;
    const recommendedBestPractices = config.recommendations?.bestPractices ?? [];
    const recommendedTrainings = config.recommendations?.trainings ?? [];
    const bestPractices = config.bestPractices?.filter(i => recommendedBestPractices.includes(i.id)) ?? [];
    const trainings = config.trainings?.filter(i => recommendedTrainings.includes(i.id)) ?? [];
    switch (selectedCategory.key) {
        case RecommendedContentCategory.All:
            items.push(...bestPractices);
            items.push(...trainings);
            break;
        case RecommendedContentCategory.BestPractices:
            items.push(...bestPractices);
            break;
        case RecommendedContentCategory.Trainings:
            items.push(...trainings);
            break;
    }
    return items;
}