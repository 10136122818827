import {DateRange, DateRangeType, Props, State} from "./DateRangeSelector.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {DatepickerCalendarProps, PopperRefHandle} from "@fluentui/react-northstar";
import moment from "moment/moment";
import {IDay} from "@fluentui/react-northstar/dist/es/utils/date-time-utilities";

export const initialState = (props: Props): State => ({
    selectionMode: DateRangeType.Week,
    isCustomEndDateSelected: false,
    selectedDate: moment().startOf("day").toISOString(),
    selectedRange: props.initialDateRange ?? {
        startDate: moment().startOf("week").toISOString(false),
        endDate: moment().endOf("week").toISOString(false),
    },
    isTodaySelected: true,
});

export const reducer = (config: {
    props: Props,
    datePickerPopperRef: React.MutableRefObject<PopperRefHandle | null>,
    popupContentRef: React.MutableRefObject<HTMLDivElement | null>,
}) => ({
    handleClickTrigger: () => {
        setTimeout(() => {
            if (config.popupContentRef.current) config.popupContentRef.current!.className += " date-range-selector-picker";
            config.datePickerPopperRef.current?.updatePosition();
        }, 0);
    },
    handleSwitchMode: (reducerData, _, mode: State["selectionMode"]) => {
        const {state, setState} = reducerData;
        setTimeout(() => config.datePickerPopperRef.current?.updatePosition(), 0);
        setState({selectionMode: mode});
        reducer(config).changeDate(reducerData, null, state.selectedDate);
    },
    handleChangeDate: (reducerData, [, datePickerData]: [React.SyntheticEvent, DatepickerCalendarProps & {
        value: IDay;
    } | undefined]) => {
        const value = datePickerData?.value.originalDate.toISOString();
        if (!value) return;
        reducer(config).changeDate(reducerData, null, value);
    },
    handleToggleSelectedCustomDate: ({setState}, _, isEndDate: boolean) => {
        setState({isCustomEndDateSelected: isEndDate});
    },
    handleChangeMonth: (reducerData, _, action: "next" | "previous") => {
        const {state} = reducerData;
        if (state.selectionMode !== DateRangeType.Month) return;
        const date = moment(state.selectedDate).add(action === "next" ? 1 : -1, "month").toISOString();
        reducer(config).changeDate(reducerData, null, date);
    },
    changeDate: ({state, setState}, _, date: string) => {
        setState({selectedDate: date}, false);
        const selectedRange = getRangeFromDate({
            date,
            selectionMode: state.selectionMode,
            isCustomEndDateSelected: state.isCustomEndDateSelected,
            dateRange: state.selectedRange,
        });
        const rangeStart = moment(selectedRange.startDate).startOf("day");
        const rangeEnd = moment(selectedRange.endDate).endOf("day");
        const isTodaySelected = moment().isBetween(rangeStart, rangeEnd, "day", "[]");
        setState({selectedRange, isTodaySelected});
        config.props.onDateRangeChange?.(date, selectedRange);
    },
    prevRange: (reducerData) => {
        const {state} = reducerData;
        const date = getDateRange(state.selectedDate, state.selectionMode, false);
        reducer(config).changeDate(reducerData, null, date);
    },
    nextRange: (reducerData) => {
        const {state} = reducerData;
        const date = getDateRange(state.selectedDate, state.selectionMode, true);
        reducer(config).changeDate(reducerData, null, date);
    },
    goToToday: (reducerData) => {
        const date = moment().startOf("day").toISOString();
        reducer(config).changeDate(reducerData, null, date);
    },
}) satisfies MagicReducerObject<State>;

const getDateRange = (date: string, selectionMode: State["selectionMode"], isNext: boolean): string => {
    let unit: moment.unitOfTime.DurationConstructor | undefined = undefined;
    switch (selectionMode) {
        case DateRangeType.Day:
            unit = "day";
            break;
        case DateRangeType.Week:
            unit = "week";
            break;
        case DateRangeType.Month:
            unit = "month";
            break;
        default:
            break;
    }
    if (!unit) return date;
    return moment(date).add(isNext ? 1 : -1, unit).toISOString();
}

const getRangeFromDate = (data: {
    date: string,
    selectionMode: State["selectionMode"],
    isCustomEndDateSelected: State["isCustomEndDateSelected"],
    dateRange: State["selectedRange"],
}): DateRange => {
    const {date, selectionMode, isCustomEndDateSelected, dateRange} = data;
    let startDate = "";
    let endDate = "";
    switch (selectionMode) {
        case DateRangeType.Day:
            startDate = date;
            endDate = moment(date).endOf("day").toISOString();
            break;
        case DateRangeType.Week:
            startDate = moment(date).startOf("week").toISOString();
            endDate = moment(date).endOf("week").toISOString();
            break;
        case DateRangeType.Month:
            startDate = moment(date).startOf("month").toISOString();
            endDate = moment(date).endOf("month").toISOString();
            break;
        case DateRangeType.Custom:
            startDate = moment(dateRange?.startDate).startOf("day").toISOString(false);
            endDate = moment(dateRange?.endDate).endOf("day").toISOString(false);
            if (isCustomEndDateSelected) {
                endDate = moment(date).endOf("day").toISOString(false);
                if (endDate <= startDate) startDate = moment(endDate).add(-1, "day").toISOString(false);
            } else {
                startDate = date;
                if (startDate >= endDate) endDate = moment(startDate).add(1, "day").toISOString(false);
            }
            break;
        default:
            break;
    }
    return {startDate, endDate};
}

const getShortDaysNames = (locale: string) => {
    const prevLocale = moment.locale();
    moment.locale(locale);
    const shortDaysNames = moment.weekdays(false).map(day => day.slice(0, 3));
    moment.locale(prevLocale);
    return shortDaysNames;
}

const getMonthsNames = (locale: string) => {
    return Array.from(Array(12).keys()).map(key => moment().locale(locale).month(key).format('MMMM'))
}

export const DateRangeSelectorHelpers = {
    getRangeFromDate,
    getShortDaysNames,
    getMonthsNames,
}