"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchDriveItemsType = void 0;
var SearchDriveItemsType;
(function (SearchDriveItemsType) {
    SearchDriveItemsType["All"] = "all";
    SearchDriveItemsType["Word"] = "word";
    SearchDriveItemsType["Excel"] = "excel";
    SearchDriveItemsType["Powerpoint"] = "powerpoint";
    SearchDriveItemsType["Onenote"] = "onenote";
    SearchDriveItemsType["Form"] = "form";
    SearchDriveItemsType["Pdf"] = "pdf";
    SearchDriveItemsType["Image"] = "image";
    SearchDriveItemsType["Video"] = "video";
    SearchDriveItemsType["Archive"] = "archive";
    SearchDriveItemsType["Site"] = "site";
    SearchDriveItemsType["Other"] = "other";
})(SearchDriveItemsType || (exports.SearchDriveItemsType = SearchDriveItemsType = {}));
