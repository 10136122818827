import {AxiosConfig} from "../apis/AxiosConfig/AxiosConfig";
import {ErrorModule} from "../components/others/ErrorBoundary/ErrorBoundary";
import {AccountInfo, AuthenticationResult, BrowserAuthOptions, PublicClientApplication} from "@azure/msal-browser";
import {QueryClient, useQuery, useQueryClient} from "@tanstack/react-query";
import {AuthData} from "../types/AuthData";
import {Guid, TokenResult} from "common";
import * as microsoftTeams from "@microsoft/teams-js";
import {getAppConfiguration} from "../services/ConfigurationService/ConfigurationService";

export const authCacheKey = "auth";

const initialState: AuthData = {
    inIframe: window.self !== window.top,
    accessToken: undefined,
    msalInstance: undefined,
    accessTokenInterval: undefined,
    clientId: undefined,
}

export const useAuth = () => {
    const queryClient = useQueryClient()

    const {data} = useQuery({
        queryKey: [authCacheKey],
        queryFn: () => ({...initialState}),
        staleTime: Infinity,
    });

    return {
        data: data ?? initialState,
        initializeAuth: initializeAuth(queryClient),
        login: login(queryClient),
        signOut: signOut(queryClient),
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const msalRedirectionKey = "msal_redirecting";

const getLocalData = (queryClient: QueryClient) => {
    return queryClient.getQueryData([authCacheKey]) as AuthData | undefined;
}

const setLocalData = (queryClient: QueryClient, data: Partial<AuthData>) => {
    const localData = getLocalData(queryClient);
    const newData = {...localData, ...data};
    queryClient.setQueryData([authCacheKey], newData);
}

const initializeAuth = (queryClient: QueryClient) => async (data: { isTeamsApp: boolean, clientId: string }) => {
    const state = getLocalData(queryClient);
    if (!state) throw new Error("Can't get auth data");
    const msalInstance = initMsalInstance(data.clientId, state.inIframe);
    const refreshAccessToken = async (needDispatch = true) => {
        const accessToken = await getAccessToken(data.clientId, data.isTeamsApp, msalInstance, state.inIframe);
        AxiosConfig.setAxiosRequestMiddleware(accessToken?.token ?? "", refreshAccessToken);
        if (needDispatch) setLocalData(queryClient, {accessToken});
        return accessToken;
    }
    const accessToken = await refreshAccessToken(false);
    // Refresh access token every 30 minutes
    const accessTokenInterval = setInterval(refreshAccessToken, 1800000);
    setLocalData(queryClient, {
        msalInstance,
        clientId: data.clientId,
        accessToken,
        accessTokenInterval
    });
}

const login = (queryClient: QueryClient) => async (graphScopes: Array<string>, consent: () => void) => {
    try {
        const state = getLocalData(queryClient);
        if (!state) throw new Error("Can't get auth data");
        if (!state.clientId) return ErrorModule.showErrorAlert("Can't consent scopes, client id is not defined");
        if (state.msalInstance === undefined) initMsalInstance(state.clientId, state.inIframe);
        await state.msalInstance?.loginRedirect({scopes: graphScopes, prompt: "consent"});
    } catch (e: any) {
        // Refresh window in case of invalid response
        // This issue happens only on Android devices, can't parse result
        // After reload it should work fine thanks to SSO
        if (e.code === "InvalidResponse") return window.location.reload();
        else if (e.code === "ConsentFailed")
            ErrorModule.showErrorAlert("User cancelled consent", e);
        else ErrorModule.showErrorAlert("Consent failed", e);
        return;
    }
    consent();
}

const signOut = (queryClient: QueryClient) => async () => {
    const state = getLocalData(queryClient);
    if (!state) throw new Error("Can't get auth data");
    if (!state.msalInstance) return ErrorModule.showErrorAlert("MSAL instance is not initialized");
    const logoutRequest = {
        account: state.msalInstance.getAllAccounts()[0] as AccountInfo,
    };
    if (!logoutRequest.account) return;
    await state.msalInstance.logoutRedirect(logoutRequest);
}

export const initMsalInstance = (clientId: string, inIframe: boolean): PublicClientApplication => {
    const redirectUri = window.location.origin + (inIframe ? "/blank-auth-end.html" : "");
    const authData: BrowserAuthOptions = {
        clientId: clientId,
        authority: "https://login.microsoftonline.com/organizations",
        navigateToLoginRequestUrl: true,
        redirectUri: redirectUri,
        postLogoutRedirectUri: redirectUri,
    };
    const msalInstance = new PublicClientApplication({
        auth: authData, cache: {cacheLocation: "sessionStorage", storeAuthStateInCookie: false}
    });
    if (inIframe) return msalInstance;
    msalInstance.handleRedirectPromise().then((tokenResponse) => {
        if (!tokenResponse) return;
        sessionStorage.setItem(msalRedirectionKey, JSON.stringify(tokenResponse));
    }).catch((error) => {
        console.error(error)
    });
    return msalInstance;
}

const getRedirectionResult = (): null | { redirecting: boolean } | AuthenticationResult => {
    const stringItem = sessionStorage.getItem(msalRedirectionKey) ?? "";
    if (stringItem.length === 0) return null;
    const parsedItem = JSON.parse(stringItem);
    if (parsedItem === null) return {redirecting: true};
    return parsedItem;
}

export const getAccessToken = (clientId: string, isTeamsApp: boolean, msalInstance: PublicClientApplication, inIframe: boolean): Promise<TokenResult | undefined> => {
    const accessScope = `api://${getAppConfiguration()?.appBaseUrl}/${clientId}/access_as_user`;
    if (!isTeamsApp) return getTokenWithScopesFromWeb([accessScope], msalInstance, inIframe);
    else return getAccessTokenFromTeams();
}

export const msalLogin = async (scopes: Array<string>, prompt: "select_account" | "consent", msalInstance: PublicClientApplication, inIframe: boolean): Promise<TokenResult | undefined> => {
    if (inIframe) {
        const authResult = await msalInstance.loginPopup({scopes, prompt});
        return extractTokenResult(authResult);
    } else {
        await msalInstance.loginRedirect({scopes, prompt});
        return undefined;
    }
}

export const getTokenWithScopesFromWeb = (scopes: Array<string>, msalInstance: PublicClientApplication, inIframe: boolean): Promise<TokenResult> => {
    const handleTokenRequest = async (): Promise<TokenResult> => {
        try {
            let redirectionResult = getRedirectionResult();
            if (redirectionResult && "redirecting" in redirectionResult && redirectionResult?.redirecting) {
                return new Promise<TokenResult>((resolve) => {
                    const resetTimeout = setTimeout(() => {
                        sessionStorage.removeItem(msalRedirectionKey);
                        window.location.reload();
                    }, 3000);
                    const interval = setInterval(() => {
                        redirectionResult = getRedirectionResult();
                        if (!("authority" in (redirectionResult ?? {}))) return;
                        clearTimeout(resetTimeout);
                        clearInterval(interval);
                        sessionStorage.removeItem(msalRedirectionKey);
                        resolve(extractTokenResult(redirectionResult as AuthenticationResult));
                    }, 500);
                });
            } else {
                const userAccount = msalInstance.getAllAccounts()[0];
                if (!userAccount) throw new Error("User not logged in");
                const loginResponse = await msalInstance.ssoSilent({
                    scopes,
                    loginHint: userAccount.username,
                    extraQueryParameters: {domain_hint: "organizations"},
                });
                sessionStorage.removeItem(msalRedirectionKey);
                return extractTokenResult(loginResponse);
            }
        } catch (err) {
            sessionStorage.setItem(msalRedirectionKey, JSON.stringify(null));
            try {
                const result = await msalLogin(scopes, "select_account", msalInstance, inIframe);
                if (result) return result;
            } catch (err) {
                const result = await msalLogin(scopes, "consent", msalInstance, inIframe);
                if (result) return result;
            }
            throw new Error("Unable to get token");
        }
    };
    return handleTokenRequest();
};

export const extractTokenResult = (result: AuthenticationResult): TokenResult => {
    return {
        authResult: true,
        token: result.accessToken,
        tenantId: result.tenantId,
        userId: result.uniqueId as Guid,
        userMail: result.account?.username.toLowerCase() ?? "",
        userName: result.account?.name ?? "",
    }
}

export const getAccessTokenFromTeams = async (): Promise<TokenResult | undefined> => {
    try {
        const token = await microsoftTeams.authentication.getAuthToken({silent: true});
        return {authResult: true, token};
    } catch (e) {
        microsoftTeams.app.notifySuccess();
        const token = await microsoftTeams.authentication.getAuthToken({silent: false});
        if (!token) {
            ErrorModule.showErrorAlert("User cancelled consent");
            return;
        }
        return {authResult: true, token};
    }
}