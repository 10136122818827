import {DraftMessage, Props, State} from "./NewsDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import React from "react";
import {ObjectModule} from "../../../modules/Object.module";
import {MentionModule} from "../../../modules/Mention.module";
import {Guid, GuidModule, News, NewsButton, SearchDriveItemsType} from "common";
import {translations} from "../../../translations";
import {SessionCacheService} from "../../../services/CacheService/CacheService.hook";
import {Constants} from "../../../const/Constants";
import {useEditMessageForm} from "../../../hooks/forms/useEditMessageForm";
import {Dialog} from "../Dialog/Dialog";
import {GraphApi} from "../../../apis/Graph/GraphApi";
import {useAllNewsCache} from "../../../hooks/cache/useNewsCache";
import {MenuItemProps} from "@fluentui/react-northstar";
import {CommunityModule} from "../../../modules/Community.module";
import {ConfirmCancelChangesDialog} from "../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";
import {FilePickerDialog} from "../FilePickerDialog/FilePickerDialog";

export const draftMessageCacheKey = "messageOfTheDayDraft";

export const initialState: State = {
    isSaving: false,
    buttons: [],
    selectedCommunity: CommunityModule.generalNewsId,
    initialCommunity: CommunityModule.generalNewsId,
    draftMessage: null,
    selectedVideo: undefined,
}

type MessageForm = ReturnType<typeof useEditMessageForm>["messageForm"];
type ButtonsForm = ReturnType<typeof useEditMessageForm>["buttonsForm"];

export const reducer = (config: {
    props: Props,
    news: Array<Immutable<News>> | undefined,
    dialogRef: MagicReducerRef<typeof Dialog>,
    upsertNews: ReturnType<typeof useAllNewsCache>["upsertItem"],
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
    filePickerDialog: MagicReducerRef<typeof FilePickerDialog>,
    isAdmin: boolean | undefined,
}) => ({
    handleOpen: (reducerData, _, initialCommunity: string = CommunityModule.generalNewsId) => {
        const {dialogRef, news} = config;
        const {setState} = reducerData;
        let selectedCommunity: string | undefined = initialCommunity;
        if (!config.isAdmin && selectedCommunity === CommunityModule.generalNewsId) {
            selectedCommunity = news?.find(n => n.community !== CommunityModule.generalNewsId)?.community;
        }
        const activeNews = news?.find(n => n.community === selectedCommunity);
        const buttons = ObjectModule.deepClone(activeNews?.buttons ?? []);
        const draftMessage = SessionCacheService.getItem<DraftMessage>(draftMessageCacheKey);
        setState({
            isSaving: false,
            buttons,
            draftMessage,
            selectedCommunity: selectedCommunity,
            selectedVideo: activeNews?.selectedVideo,
        });
        dialogRef.dispatch("open")();
    },
    handleClose: () => {
        const {dialogRef} = config;
        dialogRef.dispatch("close")();
    },
    handleSave: async (reducerData, _, {messageForm, buttonsForm}: {
        messageForm: MessageForm,
        buttonsForm: ButtonsForm
    }) => {
        const {dialogRef} = config;
        const {state} = reducerData;
        const show = messageForm.state.data.visible as boolean;
        const message = messageForm.state.data.message as string;
        const activeNews = config.news?.find(n => n.community === state.selectedCommunity);
        const news: News = {
            id: (activeNews?.id ?? "") as Guid,
            show,
            message,
            buttons: reducerData.state.buttons.map(b => ({
                ...b,
                title: buttonsForm.state.data?.[b.id + "_title"] as string,
                link: buttonsForm.state.data?.[b.id + "_link"] as string,
            })),
            community: state.selectedCommunity,
            selectedVideo: state.selectedVideo,
        }
        const {setState} = reducerData;
        setState({isSaving: true});
        await config.upsertNews(news);
        setState({isSaving: false});
        dialogRef.dispatch("close")();
        await notifyMentionedUsersInMessage(message);
        SessionCacheService.removeItem(draftMessageCacheKey);
    },
    handleAddButton: (reducerData) => {
        const {state, setState} = reducerData;
        const newButton: NewsButton = {
            id: GuidModule.generateGUID(),
            title: "",
            link: "",
            color: "#00427C"
        }
        const buttons = [...state.buttons, newButton];
        setState({buttons});
    },
    handleDeleteButton: (reducerData, _, id: string) => {
        const {state, setState} = reducerData;
        const buttons = state.buttons.filter(b => b.id !== id);
        setState({buttons});
    },
    handleSetButtonColor: (reducerData, [e]: [React.ChangeEvent<HTMLInputElement>], id: string) => {
        const {state, setState} = reducerData;
        const buttonIndex = state.buttons.findIndex(b => b.id === id);
        if (buttonIndex < 0) return;
        const newButton = {...state.buttons[buttonIndex], color: e.target.value} as Immutable<NewsButton>;
        const buttons = [...state.buttons];
        buttons.splice(buttonIndex, 1, newButton);
        setState({buttons}, false);
    },
    saveDraftMessage: ({state}, [message]: [string]) => {
        if (state.draftMessage) return;
        SessionCacheService.setItem<DraftMessage>(draftMessageCacheKey, {
            community: state.selectedCommunity,
            message,
        });
    },
    handleRetrieveDraft: ({setState}, _, messageForm: MessageForm) => {
        const draftMessage = SessionCacheService.getAndRemoveItem<DraftMessage>(draftMessageCacheKey);
        if (draftMessage) {
            messageForm.setFieldsInitialValues({message: draftMessage.message});
            messageForm.reset();
        }
        setState({draftMessage: null});
    },
    removeDraftMessage: () => {
        SessionCacheService.removeItem(draftMessageCacheKey);
    },
    switchCommunity: ({setState}, [, data]: [React.SyntheticEvent, MenuItemProps | undefined], canSave: boolean) => {
        const tabId = (data as any)?.id;
        if (tabId === undefined) return;
        const activeNews = config.news?.find(n => n.community === tabId);
        const buttons = ObjectModule.deepClone(activeNews?.buttons ?? []);
        const selectedVideo = activeNews?.selectedVideo;
        if (!canSave) setState({selectedCommunity: tabId, buttons, selectedVideo});
        else {
            config.confirmCancelChangesDialog.dispatch("open", (confirm) => {
                if (confirm) setState({selectedCommunity: tabId, buttons, selectedVideo});
            })();
        }
    },
    handleSelectVideo: ({setState}) => {
        config.filePickerDialog.dispatch("open", {
            title: translations.get("SelectAVideo"),
            searchPlaceholder: translations.get("SearchAVideo"),
            types: [SearchDriveItemsType.Video],
            onFileSelected: (file) => {
                setState({
                    selectedVideo: {
                        name: file.name,
                        itemId: file.fileId,
                        driveId: file.driveId,
                    }
                });
            }
        })();
    },
    handleClearVideo: ({setState}) => {
        setState({selectedVideo: undefined});
    },
}) satisfies MagicReducerObject<State>;

const notifyMentionedUsersInMessage = async (message: string) => {
    const mentions = MentionModule.extractMentions(message);
    if (mentions.length === 0) return;
    const userIds = mentions.map(m => m.mentionId);
    await GraphApi.sendNotification({
        appName: Constants.appName,
        userIdsOrUpns: userIds,
        title: translations.en.CheckOutMessageOfTheDay + "!",
        entityId: "starvoice",
    });
}