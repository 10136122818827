import {Immutable} from "@witivio_teamspro/use-reducer";
import {Guid} from "common";
import {AxiosResponse} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {TextModule} from "../../modules/Text.module";

const generate = <T>(routeName: string) => {
    const itemName = TextModule.humanize(routeName);
    return {
        create: async (item: Immutable<T>): Promise<Immutable<T> | undefined> => {
            try {
                const response: AxiosResponse = await AxiosConfig.instance.post(`/${routeName}`, item);
                return response.data;
            } catch (error) {
                ErrorModule.showErrorAlert(`Can't create ${itemName.singular}`, error);
                return undefined;
            }
        },
        update: async (id: Immutable<Guid>, fields: Immutable<Partial<T>>): Promise<boolean> => {
            try {
                if (Object.entries(fields).length === 0) return true;
                await AxiosConfig.instance.put(`/${routeName}/${id}`, fields);
                return true;
            } catch (error) {
                ErrorModule.showErrorAlert(`Can't update ${itemName.singular} with id ${id}`, error);
                return false;
            }
        },
        remove: async (id: Immutable<Guid>) => {
            try {
                if (!id) return false;
                await AxiosConfig.instance.delete(`/${routeName}/${id}`);
                return true;
            } catch (error) {
                ErrorModule.showErrorAlert(`Can't delete ${itemName.singular} with id ${id}`, error);
                return false;
            }
        },
        getById: async (id: Immutable<Guid> | undefined) => {
            if (!id) return;
            try {
                const response: AxiosResponse<Immutable<T>> = await AxiosConfig.instance.get(`/${routeName}/${id}`);
                return response.data;
            } catch (error) {
                ErrorModule.showErrorAlert(`Can't get ${itemName.singular} with id ${id}`, error);
                return undefined;
            }
        },
        getAll: async () => {
            try {
                const response: AxiosResponse<Immutable<Array<T>>> = await AxiosConfig.instance.get(`/${routeName}`);
                return response.data;
            } catch (error) {
                ErrorModule.showErrorAlert(`Can't get all ${itemName.plural}`, error);
                return [];
            }
        }
    }
}

export const CRUDApi = {
    generate,
}