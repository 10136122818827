import {State} from "./Home.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {BestPracticeApi} from "../../../../../apis/BestPractice/BestPracticeApi";

export const initialState: State = {
    isDownloadingTemplate: false,
}

export const reducer = {
    downloadTemplate: async ({setState}) => {
        setState({isDownloadingTemplate: true});
        const templateLink = await BestPracticeApi.getTemplateLink();
        if (!templateLink) return;
        const link = document.createElement('a');
        link.href = templateLink;
        link.download = templateLink.split('/').pop() ?? "";
        link.rel = 'noopener noreferrer';
        link.target = "_self";
        link.click();
        link.remove();
        setState({isDownloadingTemplate: false});
    }
} satisfies MagicReducerObject<State>;