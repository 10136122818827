import en from 'translations/en.json';
import fr from 'translations/fr.json';
import es from 'translations/es.json';
import de from 'translations/de.json';
import pt from 'translations/pt.json';
import ja from 'translations/ja.json';
import zh from 'translations/zh.json';

export const translations = {
    locale: "en",
    get: (id: string, parameters?: Record<string, string | number>): string => {
        if (!id) return "";
        const object = (translations as any)[translations.locale] ?? (translations as any).en;
        let translation = object[id] ?? (translations as any).en[id] ?? "";
        if (!translation) {
            console.error("Missing translation for '" + id + "'");
            translation = id;
        }
        if (parameters) {
            for (const param in parameters)
                translation = translation.replaceAll("{" + param + "}", parameters[param] + "");
        }
        return translation;
    },
    en: en,
    fr: fr,
    es: es,
    de: de,
    pt: pt,
    ja: ja,
    zh: zh,
}