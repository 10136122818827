import React, {ReactElement} from "react";
import {Props} from "./ItemsStatsKpi.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {useItemStatsTableColumns} from "./hooks/useItemStatsTableColumns";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {Input, useForm} from "@witivio_teamspro/northstar-form";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {translations} from "../../../../translations";
import {ItemDataModule} from "../../../../modules/ItemData.module";
import {ItemData} from "common";
import {List} from "../../../../components/lists/List/List";

export const ItemsStatsKpi = (props: Props): ReactElement | null => {
    const {locale, userMail} = useMsTeamsSelector("locale", "userMail");

    const columns = useItemStatsTableColumns(props);

    const form = useForm({
        locale,
        fluid: true,
        items: {
            search: Input({
                inputMode: "text",
                placeholder: translations.get("SearchFile"),
            })
        }
    });

    const filteredItems = ItemDataModule.filterItems({
        items: props.items,
        query: form.state.data.search ?? "",
        userUpn: userMail,
    });

    return (
        <Flex column fill className={"overflow-hidden"}>
            <Flex gap={"gap.medium"} className={"no-shrink"}>
                <Flex fill>
                    {props.title && <Text truncated weight={"semibold"} size={"larger"} content={props.title}/>}
                </Flex>
                <Flex styles={{marginTop: "-5px", minWidth: "250px"}}>
                    {!props.showSkeletons && form.formItems.search}
                </Flex>
            </Flex>
            {props.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <List<Immutable<ItemData>>
                    key={"items-stats"}
                    items={filteredItems}
                    filterByColumnThrottlingInMs={500}
                    columns={columns}
                    sortLocally
                    initialSortedColumn={"clicks"}
                    initialSortOrder={"descending"}
                />
            }
        </Flex>
    );
}