import {Props, State} from "./ItemsCarousel.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Carousel} from "../Carousel/Carousel";
import {HistoryService} from "../../../services/HistoryService/HistoryService.hook";

export const initialState = (props: Props): State => ({
    menuIndex: props.defaultMenuIndex,
    container: null,
    slidesPerView: 0,
});

export const reducer = (config: {
    props: Props,
    carouselRef: MagicReducerRef<typeof Carousel>,
}) => ({
    containerRef: ({state, setState}, [container]: [HTMLDivElement]) => {
        if (!container) return;
        const containerWidth = container.clientWidth;
        const slidesPerView = getSlidesPerView(containerWidth);
        setState({container, slidesPerView}, state.slidesPerView === 0);
    },
    handleClickMenuItem: (reducerData, [index]: [number]) => {
        const {props, carouselRef} = config;
        const selectedItem = props.menuItems?.[index];
        if (!selectedItem) return;
        if (carouselRef.state) carouselRef.dispatch("slideTo")(0, 0);
        const {state, setState} = reducerData;
        if (state.menuIndex === index) return;
        setState({menuIndex: index});
        saveState(props.cacheKey, reducerData.state);
        props.onChangeMenuIndex(index);
    },
    windowResize: ({state, setState}) => {
        const containerWidth = state.container?.clientWidth;
        if (!containerWidth) return;
        const slidesPerView = getSlidesPerView(containerWidth);
        if (state.slidesPerView === slidesPerView) return;
        setState({slidesPerView});
    },
}) satisfies MagicReducerObject<State>;

const saveState = (cacheKey: string, state: Immutable<State>) => {
    const {menuIndex} = state;
    HistoryService.saveComponentState(cacheKey, {menuIndex}, 300);
}

const getSlidesPerView = (containerWidth: number) => {
    let value = Math.round(containerWidth / 400);
    if (value < 1) value = 1;
    return value;
}