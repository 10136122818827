import {State} from "./Trainings.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {HistoryService} from "../../services/HistoryService/HistoryService.hook";
import {ReportingModule} from "../../modules/Reporting.module";
import {AppView} from "../../types/AppView/AppView";
import {Guid} from "common";

export const trainingsViewCacheKey = "trainings-view";

export const initialState = (): State => {
    const historyState = HistoryService.getComponentState<State>(trainingsViewCacheKey);
    return {
        selectedCategoryIndex: historyState?.selectedCategoryIndex ?? 0,
    }
}

const saveState = (state: Immutable<State>) => {
    const {selectedCategoryIndex} = state;
    HistoryService.saveComponentState(trainingsViewCacheKey, {selectedCategoryIndex}, 300);
}

export const reducer = (userId: Guid) => ({
    handleClickMenuItem: (
        reducerData,
        [index]: [number],
        categories: Array<{ key: string, content: string }> | undefined,
    ) => {
        const selectedItem = categories?.[index];
        if (!selectedItem) return;
        const {state, setState} = reducerData;
        if (state.selectedCategoryIndex === index) return;
        setState({selectedCategoryIndex: index});
        saveState(reducerData.state);
        ReportingModule.notifyTopicSelected(userId, selectedItem.key, AppView.Trainings).then();
    },
    handleSearch: ({render}, [value]: [string]) => {
        ReportingModule.notifyQuery(userId, value, AppView.Trainings).then();
        render();
    }
}) satisfies MagicReducerObject<State>;