import {memo, ReactElement} from "react";
import {Props} from "./TopicButton.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./TopicButton.reducer";
import {CompareModule} from "modules/Compare.module";
import "./TopicButton.styles.scss";
import {AddIcon, Button, CloseIcon, Flex, Text} from "@fluentui/react-northstar";

export const TopicButton = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    return (
        <Button
            className={"topic-button"}
            key={props.title}
            content={
                <Flex fill>
                    <Text content={props.title}/>
                </Flex>
            }
            tinted={!props.isSelected}
            primary={props.isSelected}
            icon={props.isSelected ? <CloseIcon/> : <AddIcon/>}
            iconPosition={"after"}
            onClick={dispatch("click", props)}
            disabled={props.disabled}
            fluid={props.fluid}
        />
    )
}, CompareModule.areObjectsEqual);