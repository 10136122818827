import axios from "axios";
import {ErrorModule} from "components/others/ErrorBoundary/ErrorBoundary";
import {Configuration} from "common";

const getConfiguration = async (): Promise<Configuration> => {
    try {
        const response = await axios.get("/api/settings");
        return response.data as Configuration;
    } catch (error) {
        console.error(error);
        ErrorModule.showErrorAlert("Can't get client configuration", error as Error);
        throw new Error("Can't get client configuration");
    }
}

export const ConfigurationApi = {
    getConfiguration,
}