import {useQuery} from "@tanstack/react-query";
import {GraphApi} from "../../apis/Graph/GraphApi";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Guid} from "common";

export const useUserThumbnailCache = (idOrEmail: Immutable<Guid> | string | undefined) => {
    const {data: imageUrl, isLoading} = useQuery({
        queryKey: ["userThumbnail" + idOrEmail],
        queryFn: () => GraphApi.getUserPhoto(idOrEmail!.toString()),
        staleTime: Infinity,
        enabled: !!idOrEmail
    });

    return {imageUrl, isLoading};
};