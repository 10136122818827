import {HomeLogic} from "../Home.interfaces";
import {ArrowRightIcon, Button, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import React, {useEffect, useRef, useState} from "react";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ItemDataType} from "common/dist/src";
import {SearchItemData} from "../../../components/others/SearchItem/SearchItem.interfaces";
import {SearchItem} from "../../../components/others/SearchItem/SearchItem";

export const SearchResults = (logic: HomeLogic) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [visibleItems, setVisibleItems] = useState<number>(0);

    useEffect(() => {
        if (!containerRef.current) return;
        const categoryTitleHeight = 42;
        const gap = 20;
        const containerHeight = containerRef.current.clientHeight - (categoryTitleHeight * 2) - gap;
        const itemHeight = 66;
        let visibleItems = Math.floor(Math.floor(containerHeight / itemHeight) / 2) - 1;
        if (visibleItems < 6) visibleItems = 6;
        setVisibleItems(visibleItems);
    }, [logic.showSearchResults]);

    if (!logic.showSearchResults) return null;

    const showAllItems = logic.selectedSearchResultsCategoryIndex === 0;
    const showBestPracticesSearchResults = [0, 1].includes(logic.selectedSearchResultsCategoryIndex);
    const showTrainingsSearchResults = [0, 2].includes(logic.selectedSearchResultsCategoryIndex);

    const bestPracticesSearchResults = logic.searchResults?.filter(i => i.type === ItemDataType.BestPractice);
    const trainingsSearchResults = logic.searchResults?.filter(i => i.type === ItemDataType.Training);

    const bestPracticesResults = renderResultsPart({
        type: ItemDataType.BestPractice,
        title: translations.get("BestPractices"),
        results: bestPracticesSearchResults,
        showAllItems,
        visibleItems,
        logic,
    })

    const trainingsResults = renderResultsPart({
        type: ItemDataType.Training,
        title: translations.get("Trainings"),
        results: trainingsSearchResults,
        showAllItems,
        visibleItems,
        logic,
    });

    const styles = {
        gap: "15px",
        paddingBottom: showAllItems ? "30px" : "0",
    }

    const className = [
        "home-view-search-results",
        showAllItems ? "overflow-scroll" : "overflow-hidden",
    ].join(" ");

    return (
        <Flex fill column styles={styles} className={className} ref={containerRef}>
            {showBestPracticesSearchResults && bestPracticesResults}
            {showTrainingsSearchResults && trainingsResults}
            {showAllItems && <div style={{minHeight: "5px"}}/>}
        </Flex>
    )
}

const renderResultsPart = (data: {
    type: ItemDataType,
    results: Immutable<Array<SearchItemData>> | undefined,
    title: string,
    showAllItems: boolean,
    visibleItems: number,
    logic: HomeLogic,
}) => {
    const {results, title, showAllItems, visibleItems, logic} = data;
    const visibleSearchResultsItems = showAllItems ? visibleItems : undefined;
    const visibleResults = logic.showSkeletons ?
        Array.from({length: visibleSearchResultsItems ?? 3}).map(() => undefined) : results?.slice(0, visibleSearchResultsItems);
    const hasResults = visibleResults && visibleResults.length > 0;
    const renderedResults = visibleResults?.map((i, index) => <SearchItem key={!i ? index : i.id} data={i}/>);
    const multipleResults = results && results.length > 1;
    const itemsCountLabel = `(${results?.length} ${multipleResults ? translations.get("Items") : translations.get("Item")})`.toLowerCase();
    const overflowClassName = !showAllItems ? "overflow-hidden" : "";
    return (
        <Flex column className={overflowClassName} fill={!showAllItems}>
            <Flex className={"no-shrink w-100 cursor-pointer " + overflowClassName} vAlign={"center"} gap={"gap.small"}
                  onClick={data.logic.dispatch("showSearchMoreItems", data.type)}>
                <Text
                    className={"home-view-search-part-title"}
                    size={"larger"}
                    weight={"bold"}
                    content={title}
                />
                <Text className={"primary-color"} styles={{marginBottom: "5px"}} content={itemsCountLabel}/>
            </Flex>
            <Flex fill column gap={"gap.small"} className={"overflow-scroll"}
                  styles={!showAllItems ? {paddingBottom: "30px"} : {}}>
                {hasResults ? renderedResults : (
                    <Text styles={{color: "darkgray"}} content={translations.get("NoResults")}/>
                )}
            </Flex>
            {showAllItems &&
                <Flex styles={{margin: "10px 0 0 0"}}>
                    <Button
                        primary content={translations.get("ShowAllItems", {name: title.toLowerCase()})}
                        iconPosition={"after"} icon={<ArrowRightIcon/>}
                        onClick={data.logic.dispatch("showSearchMoreItems", data.type)}
                    />
                </Flex>
            }
        </Flex>
    )
}