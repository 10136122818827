"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileHostType = void 0;
var FileHostType;
(function (FileHostType) {
    FileHostType[FileHostType["Sharepoint"] = 0] = "Sharepoint";
    FileHostType[FileHostType["OneDrive"] = 1] = "OneDrive";
    FileHostType[FileHostType["Teams"] = 2] = "Teams";
    FileHostType[FileHostType["OneNote"] = 3] = "OneNote";
    FileHostType[FileHostType["Unknown"] = 4] = "Unknown";
})(FileHostType || (exports.FileHostType = FileHostType = {}));
