import {Immutable, MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./UserSettingsDialog.reducer";

export enum UserSettingsDialogStep {
    JobFamily = 0,
    PrimaryChannels = 1,
    Topics = 2,
    Interests = 3
}

export type UserSettingsStepData = {
    step: UserSettingsDialogStep,
    title: string,
    subtitle: string,
    items: Immutable<string[]>,
    selectedItems: Immutable<string[]>,
    isValid: boolean,
    toggleItem: (item: string) => void,
    searchPlaceholder: string,
    selectedItemsLabel: string,
    maxSelectedItems: number,
    fluidItems?: boolean,
}

export type State = {
    isSaving: boolean,
    step: UserSettingsDialogStep,
}

export type Props = {
    externalRef: MagicReducerExternalRef<typeof reducer>,
}