import React, {memo, ReactElement} from "react";
import {Props} from "./Details.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Details.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Details.styles.scss";
import {Divider, Flex} from "@fluentui/react-northstar";

export const Details = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    return (
        <Flex fill gap={"gap.medium"} className={"share-item-dialog-details-part"}>
            <Flex fill column gap={"gap.medium"}>
                {props.form.formItems.topic}
                {props.form.formItems.title}
                {props.form.formItems.description}
            </Flex>
            <Flex>
                <Divider vertical/>
            </Flex>
            <Flex className={"share-item-dialog-details-part-thumbnail-container"}>
                {props.form.formItems.thumbnail}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);