import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./Top3ContentDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Top3ContentDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex, Loader} from "@fluentui/react-northstar";
import "./Top3ContentDialog.styles.scss";
import {translations} from "../../../translations";
import {useBestPracticesCache} from "../../../hooks/cache/bestPracticesCache";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";
import {ItemCard} from "../../others/ItemCard/ItemCard";

export const Top3ContentDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const {bestPractices} = useBestPracticesCache();
    const {hasUserVisitedItem, userData} = useUserDataCache();
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const top3BestPractices = useMemo(() => {
        return bestPractices?.filter(bp => !hasUserVisitedItem(bp.type, bp.id)).slice(0, 3);
    }, [bestPractices]);

    const isLoading = !top3BestPractices || !userData;

    return (
        <Dialog
            externalRef={dialogRef}
            width={900}
            title={translations.get("YourMonthlyRecommendedContent")}
            content={
                <Flex fill gap={"gap.medium"} vAlign={"center"} hAlign={"center"}>
                    {isLoading ?
                        <Loader/>
                        :
                        top3BestPractices?.map(bp => (
                            <ItemCard key={bp.id} item={bp} hideRecommendButton={true}/>
                        ))
                    }
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);