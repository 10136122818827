import {Immutable} from "@witivio_teamspro/use-reducer";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {AxiosError} from "axios";

const getCommunities = () => new Promise<Immutable<Array<string>>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get("/communities");
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get communities", error as AxiosError);
        resolve([]);
    }
});

export const CommunityApi = {
    getCommunities,
}