import axios, {AxiosError, CancelTokenSource} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ItemDataModule} from "../../modules/ItemData.module";
import {TrainingsModule} from "../../modules/Trainings.module";
import {ItemData, ItemDataType, SharepointComment, Training} from "common";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";

let getAllTrainingsCancelToken: CancelTokenSource | undefined = undefined;

const getAll = () => new Promise<Array<Immutable<ItemData>> | undefined>(async (resolve) => {
    if (getAllTrainingsCancelToken) getAllTrainingsCancelToken.cancel();
    getAllTrainingsCancelToken = axios.CancelToken.source();
    try {
        const response = await AxiosConfig.instance.get("/trainings", {cancelToken: getAllTrainingsCancelToken.token});
        const trainings: Array<Immutable<Training>> = response.data;
        let items: Array<Immutable<ItemData>> = trainings.map(t => ({
            type: ItemDataType.Training,
            id: t.id,
            title: t.title,
            description: t.description,
            pictureUrl: TrainingsModule.getTopicPictureUrl(trainings as unknown as Array<Immutable<ItemData>>, t.topics[0] ?? ""),
            likes: t.reactions.likes,
            link: t.link.url,
            rating: 0,
            jobFamilies: t.jobFamily,
            topics: t.topics,
            types: t.type,
            affiliate: undefined,
            creationDate: t.created,
            lastUpdate: t.modified,
            brand: t.brand,
            source: t.source,
            comments: t.reactions.commentCount,
            clicks: 0,
            returnRate: 0,
            canInteract: !!t.sharepointPageId,
            isReplicated: false,
            tags: [],
            channels: [],
        }));
        items = ItemDataModule.sortItemsByTitle(items);
        return resolve(items);
    } catch (error) {
        if (axios.isCancel(error)) return resolve(undefined);
        ErrorModule.showErrorAlert("Can't get trainings", error as AxiosError);
        resolve(undefined);
    }
});

const postComment = (id: string, comment: string) => new Promise<boolean>(async (resolve) => {
    try {
        await AxiosConfig.instance.post(`/trainings/${id}/comment`, {text: comment});
        return resolve(true);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't post comment", error as AxiosError);
        resolve(true);
    }
});

const like = (id: string) => new Promise<boolean>(async (resolve) => {
    try {
        await AxiosConfig.instance.get(`/trainings/${id}/like`);
        return resolve(true);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't like training", error as AxiosError);
        resolve(true);
    }
});

const unlike = (id: string) => new Promise<boolean>(async (resolve) => {
    try {
        await AxiosConfig.instance.get(`/trainings/${id}/unlike`);
        return resolve(true);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't dislike training", error as AxiosError);
        resolve(true);
    }
});

const getComments = (id: string) => new Promise<Array<SharepointComment>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get(`/trainings/${id}/comments`);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get comments of training", error as AxiosError);
        resolve([]);
    }
});

export const TrainingApi = {
    getAll,
    postComment,
    like,
    unlike,
    getComments,
}