import {Props, State} from "./DocumentButton.types";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {FileModule} from "../../../modules/File.module";
import * as microsoftTeams from "@microsoft/teams-js";
import copy from "copy-to-clipboard";
import {DialogContextValue} from "../../../services/DialogContext/DialogContext.types";
import {translations} from "../../../translations";

export const initialState: State = {}

export const reducer = (config: {
    props: Props,
    filePreviewDialog: DialogContextValue["filePreviewDialog"],
    renameFileDialog: DialogContextValue["renameFileDialog"],
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
}) => ({
    openPreview: () => {
        if (!config.props.file) return;
        config.filePreviewDialog.dispatch("open", {
            file: config.props.file,
            hideActions: config.props.canDownload === false
        })();
    },
    removeFile: () => {
        if (!config.props.file) return;
        config.confirmDeleteDialog.dispatch("open", {
            title: translations.get("DeleteFile"),
            subtitle: translations.get("DeleteFileConfirmation", {title: config.props.file.name}),
            callback: () => config.props.onRemove?.(config.props.file!),
        })();
    },
    renameFile: () => {
        if (!config.props.file) return;
        config.renameFileDialog.dispatch("open", {
            file: config.props.file,
            callback: (name) => config.props.onRename?.({...config.props.file!, name}),
        })();
    },
    downloadFile: async () => {
        if (!config.props.file) return;
        const {id} = config.props.file;
        await FileModule.downloadFile(id);
    },
    shareFileOnTeams: async () => {
        if (!config.props.file) return;
        const {fileId, driveId} = config.props.file;
        const shareLink = await FileModule.getFileSharedLink({driveId, fileId});
        if (!shareLink) return;
        await microsoftTeams.sharing.shareWebContent({
            content: [{type: "URL", url: shareLink, preview: true}]
        }).catch(err => {
            console.error(err)
        })
    },
    copyFileLink: async () => {
        if (!config.props.file) return;
        const {fileId, driveId} = config.props.file;
        const shareLink = await FileModule.getFileSharedLink({driveId, fileId});
        if (!shareLink) return;
        copy(shareLink);
    },
    shareFileByEmail: async () => {
        if (!config.props.file) return;
        const {fileId, driveId, name} = config.props.file;
        const shareLink = await FileModule.getFileSharedLink({driveId, fileId});
        if (!shareLink) return;
        window.open(`https://outlook.office.com/mail/deeplink/compose?subject=${encodeURIComponent(name)}&body=${encodeURIComponent(shareLink)}`, "_blank");
    },
}) satisfies MagicReducerObject<State>;