import {State} from "./LaunchpadAppsListDialog.types";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: () => {
        config.dialogRef.dispatch("open")();
    },
}) satisfies MagicReducerObject<State>;