import {ShareDialogStep, State} from "./ShareItemDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {FileData, PublishRequest} from "common";
import {useShareItemForm} from "../../../hooks/forms/useShareItemForm";
import {BestPracticeApi} from "../../../apis/BestPractice/BestPracticeApi";

export const initialState: State = {
    step: ShareDialogStep.Process,
    resources: [],
    isPublishing: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    form: ReturnType<typeof useShareItemForm>,
    setDisableForm: (value: boolean) => void,
}) => ({
    open: ({setState}) => {
        setState({...initialState});
        config.form.detailsForm.reset();
        config.form.imagesForm.reset();
        config.form.resourcesForm.reset();
        config.dialogRef.dispatch("open")();
    },
    nextStep: ({state, setState}) => {
        setState({step: state.step + 1})
    },
    prevStep: ({state, setState}) => {
        setState({step: state.step - 1})
    },
    addResource: ({state, setState}, [resource]: [Immutable<FileData>]) => {
        setState({resources: [...state.resources, resource]});
    },
    removeResource: ({state, setState}, [resource]: [Immutable<FileData>]) => {
        setState({resources: state.resources.filter(r => r !== resource)});
    },
    publish: async ({state, setState}) => {
        const {detailsForm, imagesForm} = config.form;
        const doc = new DOMParser().parseFromString(detailsForm.state.data.description ?? "", "text/html");
        const description = doc.body.textContent || "";
        const publishRequest: PublishRequest = {
            title: detailsForm.state.data.title ?? "",
            description,
            htmlDescription: detailsForm.state.data.description ?? "",
            thumbnail: detailsForm.state.data.thumbnail ?? "",
            images: [
                imagesForm.state.data.image1 ?? "",
                imagesForm.state.data.image2 ?? "",
                imagesForm.state.data.image3 ?? "",
                imagesForm.state.data.image4 ?? "",
            ].filter(Boolean),
            resources: state.resources as Array<FileData>,
            topic: detailsForm.state.data.topic ?? "",
        }
        setState({isPublishing: true});
        config.setDisableForm(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setState({isPublishing: false});
        config.setDisableForm(false);
        setState({step: ShareDialogStep.Publish});
        await BestPracticeApi.publish(publishRequest);
    }
}) satisfies MagicReducerObject<State>;