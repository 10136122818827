import {QueryClient, SetDataOptions} from "@tanstack/react-query";

export type QueryCacheNotifyEvent = Exclude<Parameters<Exclude<Parameters<ReturnType<QueryClient['getQueryCache']>['subscribe']>[0], undefined>>[0], undefined>;

export type NotifyEventQueryUpdated = QueryCacheNotifyEvent & {
    action: {
        data: any;
        dataUpdatedAt?: number;
    }
}

const ignoredQueryEventOptions: SetDataOptions = {
    updatedAt: -1,
}

const isQueryEvent = (event: QueryCacheNotifyEvent | undefined): event is NotifyEventQueryUpdated => {
    const queryEvents: Array<QueryCacheNotifyEvent["type"]> = ['added', 'updated', 'removed'];
    return !!event?.type && queryEvents.includes(event?.type);
}

const isUpdateQueryEvent = (event: QueryCacheNotifyEvent | undefined): event is NotifyEventQueryUpdated => {
    if (!event) return false;
    return event && event.type === 'added';
}

const isQueryEventIgnored = (event: QueryCacheNotifyEvent): boolean => {
    if (!event || event.type !== 'updated') return true;
    if (event.action?.type !== 'success') return true;
    return event.action.dataUpdatedAt === ignoredQueryEventOptions.updatedAt;
}

export const QueryModule = {
    isQueryEvent,
    ignoredQueryEventOptions,
    isQueryEventIgnored,
    isUpdateQueryEvent,
}