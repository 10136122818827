import {
    ExcelColorIcon,
    ExcelIcon,
    FilesCodeIcon,
    FilesEmptyIcon,
    FilesHtmlColoredIcon,
    FilesPdfIcon,
    FilesPictureColoredIcon,
    FilesSoundIcon,
    FilesTxtIcon,
    FilesVideoIcon,
    FilesZipIcon,
    OneNoteColorIcon,
    OneNoteIcon,
    PowerPointColorIcon,
    PowerPointIcon,
    WordColorIcon,
    WordIcon
} from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {FileData, FileHostType, FileType, Guid, GuidModule} from "common";
import {GraphApi} from "../apis/Graph/GraphApi";
import {IFilePickerFile} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/FilePicker/FilePicker";
import {FileApi} from "../apis/File/FileApi";
import {DriveItem} from "@microsoft/microsoft-graph-types";

const getFileType = (extension: string | undefined): FileType => {
    if (!extension) return FileType.Other;
    const lowerCaseExtension = extension.toLowerCase();
    switch (lowerCaseExtension) {
        case "doc":
        case "docx":
            return FileType.Word;
        case "xls":
        case "xlsx":
        case "xlsm":
        case "csv":
            return FileType.Excel;
        case "one":
        case "onetoc2":
            return FileType.OneNote;
        case "ppt":
        case "pptx":
            return FileType.PowerPoint;
        case "txt":
        case "md":
            return FileType.Text;
        case "pdf":
            return FileType.Pdf;
        case "html":
            return FileType.Html;
        default:
            break;
    }
    const codeExtensions = ["js", "ts", "tsx", "css", "py", "c", "cpp", "hpp", "cgi", "pl", "class", "cs", "h", "java", "php", "sh", "swift", "vb"];
    if (codeExtensions.includes(lowerCaseExtension)) return FileType.Code;
    const archiveExtensions = ["zip", "rar", "tar", "7z", "gzip", "cab"];
    if (archiveExtensions.includes(lowerCaseExtension)) return FileType.Archive;
    const imageExtensions = ["jpg", "png", "heic", "heif", "jpeg", "gif", "tiff", "raw", "svg", "bmp", "webp"];
    if (imageExtensions.includes(lowerCaseExtension)) return FileType.Image;
    const soundExtensions = ["mp3", "wav", "aiff", "flac", "aac", "m4a"];
    if (soundExtensions.includes(lowerCaseExtension)) return FileType.Sound;
    const videoExtensions = ["mp4", "avi", "mov", "webm", "mkv", "flv", "wmv", "mpeg", "m4v", "3gp"];
    if (videoExtensions.includes(lowerCaseExtension)) return FileType.Video;
    return FileType.Other;
}

const getFileIcon = (fileType: FileType | undefined): typeof WordColorIcon => {
    switch (fileType) {
        case FileType.Word:
            return WordColorIcon;
        case FileType.Excel:
            return ExcelColorIcon;
        case FileType.OneNote:
            return OneNoteColorIcon;
        case FileType.PowerPoint:
            return PowerPointColorIcon;
        case FileType.Text:
            return FilesTxtIcon;
        case FileType.Pdf:
            return FilesPdfIcon;
        case FileType.Html:
            return FilesHtmlColoredIcon;
        case FileType.Code:
            return FilesCodeIcon;
        case FileType.Archive:
            return FilesZipIcon;
        case FileType.Image:
            return FilesPictureColoredIcon;
        case FileType.Sound:
            return FilesSoundIcon;
        case FileType.Video:
            return FilesVideoIcon;
        case FileType.Other:
            return FilesEmptyIcon;
        default:
            return FilesEmptyIcon;
    }
}

const isFileEditable = (fileType: FileType | undefined) => {
    if (fileType === undefined) return false;
    return [FileType.Word, FileType.Excel, FileType.PowerPoint].includes(fileType);
}

const getFileEditAppName = (fileType: FileType | undefined): string => {
    if (fileType === undefined) return "";
    const canEdit = isFileEditable(fileType);
    if (!canEdit) return "";
    return FileType[fileType] + "";
}

const getFileEditAppIcon = (fileType: FileType | undefined) => {
    switch (fileType) {
        case FileType.Word:
            return WordIcon;
        case FileType.Excel:
            return ExcelIcon;
        case FileType.OneNote:
            return OneNoteIcon;
        case FileType.PowerPoint:
            return PowerPointIcon;
        default:
            return null;
    }
}

const getFileNameWithoutExtension = (fileName: string | undefined | null) => {
    if (!fileName) return;
    return fileName.slice(0, fileName.lastIndexOf("."));
}

const getFileExtension = (fileName: string | undefined | null) => {
    if (!fileName) return;
    const pathFileName = fileName.slice(fileName.lastIndexOf("/") + 1);
    if (!pathFileName.includes(".")) return;
    return pathFileName.slice(pathFileName.lastIndexOf(".") + 1).toLowerCase();
}

const getFileHostType = (fileUrl: string, fileType: FileType): FileHostType => {
    const urlHost = new URL(fileUrl).host;
    if (fileType === FileType.OneNote) return FileHostType.OneNote;
    if (urlHost.endsWith("my.sharepoint.com")) return FileHostType.OneDrive;
    if (urlHost.endsWith("sharepoint.com")) return FileHostType.Sharepoint;
    return FileHostType.Unknown;
}

const generateFileTeamsDeeplink = (fileName: string, fileUrl: string) => {
    const extension = getFileExtension(fileName);
    const source = "p2p_ns";
    const encodedUrl = encodeURIComponent(fileUrl).replace(/%2F/g, "~2F");
    return `https://teams.microsoft.com/_#/${extension}/viewer/${source}/${encodedUrl}`;
}

const formatFileLocationPath = (fileUrl: string, fileType: FileType) => {
    let url = new URL(fileUrl);
    let locationPath = "";
    if (fileType === FileType.OneNote) {
        locationPath += "OneNote";
    } else if (url.pathname.startsWith("/personal/")) {
        locationPath += "OneDrive";
        let personalPath = decodeURI(url.pathname.slice(0, url.pathname.indexOf("/", 10) + 1));
        const folderPathBeginIndex = url.pathname.indexOf(personalPath) + personalPath.length;
        locationPath += "/" + decodeURI(url.pathname.slice(folderPathBeginIndex, url.pathname.lastIndexOf("/") + 1));
    } else if (url.pathname.startsWith("/sites/")) {
        locationPath += "Sharepoint";
        let sitePath = url.pathname.slice(0, url.pathname.indexOf("/", 7) + 1);
        const folderPathBeginIndex = url.pathname.indexOf(sitePath) + sitePath.length;
        locationPath += "/" + decodeURI(url.pathname.slice(folderPathBeginIndex, url.pathname.lastIndexOf("/") + 1));
    } else {
        locationPath += "Sharepoint";
        locationPath += "/" + decodeURI(url.pathname.slice(0, url.pathname.lastIndexOf("/") + 1));
    }
    locationPath = decodeURI(locationPath);
    if (locationPath.endsWith("/")) locationPath = locationPath.slice(0, locationPath.length - 1);
    if (locationPath.includes("/Forms")) locationPath = locationPath.slice(0, locationPath.indexOf("/Forms"));
    if (locationPath.includes("/_layouts")) locationPath = locationPath.slice(0, locationPath.indexOf("/_layouts"));
    locationPath = locationPath.replaceAll(/\/+/g, "/");
    return locationPath.replace(/\//g, " > ");
}

const filesSharedLinks: Record<string, string> = {};

const getFileSharedLink = async (config: { fileId: string, driveId: string }) => {
    const {fileId, driveId} = config;
    let sharedLink: string | undefined = filesSharedLinks[fileId + driveId];
    if (!sharedLink) {
        sharedLink = await GraphApi.getFileSharedLink(config);
        filesSharedLinks[fileId + driveId] = sharedLink ?? "";
    }
    return sharedLink;
}

const openFileParentFolder = async (config: { fileId: string, driveId: string }) => {
    let link = await getFileSharedLink(config);
    if (!link) return;
    link = link.slice(0, link.lastIndexOf("/"));
    window.open(link, "_blank");
}

const openFileInWebBrowser = async (config: { fileId: string, driveId: string }) => {
    const link = await getFileSharedLink(config);
    if (!link) return;
    window.open(link, "_blank");
}

const openFileInDesktopApp = async (config: { fileId: string, driveId: string, fileType: FileType }) => {
    const url = new URL(await getFileSharedLink(config) ?? "");
    let link = url.origin;
    if (url.pathname.includes("/site")) link += url.pathname.slice(url.pathname.indexOf("/site"))
    else if (url.pathname.includes("/personal")) link += url.pathname.slice(url.pathname.indexOf("/personal"))
    else {
        link += url.pathname.replace("/:x:/s/", "/");
    }
    let protocol = "";
    switch (config.fileType) {
        case FileType.Excel:
            protocol = "ms-excel";
            break;
        case FileType.Word:
            protocol = "ms-word";
            break;
        case FileType.PowerPoint:
            protocol = "ms-powerpoint";
            break;
        default:
            break;
    }
    link = `${protocol}:${encodeURIComponent("ofe|u|")}${link}`;
    const htmlLink = document.createElement('a');
    htmlLink.href = link;
    htmlLink.rel = 'noopener noreferrer';
    htmlLink.target = '_self';
    htmlLink.click();
    htmlLink.remove();
}

const openFileInStageView = async (fileUrl: string) => {
    const sharepointID = "2a527703-1f6f-4559-a332-d8a7d288cd88";
    const regex = /^(https?:\/\/[^\/]+)(\/.*)$/;
    const matches = fileUrl.match(regex);
    if (!matches) return;
    const root = matches[1];
    const path = matches[2];
    const context = {
        contentUrl: `${root}/_layouts/15/teamslogon.aspx?spfx=true&dest=${path}`,
        websiteUrl: fileUrl
    }
    const encodeContext = encodeURIComponent(JSON.stringify(context));
    const deeplink = `https://teams.microsoft.com/l/stage/${sharepointID}/0?context=${encodeContext}`;
    await microsoftTeams.app.openLink(deeplink);
}

const downloadFile = async (id: Immutable<Guid>, isOnMobile?: boolean) => {
    let downloadUrl = await FileApi.getDownloadUrl(id);
    if (!downloadUrl) return;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = downloadUrl.split('/').pop() ?? "";
    link.rel = 'noopener noreferrer';
    link.target = isOnMobile ? '_blank' : "_self";
    link.click();
    link.remove();
}

const parseRawFile = (id: Immutable<Guid>, data: DriveItem | null): FileData | null => {
    if (!data) return null;
    const extension = FileModule.getFileExtension(data.name);
    if (!extension) return null;
    const fileType = FileModule.getFileType(extension);
    const name = FileModule.getFileNameWithoutExtension(data.name) ?? "";
    const locationPath = !data.webUrl ? "" : FileModule.formatFileLocationPath(data.webUrl, fileType);
    if (fileType === FileType.OneNote && !data.webUrl?.includes("?"))
        data.webUrl += "?web=1&action=default&mobileRedirect=1";
    return {
        id: id as Guid,
        fileId: data.id ?? "",
        name,
        extension,
        fileType,
        url: data.webUrl ?? "",
        locationPath,
        driveId: data.parentReference?.driveId ?? "",
        ownerId: data.createdBy?.user?.id ?? "",
        updatedAt: data.lastModifiedDateTime ?? "",
    };
}

const getFileNameCopy = (filesNames: Array<string> | undefined, fileName: string) => {
    if (!filesNames || filesNames.length === 0) return fileName;
    let baseName = fileName;
    let extension = '';
    const dotIndex = fileName.lastIndexOf('.');
    if (dotIndex !== -1) {
        baseName = fileName.substring(0, dotIndex);
        extension = fileName.substring(dotIndex);
    }
    let copyNumber = 1;
    let newFileName = fileName;
    while (filesNames.includes(newFileName)) {
        newFileName = `${baseName}(${copyNumber})${extension}`;
        copyNumber++;
    }
    return newFileName;
}

const generateFileDataFromFile = (file: IFilePickerFile): FileData | undefined => {
    const name = FileModule.getFileNameWithoutExtension(file.name) ?? "";
    const extension = FileModule.getFileExtension(file.name) ?? "";
    const fileType = FileModule.getFileType(extension);
    return {
        id: GuidModule.generateGUID(),
        fileId: "",
        content: file.content,
        driveId: "",
        editData: undefined,
        extension,
        fileType,
        locationPath: "/" + file.name,
        name,
        ownerId: "",
        updatedAt: "",
        url: ""
    }
}

export const FileModule = {
    getFileIcon,
    generateFileTeamsDeeplink,
    getFileExtension,
    getFileHostType,
    formatFileLocationPath,
    getFileNameWithoutExtension,
    isFileEditable,
    openFileInWebBrowser,
    downloadFile,
    getFileEditAppName,
    getFileSharedLink,
    openFileParentFolder,
    getFileType,
    openFileInDesktopApp,
    getFileEditAppIcon,
    parseRawFile,
    getFileNameCopy,
    openFileInStageView,
    generateFileDataFromFile,
}