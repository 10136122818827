import {State} from "./TemplateList.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {Notification} from "common";
import {DialogContextValue} from "../../../services/DialogContext/DialogContext.types";
import {translations} from "../../../translations";
import {useNotificationTemplatesCache} from "../../../hooks/cache/notificationTemplatesCache";

export const initialState: State = {}

export const reducer = (config: {
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
    deleteTemplate: ReturnType<typeof useNotificationTemplatesCache>["deleteTemplate"],
}) => ({
    openDeleteDialog: (_, [notification]: [Immutable<Notification>]) => {
        config.confirmDeleteDialog.dispatch("open", {
            title: translations.get("DeleteTemplate"),
            subtitle: translations.get("AreYouSureToDeleteTheTemplate", {title: notification.title}),
            callback: () => config.deleteTemplate(notification.id),
        })();
    }
}) satisfies MagicReducerObject<State>;