"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuidModule = void 0;
const IIdentifiable_1 = require("../types/IIdentifiable");
const guidPattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
function generateGUID() {
    let uuid = self.crypto.randomUUID();
    return (0, IIdentifiable_1.Guid)(uuid);
}
const isValidGuid = (value) => {
    if (!value)
        return false;
    return guidPattern.test(value);
};
exports.GuidModule = {
    generateGUID,
    isValidGuid
};
