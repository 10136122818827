import React, {ReactElement, useMemo} from "react";
import {ImmutableObject, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer, trainingsViewCacheKey} from "./Trainings.reducer";
import "./Trainings.styles.scss";
import {Flex, SearchIcon, Text} from "@fluentui/react-northstar";
import {ItemDataModule} from "../../modules/ItemData.module";
import {TrainingsModule} from "../../modules/Trainings.module";
import {translations} from "../../translations";
import moment from "moment/moment";
import {ScreenModule} from "../../modules/Screen.module";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {ItemDialog} from "../../components/dialogs/ItemDialog/ItemDialog";
import {Searchbar} from "components/others/Searchbar/Searchbar";
import {AutoWidthMenu} from "../../components/others/AutoWidthMenu/AutoWidthMenu";
import {useMyRecommendationsCache} from "../../hooks/cache/recommendationsCache";
import {useTrainingsCache} from "../../hooks/cache/trainingsCache";
import {IFilterItem} from "../../components/others/Filter/Filter.types";
import {ItemData} from "common";
import {ItemDialogRef} from "../../components/dialogs/ItemDialog/ItemDialog.interfaces";
import {VirtualizedGrid} from "../../components/others/VirtualizedGrid/VirtualizedGrid";
import {ItemCard} from "../../components/others/ItemCard/ItemCard";

export const Trainings = (): ReactElement => {
    const {userId, userMail} = useMsTeamsSelector("userId", "userMail");
    const [state, dispatch] = useMagicReducer(reducer(userId), initialState());
    const {canRecommendItem} = useMyRecommendationsCache();

    const itemDialogRef = useMagicReducerRef(ItemDialog);
    const searchBarRef = useMagicReducerRef(Searchbar);
    const menuRef = useMagicReducerRef(AutoWidthMenu);

    const {trainings} = useTrainingsCache();

    const isSmallScreen = ScreenModule.isSmallScreen();

    const categories = useMemo(() => ItemDataModule.generateCategories(trainings), [trainings]);

    let selectedFromDate = (searchBarRef.state?.filterItems?.["from-date"]?.inputValue ?? "") + "";
    if (!selectedFromDate) selectedFromDate = moment().startOf("day").add(-1, "year").format("YYYY-MM-DD");
    let selectedToDate = (searchBarRef.state?.filterItems?.["to-date"]?.inputValue ?? "") + "";
    if (!selectedToDate) selectedToDate = moment().startOf("day").format("YYYY-MM-DD");

    const filters = useMemo(() => {
        const items = TrainingsModule.generateFilterItems(trainings) as Array<IFilterItem>;
        const fromDateItem = items.find(i => i.key === "from-date");
        if (fromDateItem?.input) {
            fromDateItem.input.max = selectedToDate;
            fromDateItem.input.value = selectedFromDate;
        }
        const toDateItem = items.find(i => i.key === "to-date");
        if (toDateItem?.input) {
            toDateItem.input.min = selectedFromDate;
            toDateItem.input.value = selectedToDate;
        }
        return items;
    }, [trainings, selectedFromDate, selectedToDate]);

    const query = (searchBarRef.state?.query ?? "").toLowerCase();

    const filterItems = searchBarRef.state?.filterItems;

    const selectedCategory = state.selectedCategoryIndex !== 0 ? categories?.[state.selectedCategoryIndex] : undefined;

    const overrideImage = state.selectedCategoryIndex === 0 ? undefined :
        TrainingsModule.getTopicPictureUrl(trainings, selectedCategory?.key);

    const filteredTrainings = useMemo(() => {
        return ItemDataModule.filterItems({
            items: trainings,
            query,
            filterItems,
            selectedCategoryKey: selectedCategory?.key,
            userUpn: userMail,
        });
    }, [state.selectedCategoryIndex, trainings, categories, query, filterItems]);

    const itemDialog = <ItemDialog externalRef={itemDialogRef}/>;

    const header = (
        <Flex className={"w-100"} column gap={"gap.medium"}>
            {!isSmallScreen &&
                <Flex column hAlign={"center"} styles={{gap: "3px"}}>
                    <Text
                        className={"primary-color"}
                        size={"larger"}
                        weight={"bold"}
                        content={translations.get("Trainings")}
                    />
                    <Text
                        className={"primary-color"}
                        content={translations.get("TrainingsSubtitle")}
                    />
                </Flex>
            }
            <Flex column className={"w-100"} hAlign={"center"} gap={isSmallScreen ? "gap.medium" : "gap.small"}>
                <Searchbar
                    id={trainingsViewCacheKey + "searchbar"}
                    throttling={600}
                    externalRef={searchBarRef}
                    styles={isSmallScreen ? {width: "100%"} : {minWidth: "500px", maxWidth: "800px"}}
                    placeholder={translations.get("SearchByKeyword") + "..."}
                    onSearch={dispatch("handleSearch")}
                    filterItems={filters}
                />
                <AutoWidthMenu
                    externalRef={menuRef}
                    align={"center"}
                    defaultMenuIndex={state.selectedCategoryIndex}
                    items={categories}
                    onIndexChange={dispatch("handleClickMenuItem", categories)}
                />
            </Flex>
        </Flex>
    )

    return (
        <Flex fill column className={"trainings-view no-select"}>
            <Flex fill hAlign={"center"} column className={"trainings-view-content"}>
                {header}
                {renderItemsList(filteredTrainings, itemDialogRef, overrideImage, isSmallScreen, canRecommendItem)}
            </Flex>
            {itemDialog}
        </Flex>
    )
}

const renderItemsList = (
    filteredTrainings: ImmutableObject<ItemData>[] | undefined,
    itemDialogRef: ItemDialogRef,
    overrideImage: string | undefined,
    isSmallScreen: boolean,
    canRecommendItem: boolean,
) => {
    return (
        <Flex column fill hAlign={"center"} vAlign={"center"} className={"trainings-grid-container"}>
            {filteredTrainings?.length === 0 ?
                <Flex column hAlign={"center"} vAlign={"center"} gap={"gap.small"} styles={{marginTop: "50px"}}>
                    <SearchIcon outline size={"largest"}/>
                    <Text size={"large"} content={translations.get("NoResults")}/>
                </Flex>
                :
                <VirtualizedGrid
                    styles={{marginTop: "5px", marginBottom: "50px", flexShrink: 0}}
                    renderSkeleton={renderSkeleton}
                    itemHeight={isSmallScreen ? 211 : 248}
                    gridGap={30}
                    itemMinWidth={300}
                    gridPadding={12}
                    showSkeletons={!filteredTrainings}
                    scrollToTopOnNewChildren
                    showAlwaysScrollbar
                    visibleLinesOffset={0}>
                    {filteredTrainings?.map(t => (
                        <ItemCard
                            key={t.id.toString()}
                            item={t}
                            itemDialogRef={itemDialogRef}
                            overrideImage={overrideImage}
                            hideRecommendButton={!canRecommendItem}
                        />
                    ))}
                </VirtualizedGrid>
            }
        </Flex>
    )
}

const renderSkeleton = (key: string) => <ItemCard key={key} item={undefined} hideRecommendButton={true}/>;