import {State} from "./IDidItFormDialog.types";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {
    bestPracticeId: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, bestPracticeId: string) => {
        setState({bestPracticeId});
        config.dialogRef.dispatch("open")();
    },
}) satisfies MagicReducerObject<State>;