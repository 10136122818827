import {Button, Flex, Header, Image, Text} from "@fluentui/react-northstar";
import {translations} from "translations";
import React, {memo} from "react";
import {LoadingPage} from "../LoadingPage/LoadingPage";
import {useGraphScopes, useGraphScopesSelector} from "../../hooks/useGraphScopes";

export const Consent = memo(() => {
    const {
        teamsFxConfig,
        needToConsent,
        isConsenting
    } = useGraphScopesSelector("teamsFxConfig", "needToConsent", "isConsenting");
    const {bindConsentButton} = useGraphScopes();

    if (!teamsFxConfig || !needToConsent) return <LoadingPage/>;

    return (
        <Flex
            fill
            styles={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999}}
            vAlign={"center"}
            hAlign={"center"}
            column
        >
            <Flex fill vAlign={"center"} hAlign={"center"} column>
                <Image width="300px" height="300px" alt="login image" src={"/loginImage.svg"}/>
                <Header
                    styles={{marginTop: "10px", marginBottom: "5px"}}
                    as={"h2"}
                    content={teamsFxConfig?.loginPageTitle}
                />
                <Text
                    styles={{marginTop: "0", marginBottom: "15px"}}
                    content={teamsFxConfig?.loginPageSubtitle}
                />
                <Button
                    ref={bindConsentButton}
                    primary
                    loading={isConsenting}
                    disabled={isConsenting}
                    content={translations.get("Consent")}
                />
            </Flex>
        </Flex>
    );
}, () => true);