import {Home} from "../views/Home/Home";
import {BestPractices} from "../views/BestPractices/BestPractices";
import {Trainings} from "../views/Trainings/Trainings";
import {Reporting} from "../views/Reporting/Reporting";
import {Notifications} from "../views/Notifications/Notifications";
import {Dashboard} from "../views/Dashboard/Dashboard";
import {AppView} from "../types/AppView/AppView";
import {AppViewData} from "../types/AppView/AppViewData";
import {
    ChartMultipleRegular,
    HomeRegular,
    LightbulbFilamentRegular,
    MegaphoneRegular,
    PresenterRegular
} from "@fluentui/react-icons";
import {UserThumbnail} from "../components/others/UserThumbnail/UserThumbnail";
import React from "react";

const HomeView: AppViewData = {
    id: AppView.Home,
    path: "/",
    element: Home,
    icon: HomeRegular,
    titleId: "Home",
}

const BestPracticesView: AppViewData = {
    id: AppView.BestPractices,
    path: "/best-practices",
    element: BestPractices,
    icon: LightbulbFilamentRegular,
    titleId: "BestPractices",
}

const TrainingsView: AppViewData = {
    id: AppView.Trainings,
    path: "/trainings",
    element: Trainings,
    icon: PresenterRegular,
    titleId: "Trainings",
}

const ReportingView: AppViewData = {
    id: AppView.Reporting,
    path: "/reporting",
    element: Reporting,
    icon: ChartMultipleRegular,
    titleId: "Reporting",
}

const NotificationsView: AppViewData = {
    id: AppView.Notifications,
    path: "/notifications",
    element: Notifications,
    icon: MegaphoneRegular,
    titleId: "Notifications",
}

const DashboardView: AppViewData = {
    id: AppView.Dashboard,
    path: "/dashboard",
    element: Dashboard,
    icon: () => <UserThumbnail size={"small"}/>,
    titleId: "MyDashboard",
}

export const AppViews: Record<AppView, AppViewData> = {
    [AppView.Home]: HomeView,
    [AppView.BestPractices]: BestPracticesView,
    [AppView.Trainings]: TrainingsView,
    [AppView.Reporting]: ReportingView,
    [AppView.Notifications]: NotificationsView,
    [AppView.Dashboard]: DashboardView,
}