import React, {memo} from "react";
import {Avatar, CallIcon, ChatIcon, Skeleton, Text} from "@fluentui/react-northstar";
import {Props} from "./UserThumbnail.types";
import {useUserCache} from "hooks/cache/useUsersCache";
import "./UserThumbnail.styles.scss";
import {CompareModule} from "modules/Compare.module";
import {SizeValue} from "@fluentui/react-northstar/dist/es/utils/commonPropTypes";
import {PopupMenuButton} from "components/buttons/PopupMenuButton/PopupMenuButton";
import {translations} from "translations";
import {useMsTeamsSelector} from "hooks/useMsTeams";
import {UserModule} from "modules/User.module";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {GraphUserData} from "common";

export const UserThumbnail = memo((props: Props) => {
    const {isLightTheme, userId} = useMsTeamsSelector("isLightTheme", "userId");
    const {user} = useUserCache(props.userId ?? userId);

    const sizeInPixels = getSizeInPixels(props.size);

    if (!user) return (
        <Skeleton animation={"pulse"} className={"no-shrink"} styles={{width: sizeInPixels, height: sizeInPixels}}>
            <Skeleton.Avatar size={props.size}/>
        </Skeleton>
    )

    const isCurrentUser = user?.id.toString() === userId;
    const hideTooltip = props.hideCommunications || isCurrentUser;

    const avatar = (
        <Avatar
            size={props.size}
            className={"no-shrink " + (hideTooltip ? "" : "cursor-pointer")}
            name={user?.displayName ?? ""}
            image={user?.picture}
        />
    )

    if (hideTooltip) return avatar;

    return (
        <PopupMenuButton
            trigger={avatar}
            position={"below"}
            menu={generateMenuItems(user, isLightTheme)}
        />
    )
}, CompareModule.areObjectsEqual);

const getSizeInPixels = (size: SizeValue) => {
    let pixels = 0;
    switch (size) {
        case "smallest":
            pixels = 20;
            break;
        case "smaller":
            pixels = 24;
            break;
        case "small":
            pixels = 28;
            break;
        case "medium":
            pixels = 32;
            break;
        case "large":
            pixels = 44;
            break;
        case "larger":
            pixels = 64;
            break;
        case "largest":
            pixels = 96;
            break;
    }
    return pixels + "px";
}

const generateMenuItems = (user: Immutable<GraphUserData> | undefined, isLightTheme: boolean) => [
    {
        key: "name",
        content: (
            <Text
                styles={{color: isLightTheme ? "black" : "white"}}
                content={user?.displayName}
                weight={"semibold"}
            />
        ),
        disabled: true,
    },
    {
        key: "chat",
        icon: <ChatIcon outline/>,
        content: translations.get("Chat"),
        onClick: () => UserModule.startChat(user?.userPrincipalName)
    },
    {
        key: "call",
        icon: <CallIcon outline/>,
        content: translations.get("Call"),
        onClick: () => UserModule.startCall(user?.userPrincipalName)
    }
]