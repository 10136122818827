import {Immutable} from "@witivio_teamspro/use-reducer";
import {Guid, LaunchpadApp} from "common";
import {useCRUDForm} from "./generic/useCRUDForm";
import {useLaunchpadAppCache} from "../cache/launchpadAppCache";
import {ImagePicker, Input} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {useLayoutEffect} from "react";

export const useAppForm = (appId: Immutable<Guid> | undefined) => {
    const {app, upsertItem, deleteItem} = useLaunchpadAppCache(appId);

    useLayoutEffect(() => {
        form.reset({name: app?.name, link: app?.link, icon: app?.iconUrl});
    }, [app]);

    const form = useCRUDForm({
        oldData: app,
        formItems: {
            name: Input({
                inputMode: "text",
                required: true,
                label: translations.get("Name"),
                placeholder: translations.get("EnterName"),
            }),
            link: Input({
                inputMode: "url",
                required: true,
                label: translations.get("Link"),
                placeholder: translations.get("EnterLink"),
            }),
            icon: ImagePicker({
                required: true,
                label: translations.get("Icon"),
                buttonLabel: translations.get("SelectIcon"),
                maxSizeInKo: 30,
            })
        },
        upsertData: async (oldData, formData) => {
            const app = generateApp(oldData, formData);
            await upsertItem(app);
        },
        removeData: deleteItem,
        removeTitle: translations.get("DeleteApp"),
        removeSubtitle: translations.get("DeleteAppConfirmation", {name: app?.name ?? ""}),
    });

    return {
        ...form,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateApp = (
    oldData: Immutable<LaunchpadApp> | undefined,
    formData: ReturnType<typeof useAppForm>["state"]["data"],
): Immutable<LaunchpadApp> | undefined => {
    const {name, icon, link} = formData;
    return {
        id: (oldData?.id ?? "") as Guid,
        name: name ?? "",
        iconUrl: icon ?? "",
        link: link ?? "",
    }
}