import {Immutable} from "@witivio_teamspro/use-reducer";
import {AxiosError} from "axios";
import {Notification, NotificationRequest} from "common";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";

const getTemplates = async (): Promise<Immutable<Array<Notification>>> => {
    try {
        const response = await AxiosConfig.instance.get("/notifications/templates");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get notifications templates", error as AxiosError);
        return [];
    }
}

const addTemplate = async (template: Notification): Promise<Immutable<Array<Notification>>> => {
    try {
        const response = await AxiosConfig.instance.post("/notifications/templates", template);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't add notification template", error as AxiosError);
        return [];
    }
}

const deleteTemplate = async (id: string): Promise<Immutable<Array<Notification>>> => {
    try {
        const response = await AxiosConfig.instance.delete(`/notifications/templates/${id}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't delete notification template", error as AxiosError);
        return [];
    }
}

const send = async (request: NotificationRequest): Promise<boolean> => {
    try {
        await AxiosConfig.instance.post(`/notifications/send`, request);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't send notification", error as AxiosError);
        return false;
    }
}

export const NotificationsApi = {
    getTemplates,
    addTemplate,
    deleteTemplate,
    send,
}