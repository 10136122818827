import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./DocumentButton.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./DocumentButton.reducer";
import {CompareModule} from "modules/Compare.module";
import "./DocumentButton.styles.scss";
import {
    AcceptIcon,
    ClipboardCopiedToIcon,
    DownloadIcon,
    EditIcon,
    EmailIcon,
    EyeIcon,
    Flex,
    MoreIcon,
    ShareAltIcon,
    TeamsIcon,
    Text,
    TrashCanIcon
} from "@fluentui/react-northstar";
import {ItemButton} from "../ItemButton/ItemButton";
import {FileModule} from "modules/File.module";
import {translations} from "translations";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {PopupMenuButton} from "../PopupMenuButton/PopupMenuButton";

export const DocumentButton = memo((props: Props): ReactElement | null => {
    const {filePreviewDialog, renameFileDialog, confirmDeleteDialog} = useDialogContext();
    const [, dispatch] = useMagicReducer(reducer({
        props, filePreviewDialog, renameFileDialog, confirmDeleteDialog
    }), initialState);

    const hideActions = props.file?.isArchived || (!props.onRemove && !props.onRename);

    const menuItems = useMemo(() => [
        ...(props.file?.isArchived ? [] : [
            {
                key: "preview",
                content: translations.get("Preview"),
                icon: <EyeIcon outline/>,
                onClick: dispatch("openPreview"),
            }
        ]),
        ...(props.canDownload === false ? [] : [{
            key: "download",
            content: translations.get("Download"),
            icon: <DownloadIcon outline/>,
            onClick: dispatch("downloadFile"),
        }]),
        ...((props.file?.isArchived || props.canShare === false) ? [] : [
            {
                key: "share",
                content: translations.get("Share"),
                icon: <ShareAltIcon outline/>,
                menu: [
                    {
                        key: "email",
                        content: translations.get("Email"),
                        icon: <EmailIcon outline/>,
                        onClick: dispatch("shareFileByEmail"),
                    },
                    {
                        key: "copylink",
                        content: translations.get("CopyLink"),
                        icon: <ClipboardCopiedToIcon outline/>,
                        onClick: dispatch("copyFileLink"),
                    },
                    {
                        key: "teams",
                        content: "Teams",
                        icon: <TeamsIcon/>,
                        onClick: dispatch("shareFileOnTeams"),
                    }
                ]
            }
        ]),
        ...(hideActions ? [] : [
            {
                key: "divider-1",
                kind: "divider",
                styles: {margin: "5px 0"},
            },
            {
                key: "rename",
                content: translations.get("Rename"),
                icon: <EditIcon outline/>,
                onClick: dispatch("renameFile"),
            },
            {
                key: "remove",
                content: translations.get("Remove"),
                icon: <TrashCanIcon styles={{color: "red !important"}} outline/>,
                onClick: dispatch("removeFile"),
                styles: {color: "red !important"}
            }
        ])
    ], [props.file, hideActions]);

    const DocumentIcon = FileModule.getFileIcon(props.file?.fileType);

    return (
        <ItemButton
            fluid={props.fluid}
            key={"" + props.file?.id + props.file?.driveId}
            menuItems={menuItems}
            skeleton={!props.file}
            primary={props.toggle}
            onClick={props.onClick && (() => props.onClick?.(props.file!))}
            content={
                <Flex fill vAlign={"center"} gap={"gap.small"} title={props.file?.name}>
                    {props.toggle && <AcceptIcon/>}
                    <DocumentIcon size={"large"}/>
                    <Flex fill column styles={{gap: "3px"}} vAlign={"center"} className={"overflow-hidden"}>
                        <Text truncated align={"start"} content={props.file?.name} weight={"semibold"}/>
                    </Flex>
                    {props.onClick && <PopupMenuButton menu={menuItems} trigger={<MoreIcon outline/>}/>}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);