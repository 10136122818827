import {useEffect, useState} from "react";
import {UserReportCacheActions} from "./userReportCache";
import {ItemData, ItemDataType} from "common";
import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";
import {TrainingApi} from "../../apis/Training/TrainingApi";
import {queryClient} from "../../index";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {bestPracticesCacheKey} from "./bestPracticesCache";
import {trainingsCacheKey} from "./trainingsCache";
import {ObjectModule} from "../../modules/Object.module";

const getItemLikes = (type: ItemDataType | undefined, itemId: string | undefined): Immutable<Array<string>> => {
    let item: Immutable<ItemData> | undefined = undefined;
    switch (type) {
        case ItemDataType.BestPractice:
            const bestPractices = queryClient.getQueryData<Immutable<Array<ItemData>>>([bestPracticesCacheKey]);
            item = bestPractices?.find(item => item.id === itemId);
            break;
        case ItemDataType.Training:
            const trainings = queryClient.getQueryData<Immutable<Array<ItemData>>>([trainingsCacheKey]);
            item = trainings?.find(item => item.id === itemId);
            break;
    }
    return item?.likes ?? [];
}

const isItemLiked = (userUpn: string | undefined, type: ItemDataType | undefined, itemId: string | undefined) => {
    if (!userUpn) return false;
    const likes = getItemLikes(type, itemId);
    return likes.includes(userUpn.toLowerCase());
}

const updateItemLikes = (type: ItemDataType | undefined, itemId: string | undefined, likes: Array<string>) => {
    if (type === undefined || itemId === undefined) return;
    switch (type) {
        case ItemDataType.BestPractice:
            const bestPractices = ObjectModule.deepClone(queryClient.getQueryData<Immutable<Array<ItemData>>>([bestPracticesCacheKey]));
            if (!bestPractices) return;
            const bestPractice = bestPractices.find(item => item.id === itemId);
            if (!bestPractice) return;
            bestPractice.likes = likes;
            queryClient.setQueryData([bestPracticesCacheKey], bestPractices);
            break;
        case ItemDataType.Training:
            const trainings = ObjectModule.deepClone(queryClient.getQueryData<Immutable<Array<ItemData>>>([trainingsCacheKey]));
            if (!trainings) return;
            const training = trainings.find(item => item.id === itemId);
            if (!training) return;
            training.likes = likes;
            queryClient.setQueryData([trainingsCacheKey], trainings);
            break;
    }
}

const toggleLike = (
    setIsLiked: (value: boolean) => void,
    userUpn: string | undefined,
    type: ItemDataType | undefined,
    itemId: string | undefined
) => async () => {
    if (!userUpn) return;
    const likes = getItemLikes(type, itemId);
    let newLikes = [...likes ?? []];
    const hasBeenLiked = !likes?.includes(userUpn);
    if (hasBeenLiked) {
        if (!newLikes.includes(userUpn)) newLikes.push(userUpn);
        UserReportCacheActions.incrementReportValue("likes");
        setIsLiked(true);
    } else {
        newLikes = newLikes.filter(upn => upn !== userUpn);
        UserReportCacheActions.decrementReportValue("likes");
        setIsLiked(false);
    }
    updateItemLikes(type, itemId, newLikes);
    switch (type) {
        case ItemDataType.BestPractice:
            if (hasBeenLiked) await BestPracticeApi.like(itemId!);
            else await BestPracticeApi.unlike(itemId!);
            break;
        case ItemDataType.Training:
            if (hasBeenLiked) await TrainingApi.like(itemId!);
            else await TrainingApi.unlike(itemId!);
            break;
    }
}

export const useItemLikeCache = (userUpn: string | undefined, type: ItemDataType | undefined, itemId: string | undefined) => {
    const [isLiked, setIsLiked] = useState<boolean>(false);

    useEffect(() => {
        setIsLiked(isItemLiked(userUpn, type, itemId));
    }, [userUpn, type, itemId]);

    return {
        isLiked,
        toggleLike: toggleLike(setIsLiked, userUpn, type, itemId),
    }
}