import {UserSettingsDialogStep, UserSettingsStepData} from "../UserSettingsDialog.types";
import {useBestPracticesCache} from "../../../../hooks/cache/bestPracticesCache";
import {useEffect, useMemo, useState} from "react";
import {BestPracticesModule} from "../../../../modules/BestPractices.module";
import {translations} from "../../../../translations";
import {useUserDataCache} from "../../../../hooks/cache/userDataCache";

export const useTopicsStep = (): UserSettingsStepData => {
    const {userData} = useUserDataCache();
    const {bestPractices} = useBestPracticesCache();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        if (!userData?.interests?.topics) return;
        setSelectedItems([...userData.interests?.topics]);
    }, [userData?.interests?.topics]);

    const items = useMemo(() => BestPracticesModule.getTopics(bestPractices), [bestPractices]);

    const maxSelectedItems = 5;

    return {
        step: UserSettingsDialogStep.Topics,
        isValid: selectedItems.length > 0 && selectedItems.length <= maxSelectedItems,
        items,
        selectedItems,
        title: translations.get("UserSettingsTopicsTitle"),
        subtitle: translations.get("UserSettingsTopicsSubtitle"),
        toggleItem: (item) => setSelectedItems((prev) => prev.includes(item) ? prev.filter((t) => t !== item) : [...prev, item]),
        maxSelectedItems,
        searchPlaceholder: translations.get("SearchTopic"),
        selectedItemsLabel: translations.get("YouHaveSelectedXTopics", {count: selectedItems.length + "/" + maxSelectedItems}),
    }
}