import {useAllCRUDCache, UseCRUDCacheBaseProps, useSingleCRUDCache} from "./generic/useCRUDCache";
import {NewsApi} from "../../apis/News/NewsApi";
import {Guid, News} from "common";
import {Immutable} from "@witivio_teamspro/use-reducer";

const newsCacheKey = 'news';

const baseConfig: UseCRUDCacheBaseProps<News> = {
    cacheKey: newsCacheKey,
    ...NewsApi,
}

export const useAllNewsCache = () => {
    const data = useAllCRUDCache({...baseConfig, fetchAll: NewsApi.getAll});

    return {
        ...data,
        news: data.items,
    }
}

export const useNewsCache = (id: Immutable<Guid> | undefined) => {
    const data = useSingleCRUDCache({...baseConfig, id});

    return {
        ...data,
        news: data.item,
    }
}