"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportEventType = void 0;
var ReportEventType;
(function (ReportEventType) {
    ReportEventType[ReportEventType["Query"] = 0] = "Query";
    ReportEventType[ReportEventType["Session"] = 1] = "Session";
    ReportEventType[ReportEventType["ItemOpened"] = 2] = "ItemOpened";
    ReportEventType[ReportEventType["TopicSelected"] = 3] = "TopicSelected";
    ReportEventType[ReportEventType["ItemShared"] = 4] = "ItemShared";
})(ReportEventType || (exports.ReportEventType = ReportEventType = {}));
