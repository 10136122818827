"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeeplinkContextType = void 0;
var DeeplinkContextType;
(function (DeeplinkContextType) {
    DeeplinkContextType["Notification"] = "notification";
    DeeplinkContextType["NewsUpdated"] = "newsUpdated";
    DeeplinkContextType["ShowItem"] = "showItem";
    DeeplinkContextType["Top3Content"] = "top3Content";
    DeeplinkContextType["Comment"] = "comment";
})(DeeplinkContextType || (exports.DeeplinkContextType = DeeplinkContextType = {}));
