import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./NewsDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./NewsDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {
    AddIcon,
    Button,
    Divider,
    FilesVideoIcon,
    Flex,
    Menu,
    RetryIcon,
    SaveIcon,
    TrashCanIcon
} from "@fluentui/react-northstar";
import "./NewsDialog.styles.scss";
import {useAllNewsCache} from "../../../hooks/cache/useNewsCache";
import {ScreenModule} from "../../../modules/Screen.module";
import {useEditMessageForm} from "../../../hooks/forms/useEditMessageForm";
import {translations} from "../../../translations";
import {useAllCommunitiesCache} from "../../../hooks/cache/useCommunityCache";
import {NewsDialogButton} from "./NewsDialogButton/NewsDialogButton";
import {CommunityModule} from "../../../modules/Community.module";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {VideoAddRegular} from "@fluentui/react-icons";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";

export const NewsDialog = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog, filePickerDialog} = useDialogContext();
    const {isAdmin} = useUserRolesCache();
    const {userData} = useUserDataCache();
    const {news, upsertItem: upsertNews} = useAllNewsCache();
    const {communities} = useAllCommunitiesCache();
    const dialogRef = useMagicReducerRef(Dialog);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const reducerConfig = {props, dialogRef, news, upsertNews, confirmCancelChangesDialog, filePickerDialog, isAdmin};
    const [state, dispatch] = useMagicReducer(reducer(reducerConfig), initialState, props.externalRef);

    const visibleCommunityNews = useMemo(() => {
        return news?.filter(n =>
            n.community !== CommunityModule.generalNewsId &&
            n.show &&
            userData?.interests?.channels.includes(n.community)
        ).map(n => n.community) ?? [];
    }, [news, userData?.interests?.channels]);

    const menuItems = useMemo(() => {
        return CommunityModule.generateMenuItems(isAdmin ? communities : visibleCommunityNews);
    }, [communities, visibleCommunityNews, isAdmin]);

    const activeIndex = menuItems?.findIndex(item => item.id === state.selectedCommunity);

    const activeNews = news?.find(n => n.community === state.selectedCommunity);

    const {messageForm, buttonsForm} = useEditMessageForm(activeNews, state.buttons, state.isSaving);

    const currentMessage = messageForm.state.data.message ?? "";

    useEffect(function onCurrentMessageChange() {
        if (!dialogRef.state?.isOpen) return;
        dispatch("saveDraftMessage")(currentMessage);
    }, [currentMessage]);

    const hasVideoChanged = state.selectedVideo?.itemId !== activeNews?.selectedVideo?.itemId;

    const canSave = !state.isSaving && messageForm.isValid && buttonsForm.isValid
        && (messageForm.hasChanged || buttonsForm.hasChanged || hasVideoChanged);

    const canAddButton = !state.isSaving && state.buttons.length < 5;

    const canRetrieveDraft = !state.isSaving && !!state.draftMessage && state.draftMessage.community === state.selectedCommunity;

    const menu = (
        <Menu
            className={"news-dialog-menu"}
            activeIndex={activeIndex}
            items={menuItems}
            underlined
            primary
            onItemClick={dispatch("switchCommunity", canSave)}
        />
    )

    const videoButton = (
        <Flex gap={"gap.smaller"}>
            <Button
                fluid
                icon={!state.selectedVideo ?
                    <VideoAddRegular className={"custom-icon"}/> :
                    <FilesVideoIcon size={"large"}/>
                }
                content={state.selectedVideo?.name || translations.get("SelectAVideo")}
                onClick={dispatch("handleSelectVideo")}
                primary={!!state.selectedVideo}
                disabled={state.isSaving}
            />
            {state.selectedVideo &&
                <Button
                    styles={{marginRight: "-6px"}}
                    iconOnly
                    text
                    icon={<TrashCanIcon outline/>}
                    onClick={dispatch("handleClearVideo")}
                    disabled={state.isSaving}
                />
            }
        </Flex>
    )

    const buttons = (
        <Flex fill column className={"overflow-hidden"} gap={"gap.small"}>
            <Flex className={"no-shrink"}>
                <Button
                    fluid icon={<AddIcon outline/>}
                    content={translations.get("AddButton")}
                    onClick={dispatch("handleAddButton")}
                    disabled={!canAddButton}
                />
            </Flex>
            <Flex fill column className={"overflow-scroll"} gap={"gap.small"}>
                {state.buttons.map((b, index) => (
                    <NewsDialogButton
                        key={b.id}
                        button={b}
                        label={translations.get("CustomButton") + " " + (index + 1)}
                        titleInput={buttonsForm.formItems[b.id + "_title"]}
                        linkInput={buttonsForm.formItems[b.id + "_link"]}
                        onChangeColor={dispatch("handleSetButtonColor", b.id)}
                        onDelete={dispatch("handleDeleteButton", b.id)}
                        disabled={state.isSaving}
                    />
                ))}
            </Flex>
        </Flex>
    );

    const footer = (
        <Flex className={"w-100"} space={"between"} vAlign={"center"}>
            {messageForm.formItems["visible"]}
            <Button
                primary icon={<SaveIcon size={"large"} outline/>}
                content={translations.get("Save")}
                onClick={dispatch("handleSave", {messageForm, buttonsForm})}
                disabled={!canSave}
                loading={state.isSaving}
            />
        </Flex>
    )

    return (
        <Dialog
            title={translations.get("MessageOfTheDay")}
            width={1000}
            noPadding
            externalRef={dialogRef}
            closeOnOutsideClick={false}
            onClose={dispatch("removeDraftMessage")}
            content={
                <Flex fill column gap={"gap.medium"} className={"news-dialog"}>
                    {menu}
                    <Flex fill column={isSmallScreen} gap={"gap.medium"} className={"overflow-hidden"}
                          styles={{height: isSmallScreen ? "100%" : "330px", padding: "0 25px 10px 25px"}}>
                        <Flex fill column gap={"gap.small"}>
                            {canRetrieveDraft &&
                                <Flex>
                                    <Button
                                        icon={<RetryIcon outline/>} fluid
                                        content={translations.get("RetrieveDraft")}
                                        onClick={dispatch("handleRetrieveDraft", messageForm)}
                                    />
                                </Flex>
                            }
                            <Flex fill column gap={"gap.small"}>
                                <Flex fill>
                                    {messageForm.formItems["message"]}
                                </Flex>
                                {videoButton}
                            </Flex>
                        </Flex>
                        <Divider vertical={!isSmallScreen}/>
                        {buttons}
                    </Flex>
                </Flex>
            }
            footer={footer}
        />
    )
}, CompareModule.areObjectsEqual);