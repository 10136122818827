import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import './const/ThemeCustomizations.styles.scss';
import {App} from './App';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {UseFormSettingsProvider} from "@witivio_teamspro/northstar-form";

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    window.document.getElementById('root') as HTMLElement
);

root.render(
    <UseFormSettingsProvider>
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </UseFormSettingsProvider>
);