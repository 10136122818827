import {useQuery} from "@tanstack/react-query";
import {UserApi} from "../../apis/User/UserApi";

const usersLocationsCacheKey = 'users-locations';

export const useUsersLocationsCache = () => {
    const {data: locations} = useQuery({
        queryKey: [usersLocationsCacheKey],
        queryFn: UserApi.getUsersLocations,
        staleTime: Infinity,
    });

    return {locations};
}