import {State} from "./Reporting.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {ReportingApi} from "../../apis/Reporting/ReportingApi";
import moment from "moment";
import {ItemData} from "common";
import {DateRange} from "../../components/others/DateRangeSelector/DateRangeSelector.types";

export const initialState: State = {
    data: undefined,
    dateRange: {
        startDate: moment().startOf("week").add(-1, "day").toISOString(false),
        endDate: moment().endOf("week").add(-1, "day").toISOString(false),
    },
    isDownloading: false,
}

export const reducer = (
    bestPractices: Immutable<ItemData[]> | undefined,
    trainings: Immutable<ItemData[]> | undefined,
) => ({
    fetchReports: async ({state, setState}) => {
        if (!!state.data) setState({data: undefined});
        const data = await ReportingApi.getReport(state.dateRange.startDate, state.dateRange.endDate);
        setState({data});
    },
    handleDateRangeChange: async (reducerData, [, range]: [string, Immutable<DateRange>]) => {
        const {state, setState} = reducerData;
        if (state.dateRange.startDate === range.startDate && state.dateRange.endDate === range.endDate) return;
        setState({data: undefined, dateRange: {startDate: range.startDate, endDate: range.endDate}});
        await reducer(bestPractices, trainings).fetchReports(reducerData);
    },
    downloadReport: async ({state, setState}) => {
        setState({isDownloading: true});
        const blob = await ReportingApi.downloadReport(state.dateRange.startDate, state.dateRange.endDate);
        if (!blob) return;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const startDate = moment(state.dateRange.startDate).startOf("day").format("YYYY-MM-DD");
        const endDate = moment(state.dateRange.endDate).startOf("day").format("YYYY-MM-DD");
        a.download = `STARVOICE_Report_${startDate}${startDate === endDate ? "" : "_to_" + endDate}.xlsx`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setState({isDownloading: false});
    },
}) satisfies MagicReducerObject<State>;