import React, {memo, ReactElement} from "react";
import {Props} from "./WelcomeDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./WelcomeDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Button, Flex, Image, Loader, Text} from "@fluentui/react-northstar";
import "./WelcomeDialog.styles.scss";
import {UserThumbnail} from "../../others/UserThumbnail/UserThumbnail";
import {translations} from "../../../translations";
import {useMsTeamsSelector} from "../../../hooks/useMsTeams";
import StarvoiceLogo from "../../../assets/images/starvoice_logo.svg";
import {ArrowRightFilled} from "@fluentui/react-icons";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {useBestPracticesCache} from "../../../hooks/cache/bestPracticesCache";

export const WelcomeDialog = memo((props: Props): ReactElement | null => {
    const {bestPractices} = useBestPracticesCache();
    const {userName} = useMsTeamsSelector("userName");
    const dialogRef = useMagicReducerRef(Dialog);
    const {userSettingsDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        userSettingsDialog
    }), initialState, props.externalRef);

    const title = translations.get("Welcome") + " " + userName.split(" ")[0];

    return (
        <Dialog
            externalRef={dialogRef}
            width={600}
            height={600}
            noPadding
            className={"welcome-dialog"}
            closeOnOutsideClick={false}
            content={
                <Flex fill column vAlign={"center"} hAlign={"center"} className={"welcome-dialog-content"}>
                    <Image className={"welcome-dialog-logo"} src={StarvoiceLogo}/>
                    <Flex column vAlign={"center"} hAlign={"center"} gap={"gap.small"}>
                        <Flex className={"welcome-dialog-thumbnail"}>
                            <UserThumbnail userId={undefined} size={"largest"}/>
                        </Flex>
                        <Text color={"white"} size={"largest"} weight={"semibold"} content={title}/>
                        <Text
                            color={"white"}
                            size={"large"}
                            weight={"semibold"}
                            align={"center"}
                            content={translations.get("WelcomeDialogSubtitle")}
                            className={"welcome-dialog-subtitle"}
                        />
                    </Flex>
                    {!bestPractices ?
                        <Flex style={{height: "50px", marginTop: "10px"}}>
                            <Loader secondary/>
                        </Flex>
                        :
                        <Button
                            className={"welcome-dialog-button"}
                            content={translations.get("GetStarted")}
                            icon={<ArrowRightFilled fontSize={100}/>}
                            iconPosition={"after"}
                            onClick={dispatch("start")}
                        />
                    }
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);