import React, {memo, ReactElement} from "react";
import {Props} from "./Publish.types";
import {CompareModule} from "modules/Compare.module";
import {Flex, Image, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../../translations";
import BPPublishImage from "../../../../../assets/images/bp_publish.webp";

export const Publish = memo((props: Props): ReactElement | null => {
    return (
        <Flex fill className={"share-item-dialog-home-view"} style={{gap: "20px"}} vAlign={"center"} hAlign={"center"}>
            <Image fluid src={BPPublishImage} alt={"Best Practice Publish"}/>
            <Flex fill column style={{gap: "15px"}} vAlign={"center"}>
                <Text className={"primary-color"} weight={"semibold"} styles={{fontSize: "2.5rem"}}
                      content={translations.get("ThankYou")}/>
                <Text dangerouslySetInnerHTML={{__html: translations.get("ShareItemDialogEndTitle1")}}/>
                <Text dangerouslySetInnerHTML={{__html: translations.get("ShareItemDialogEndTitle2")}}/>
            </Flex>
        </Flex>
    );
}, CompareModule.areObjectsEqual);