import {MenuItemData, Props, State} from "./SideMenu.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {AppView} from "../../../../types/AppView/AppView";
import {AppViews} from "../../../../const/AppViews";
import {translations} from "../../../../translations";
import {getAppConfiguration} from "../../../../services/ConfigurationService/ConfigurationService";

export const initialState = (props: Props): State => ({
    selectedView: props.defaultSelectedView,
    show: false,
    hideBlurMask: true,
});

export const reducer = (props: Props) => ({
    selectItem: ({setState}, [id]: [MenuItemData["id"]]) => {
        setState({selectedView: id});
        props.onItemSelected(id);
    },
    handleOpen: ({setState}) => {
        setState({show: true, hideBlurMask: false});
    },
    handleClose: ({setState}) => {
        setState({show: false});
        setTimeout(() => setState({hideBlurMask: true}), 250);
    },
    handleItemSelected: (reducerData, _, id: MenuItemData["id"]) => {
        reducer(props).selectItem(reducerData, [id]);
    },
    openHelpPage: () => {
        window.open(getAppConfiguration()?.helpPageUrl, "_blank");
    }
}) satisfies MagicReducerObject<State>;

export const generateMenuItems = () => {
    const items = [AppView.Home, AppView.Dashboard, AppView.BestPractices, AppView.Trainings];
    return items.map((viewId): MenuItemData => {
        const view = AppViews[viewId];
        return {
            id: view.id,
            icon: view.icon!,
            path: view.path,
            title: translations.get(view.titleId)
        }
    });
}