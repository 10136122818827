import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./Searchbar.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Searchbar.reducer";
import "./Searchbar.styles.scss";
import {ComponentSlotStyle, FilterIcon, Flex, Input, SearchIcon, Text} from "@fluentui/react-northstar";
import {Filter} from "../Filter/Filter";
import {ScreenModule} from "../../../modules/Screen.module";
import {translations} from "../../../translations";

export const Searchbar = memo((props: Props): ReactElement | null => {
    const filterRef = useMagicReducerRef(Filter);
    const [state, dispatch] = useMagicReducer(reducer(props, filterRef), initialState(props), props.externalRef);

    useEffect(() => {
        const timeout = setTimeout(dispatch("unblockClearing"), 300);
        return () => clearTimeout(timeout);
    }, []);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const className = [
        "search-bar",
        props.className
    ].filter(i => i).join(" ");

    const styles = props.styles ?? {};

    return (
        <Flex className={className} styles={styles}>
            <Input
                className={"search-bar-input"}
                inverted
                styles={props.styles as ComponentSlotStyle}
                autoComplete={"off"}
                value={state.query}
                onChange={dispatch("handleInput")}
                fluid
                placeholder={props.placeholder}
                iconPosition={"start"}
                icon={<SearchIcon styles={{color: "darkgray"}} outline/>}
            />
            <Filter
                id={props.id + "filter"}
                externalRef={filterRef}
                items={props.filterItems}
                onItemChecked={dispatch("handleFilterChanged")}
                renderTrigger={() => (
                    <Flex className={"search-bar-filter-button"}>
                        <FilterIcon/>
                        {!isSmallScreen && <Text content={translations.get("Filter")}/>}
                    </Flex>
                )}
            />
        </Flex>
    )
}, (prevProps, nextProps) => (
    prevProps.styles === nextProps.styles &&
    prevProps.onSearch === nextProps.onSearch &&
    prevProps.throttling === nextProps.throttling &&
    prevProps.externalRef === nextProps.externalRef &&
    prevProps.className === nextProps.className &&
    prevProps.placeholder === nextProps.placeholder
));