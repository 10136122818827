import {memo, ReactElement, useMemo} from "react";
import {Props} from "./LaunchpadAppsListDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./LaunchpadAppsListDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AddIcon, Button, Flex, Loader, SearchIcon} from "@fluentui/react-northstar";
import "./LaunchpadAppsListDialog.styles.scss";
import {useAllLaunchpadAppsCache} from "../../../hooks/cache/launchpadAppCache";
import {AppList} from "../../lists/AppList/AppList";
import {translations} from "../../../translations";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {Input, useForm} from "@witivio_teamspro/northstar-form";

export const LaunchpadAppsListDialog = memo((props: Props): ReactElement | null => {
    const {launchpadAppDialog} = useDialogContext();
    const {apps, isLoading, deleteItem} = useAllLaunchpadAppsCache();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useForm({
        items: {
            search: Input({
                inputMode: "text",
                placeholder: translations.get("SearchAnApp"),
                inputIcon: <SearchIcon style={{color: "darkgray"}} outline/>
            })
        }
    });

    const searchQuery = form.state.data.search;

    const filteredApps = useMemo(() => {
        if (!searchQuery) return apps;
        return apps?.filter(app => app.name.toLowerCase().includes(searchQuery?.toLowerCase()));
    }, [apps, searchQuery]);

    const addButton = useMemo(() => (
        <Button
            primary
            content={translations.get("AddApplication")}
            icon={<AddIcon outline/>}
            onClick={launchpadAppDialog.dispatch("open")}
            className={"no-shrink"}
        />
    ), []);

    return (
        <Dialog
            externalRef={dialogRef}
            width={800} height={500}
            title={translations.get("ManageApps")}
            content={
                <Flex className={"overflow-hidden"} fill column vAlign={"center"} hAlign={"center"} gap={"gap.small"}>
                    <Flex className={"w-100 no-shrink"} gap={"gap.small"}>
                        {form.formItems.search}
                        {addButton}
                    </Flex>
                    {isLoading ?
                        <Loader/>
                        :
                        <Flex fill style={{marginTop: "-10px"}}>
                            <AppList apps={filteredApps} onRemoveApp={deleteItem}/>
                        </Flex>
                    }
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);