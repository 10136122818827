import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDeeplinkContext} from "./useDeeplinkContext";
import {useConfigurationCache} from "./cache/useConfigurationCache";
import {useFormSettingsUpdater} from "@witivio_teamspro/northstar-form";
import {AxiosConfig} from "../apis/AxiosConfig/AxiosConfig";
import ImageLoader from "assets/images/image-loader.webp";
import {useMsTeams} from "./useMsTeams";
import {useAuth} from "./useAuth";
import {useGraphScopes} from "./useGraphScopes";
import {TokenApi} from "../apis/Token/TokenApi";
import useUserActivityTimer from "./useUserActivityTimer";
import {DeeplinkContextType, Guid, GuidModule} from "common";
import {HistoryService} from "../services/HistoryService/HistoryService.hook";
import {PrepareApi} from "../apis/Prepare/PrepareApi";
import * as microsoftTeams from "@microsoft/teams-js";
import {HostClientType} from "@microsoft/teams-js";
import {ReportingModule} from "../modules/Reporting.module";
import {useUserDataCache} from "./cache/userDataCache";
import {SharepointModule} from "../modules/Sharepoint.module";

export const useAppInitializer = () => {
    const [preparing, setPreparing] = useState(false);
    const {deeplinkContext, clearDeeplinkContext} = useDeeplinkContext();
    const updateUseFormSettings = useFormSettingsUpdater();
    const auth = useAuth();
    const msTeams = useMsTeams();
    const graphScopes = useGraphScopes();
    const {config} = useConfigurationCache();
    const {getActiveSeconds} = useUserActivityTimer();
    const sessionIdRef = React.useRef<string>(GuidModule.generateGUID());
    const {userData} = useUserDataCache(!!auth.data.accessToken);

    useLayoutEffect(function onDeeplinkContext() {
        if (!deeplinkContext || !msTeams.data.loaded) return;
        if (deeplinkContext.type === DeeplinkContextType.Notification) {
            HistoryService.clearAllStates();
            clearDeeplinkContext();
        } else if ([DeeplinkContextType.ShowItem, DeeplinkContextType.Comment].includes(deeplinkContext.type)) {
            SharepointModule.openPageInStageView(deeplinkContext.data ?? "", msTeams.data.fullLocale).then();
            clearDeeplinkContext();
        }
    }, [deeplinkContext, msTeams.data.loaded]);

    useLayoutEffect(function onConfigurationLoaded() {
        if (!config?.clientId || msTeams.data.isTeamsApp === undefined) return;
        auth.initializeAuth({isTeamsApp: msTeams.data.isTeamsApp, clientId: config.clientId}).then();
    }, [config?.clientId, msTeams.data.isTeamsApp]);

    useLayoutEffect(function onAccessTokenRetrieved() {
        if (!auth.data.accessToken || msTeams.data.isTeamsApp === undefined) return;
        msTeams.initialize({isTeamsApp: msTeams.data.isTeamsApp, accessToken: auth.data.accessToken}).then();
    }, [auth.data.accessToken, msTeams.data.isTeamsApp]);

    useLayoutEffect(function onMsTeamsInitialized() {
        if (!msTeams.data.loaded) return;
        updateUseFormSettings({
            locale: msTeams.data.locale,
            onLoadRichTextImage: async (node) => {
                if (node.getAttribute("src")) return;
                node.setAttribute("src", ImageLoader);
                const id = node.getAttribute("data-id");
                if (!id) return;
                const response = await AxiosConfig.instance.get(`/files/${id}/download-url`);
                node.setAttribute("src", response.data);
            },
        });
        graphScopes.initializeScope({
            login: auth.login,
            initArgs: {
                clientId: config?.clientId,
                apiBaseUrl: window.location.origin,
                scopes: config ? config?.graphScopes.split(",") : [],
                inTeams: msTeams.data.isTeamsApp ?? false,
                locale: msTeams.data.locale,
                isWidget: false,
                isOnMobile: msTeams.data.isOnMobile,
                theme: msTeams.data.themeClass,
                tenantId: msTeams.data.tenantId,
            }
        }).then();
    }, [msTeams.data.loaded, msTeams.data.isTeamsApp]);

    useLayoutEffect(function onUserRolesLoaded() {
        if (!userData) return;
        TokenApi.saveToken().then();
    }, [userData]);

    useEffect(function onScopesServiceInitialized() {
        if (!auth.data.accessToken || !config) return;
        if (config?.needToPrepare) {
            setPreparing(true);
            PrepareApi.prepareData().then(() => setPreparing(false));
        }
    }, [auth.data.accessToken, config]);

    useEffect(function onExitApp() {
        if (!msTeams.data.loaded || msTeams.data.isConfiguringApp || msTeams.data.isInMeeting || !userData) return;
        let interval: NodeJS.Timeout | undefined = undefined;
        const handler = handleExitApp(msTeams.data.userId, getActiveSeconds, msTeams.data.hostClientType, sessionIdRef.current);
        if (!msTeams.data.isTeamsIframe) window.addEventListener("beforeunload", handler);
        else if (msTeams.data.isOnMobile) interval = setInterval(handleExitApp(msTeams.data.userId, () => 10, msTeams.data.hostClientType, sessionIdRef.current), 10000);
        else {
            const isTeamsCoreSupported = microsoftTeams.teamsCore.isSupported();
            if (isTeamsCoreSupported) microsoftTeams.teamsCore.registerBeforeUnloadHandler(handler);
            else {
                let prevActiveSeconds = 0;
                interval = setInterval(() => {
                    const totalSeconds = getActiveSeconds();
                    const activeSeconds = totalSeconds - prevActiveSeconds;
                    if (activeSeconds >= 10) {
                        handleExitApp(msTeams.data.userId, () => activeSeconds, msTeams.data.hostClientType, sessionIdRef.current)();
                        prevActiveSeconds = totalSeconds;
                    }
                }, 1000);
            }
        }
        return () => {
            window.removeEventListener("beforeunload", handler);
            if (interval) clearInterval(interval);
        }
    }, [msTeams.data.loaded, userData]);

    return {
        loaded: graphScopes.data.loaded && userData,
        preparing
    }
};

const handleExitApp = (
    userId: Guid,
    getDuration: () => number,
    hostClientType: HostClientType | undefined,
    sessionId: string
) => (): boolean => {
    const duration = getDuration();
    ReportingModule.notifySession(userId, duration, hostClientType, sessionId).then();
    return true;
}