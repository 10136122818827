import {State} from "./RenameFileDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {FileData} from "common";

export const initialState: State = {
    file: undefined,
    callback: undefined,
    isRenaming: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {file, callback}: { file: Immutable<FileData>, callback: State["callback"] }) => {
        setState({...initialState, file, callback});
        config.dialogRef.dispatch("open")();
    },
    rename: async ({state, setState}, _, name: string | number | undefined) => {
        if (!name) return;
        const result = state.callback?.(name + "");
        if (result instanceof Promise) {
            setState({isRenaming: true});
            await result;
        }
        config.dialogRef.dispatch("close")();
    },
    reset: ({setState}, _, resetForm: () => void) => {
        setState({...initialState});
        resetForm();
    }
}) satisfies MagicReducerObject<State>;