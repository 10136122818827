import {useQuery} from "@tanstack/react-query";
import {GraphApi} from "../../apis/Graph/GraphApi";

export const useGraphVideoCache = (fileId: string, driveId: string) => {
    const {data: src} = useQuery({
        queryKey: ["graphVideo", fileId, driveId],
        queryFn: () => GraphApi.getFilePreviewUrl({fileId, driveId}),
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    return {
        src,
    }
}