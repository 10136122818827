import {HomeLogic} from "../Home.interfaces";
import {Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import React from "react";
import {homeViewCacheKey} from "../Home.reducer";
import {Searchbar} from "../../../components/others/Searchbar/Searchbar";
import {AutoWidthMenu} from "../../../components/others/AutoWidthMenu/AutoWidthMenu";

export const Searches = (logic: HomeLogic) => {
    return (
        <Flex column className={"home-view-searches"}>
            <Text
                className={"home-view-content-title"}
                size={"larger"}
                weight={"bold"}
                content={translations.get("Searches")}
            />
            <Text
                className={"home-view-content-subtitle"}
                content={translations.get("HomeViewSearchPartSubtitle")}
            />
            <Searchbar
                id={homeViewCacheKey + "searchbar"}
                throttling={600}
                externalRef={logic.searchBarRef}
                placeholder={translations.get("SearchByKeyword") + "..."}
                onSearch={logic.dispatch("handleSearch")}
                filterItems={logic.filterItems}
            />
            {logic.showSearchResults &&
                <Flex styles={{marginTop: "10px", marginBottom: "-10px"}}>
                    <AutoWidthMenu
                        externalRef={logic.searchesMenuRef}
                        defaultMenuIndex={logic.selectedSearchResultsCategoryIndex}
                        items={logic.searchResultsCategories}
                        onIndexChange={logic.dispatch("handleClickSearchResultsMenuItem")}
                    />
                </Flex>
            }
        </Flex>
    )
}