import {useQuery} from "@tanstack/react-query";
import {CommunityApi} from "../../apis/Community/CommunityApi";

const communityCacheKey = "community";

export const useAllCommunitiesCache = () => {
    const {data: communities, isLoading} = useQuery({
        queryKey: [communityCacheKey],
        queryFn: CommunityApi.getCommunities,
        staleTime: Infinity,
    });

    return {communities, isLoading};
}