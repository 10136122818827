import {State} from "./ItemDialog.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {ItemData} from "common";
import {Dialog} from "../Dialog/Dialog";
import {useItemCommentsCache} from "../../../hooks/cache/commentsCache";

export const initialState: State = {
    isOpen: false,
    hideBlurMask: true,
    item: undefined,
    isPublishing: false,
}

export const reducer = (
    resetForm: () => void,
    dialogRef: MagicReducerRef<typeof Dialog>,
) => ({
    handleOpen: ({setState}, [item]: [Immutable<ItemData>]) => {
        resetForm();
        setState({item, isPublishing: false});
        dialogRef.dispatch("open")();
    },
    handleClose: () => {
        dialogRef.dispatch("close")();
    },
    handlePublish: async (reducerData, _, {comment, publishComment}: {comment: string, publishComment: ReturnType<typeof useItemCommentsCache>["publishComment"]}) => {
        const {setState} = reducerData;
        if (!reducerData.state.item?.id || !comment) return;
        setState({isPublishing: true});
        await publishComment(reducerData.state.item?.type, reducerData.state.item?.id, comment);
        setState({isPublishing: false});
        dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;