import React, {memo, ReactElement, startTransition, useMemo, useReducer} from "react";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./UserProfile.reducer";
import {CompareModule} from "modules/Compare.module";
import "./UserProfile.styles.scss";
import {Button, Flex, Image, Skeleton, Text} from "@fluentui/react-northstar";
import {useUserThumbnailCache} from "hooks/cache/userThumbnailCache";
import {StatItemProps} from "./StatItem/StatItem.interfaces";
import {StatItem} from "./StatItem/StatItem";
import {useBestPracticesCache} from "../../../hooks/cache/bestPracticesCache";
import {useTrainingsCache} from "../../../hooks/cache/trainingsCache";
import {useUserDataCache} from "../../../hooks/cache/userDataCache";
import {useUserReportCache} from "../../../hooks/cache/userReportCache";
import {ItemData, UserReport} from "common";
import {
    ChatRegular,
    EyeRegular,
    LightbulbFilamentRegular,
    PersonSettingsRegular,
    PresenterRegular,
    ThumbLikeRegular,
    TrophyRegular
} from "@fluentui/react-icons";
import {translations} from "../../../translations";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const UserProfile = memo((): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const {bestPractices} = useBestPracticesCache();
    const {trainings} = useTrainingsCache();
    const {userData} = useUserDataCache();
    const {imageUrl} = useUserThumbnailCache(userData?.id);
    const {userReport} = useUserReportCache();
    const {userSettingsDialog} = useDialogContext();

    const [profilePictureLoaded, handleLoadProfilePicture] = useReducer(() => true, false);
    const [backgroundImageLoaded, handleLoadBackgroundImage] = useReducer(() => true, false);

    const statItems = useMemo(() => generateStatItems({
        userReport,
        bestPractices,
        trainings
    }), [userReport, bestPractices, trainings]);

    const backgroundImage = (
        <Flex className={"user-profile-background-image-container"}>
            <Image
                className={"user-profile-background-image"}
                styles={{opacity: backgroundImageLoaded ? 1 : 0}}
                src={"/assets/app_background.jpg"}
                onLoad={withTransition(handleLoadBackgroundImage)}
            />
        </Flex>
    )

    const profilePicture = (
        <Flex className={"user-profile-profile-picture-container"} vAlign={"center"} hAlign={"center"}>
            <Image
                className={"user-profile-profile-picture " + (!imageUrl ? "default-image" : "")}
                styles={{opacity: profilePictureLoaded ? 1 : 0}}
                src={!imageUrl ? "/assets/avatar.svg" : imageUrl}
                onLoad={withTransition(handleLoadProfilePicture)}
            />
        </Flex>
    )

    const content = (
        <Flex fill column className={"user-profile-content"}>
            <Flex column hAlign={"center"}>
                <Text className={"primary-color"} size={"larger"} weight={"bold"} content={userData?.name}/>
                <Text className={"primary-color"} size={"large"} content={userData?.jobTitle}/>
            </Flex>
            <Flex className={"user-profile-stats-grid"}>
                {statItems.map(item => <StatItem {...item}/>)}
            </Flex>
            <Flex fill vAlign={"end"}>
                <Button
                    fluid
                    primary
                    content={translations.get("PersonalizeInterests")}
                    icon={<PersonSettingsRegular className={"custom-icon"}/>}
                    onClick={userSettingsDialog.dispatch("open")}
                />
            </Flex>
        </Flex>
    )

    return (
        <Flex column className={"user-profile"}>
            <Skeleton className={"h-100"} animation={"pulse"}>
                {backgroundImage}
                {profilePicture}
                {content}
            </Skeleton>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const withTransition = (func: () => void) => () => startTransition(func);

const generateStatItems = (config: {
    userReport: Immutable<UserReport | undefined>
    bestPractices: Immutable<ItemData>[] | undefined,
    trainings: Immutable<ItemData>[] | undefined,
}): Array<StatItemProps> => {
    const {userReport, bestPractices, trainings} = config;
    const showSkeleton = !userReport;
    const items: Array<StatItemProps> = [
        {
            key: "best-practices-explored",
            value: userReport?.exploredBestPractices ?? 0,
            subValue: "/" + bestPractices?.length ?? 0,
            description: "Best practices explored",
            icon: LightbulbFilamentRegular,
        },
        {
            key: "trainings-explored",
            value: userReport?.exploredTrainings ?? 0,
            subValue: "/" + trainings?.length ?? "0",
            description: "Trainings explored",
            icon: PresenterRegular,
            iconSize: 45,
        },
        {
            key: "most-active-user-rank",
            value: userReport?.activeUsersRank ?? 0,
            subValue: "/" + userReport?.totalUsers ?? "0",
            description: "Most active users rank",
            icon: TrophyRegular,
        },
        {
            key: "visits-this-month",
            value: userReport?.visits ?? 0,
            description: "Visits this month",
            icon: EyeRegular,
        },
        {
            key: "likes-given",
            value: userReport?.likes ?? 0,
            description: "Likes given on content",
            icon: ThumbLikeRegular,
            iconSize: 45,
        },
        {
            key: "comments-shared",
            value: userReport?.comments ?? 0,
            description: "Comments shared",
            icon: ChatRegular,
            iconSize: 45,
        }
    ]
    items.forEach(item => item.skeleton = showSkeleton);
    return items;
}