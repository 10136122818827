import {HostClientType, UserTeamRole} from "@microsoft/teams-js";
import {ThemePrepared} from "@fluentui/react-northstar";
import {Guid} from "common";

export enum ThemeClass {
    Default = "teamsDefault",
    Dark = "teamsDark",
    HighContrast = "teamsHighContrast",
}

export interface MsTeamsData {
    loaded: boolean,
    locale: string,
    fullLocale: string,
    themeClass: ThemeClass,
    groupId: Guid,
    channelId: string,
    hostClientType: HostClientType | undefined,
    teamId: string,
    teamName: string,
    tenantId: Guid,
    channelName: string,
    userMail: string,
    userName: string,
    userId: Guid,
    isConfiguringApp: boolean,
    isDeletingApp: boolean,
    isOnMobile: boolean,
    isInPersonalApp: boolean,
    subEntityId: string | object,
    isInTaskModule: boolean,
    entityId: string,
    isInMeeting: boolean,
    meetingId: string,
    isInMeetingSidePanel: boolean,
    userRole: UserTeamRole,
    isTouchScreen: boolean,
    isTeamsIframe: boolean,
    isLightTheme: boolean,
    theme: ThemePrepared,
    isTeamsApp: boolean | undefined,
}