import {Immutable, MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./DateRangeSelector.reducer";
import {ReactElement} from "react";

export type DateRange = {
    startDate: string,
    endDate: string,
}

export type State = {
    selectionMode: DateRangeType,
    isCustomEndDateSelected: boolean,
    selectedDate: string,
    selectedRange: DateRange,
    isTodaySelected: boolean,
}

export type Props = {
    externalRef?: MagicReducerExternalRef<typeof reducer>,
    initialDateRange?: DateRange,
    onDateRangeChange?: (date: string, range: Immutable<DateRange>) => void,
    className?: string,
    availableTypes?: DateRangeType[],
    displayAsInput?: boolean,
    disabled?: boolean,
    trigger: (date: string) => ReactElement,
}

export enum DateRangeType {
    Day = 0,
    Week = 1,
    Month = 2,
    WorkWeek = 3,
    Custom = 4,
}