import React, {memo, ReactElement} from "react";
import {Props} from "./ItemButton.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ItemButton.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ItemButton.styles.scss";
import {Button, Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {PopupMenuButton} from "../PopupMenuButton/PopupMenuButton";

export const ItemButton = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const fluid = props.fluid ?? true;

    const className = [
        "item-button",
        fluid && "item-button-fluid",
        props.primary && "item-button-primary",
    ].filter(Boolean).join(" ");

    if (props.skeleton) return (
        <Skeleton animation={"pulse"}>
            <Skeleton.Button className={className} fluid={fluid}/>
        </Skeleton>
    )

    const trigger = (
        <Button
            primary={props.primary ?? false}
            ref={dispatch("buttonRef")}
            fluid={fluid}
            className={className}
            {...(props.onClick && {onClick: props.onClick})}>
            <Flex fill vAlign={"center"} gap={"gap.small"} className={"item-button-content"}>
                {typeof props.content === "string" ?
                    <Text truncated content={props.content} weight={"semibold"}/>
                    :
                    props.content
                }
            </Flex>
        </Button>
    );

    if (props.onClick) return trigger;

    return (
        <PopupMenuButton
            trigger={trigger}
            position={"below"}
            offset={[0, -2]}
            menu={{
                items: props.menuItems ?? [],
                styles: {width: state.buttonWidth + "px"}
            }}
        />
    )
}, CompareModule.areObjectsEqual);