import React, {memo, ReactElement} from "react";
import {Props} from "./TemplateCard.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./TemplateCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./TemplateCard.styles.scss";
import {Button, Flex, Skeleton, Text, TrashCanIcon} from "@fluentui/react-northstar";

export const TemplateCard = memo((props: Props): ReactElement | null => {
    const [, dispatch] = useMagicReducer(reducer(props), initialState);

    return (
        <Flex className={"template-card"} vAlign={"center"} onClick={dispatch("selectCard")}>
            <Skeleton animation={"pulse"}>
                <Flex fill column className={"overflow-hidden"}>
                    {!props.notification ?
                        <Skeleton.Line styles={{minHeight: "15px", marginBottom: "5px"}}/>
                        :
                        <Text weight={"semibold"} content={props.notification?.title}/>
                    }
                    {!props.notification ?
                        <Skeleton.Line styles={{minHeight: "17px", margin: 0}}/>
                        :
                        <Text content={props.notification?.subtitle}/>
                    }
                </Flex>
            </Skeleton>
            {props.notification &&
                <Flex className={"template-card-actions"}>
                    <Button iconOnly text icon={<TrashCanIcon outline/>} onClick={dispatch("deleteCard")}/>
                </Flex>
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);