import {AxiosConfig} from "../AxiosConfig/AxiosConfig";

const getGraphToken = async (): Promise<string | null> => {
    try {
        const response = await AxiosConfig.instance.get("/token/graph");
        return response.data;
    } catch (_) {
        return null;
    }
}

const saveToken = async (): Promise<void> => {
    try {
        await AxiosConfig.instance.get("/token/store");
    } catch (_) {
        console.error("Can't save token");
    }
}

export const TokenApi = {
    saveToken,
    getGraphToken,
}