import {Immutable} from "@witivio_teamspro/use-reducer";
import {translations} from "translations";
import {Flex, Skeleton} from "@fluentui/react-northstar";
import React, {useMemo} from "react";
import {Props} from "../ItemsStatsKpi.interfaces";
import {ItemDataModule} from "../../../../../modules/ItemData.module";
import {ItemData} from "common";
import {IListColumn} from "../../../../../components/lists/List/List.types";

export const useItemStatsTableColumns = (props: Props): Array<IListColumn<Immutable<ItemData> | null>> => {
    return useMemo(() => [
        generateTitleColumn(),
        generateLikesColumn(),
        generateCommentsColumn(),
        generateClicksColumn(),
        generateReturnRateColumn(),
    ], []);
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateTitleColumn = (): IListColumn<Immutable<ItemData> | null> => ({
    field: "title",
    label: translations.get("Title"),
    render: i => i?.title ?? "",
    sort: (a, b) => ItemDataModule.removeEmojis(a?.title ?? "").localeCompare(ItemDataModule.removeEmojis(b?.title ?? "")),
    minWidth: "150px",
    maxWidth: "35%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex fill>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    getFilterValue: i => i?.title ?? "",
    onClick: i => i?.link ? window.open(i?.link, "_blank") : 0,
});

const generateLikesColumn = (): IListColumn<Immutable<ItemData> | null> => ({
    field: "likes",
    label: translations.get("Likes"),
    render: i => i?.likes.length ?? "0",
    sort: (a, b) => (a?.likes.length ?? 0) - (b?.likes.length ?? 0),
    minWidth: "80px",
    maxWidth: "15%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex fill>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    getFilterValue: i => i?.likes + "",
});

const generateCommentsColumn = (): IListColumn<Immutable<ItemData> | null> => ({
    field: "comments",
    label: translations.get("Comments"),
    render: i => i?.comments ?? "0",
    sort: (a, b) => (a?.comments ?? 0) - (b?.comments ?? 0),
    minWidth: "120px",
    maxWidth: "20%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex fill>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    getFilterValue: i => i?.comments + "",
});

const generateClicksColumn = (): IListColumn<Immutable<ItemData> | null> => ({
    field: "clicks",
    label: translations.get("Clicks"),
    render: i => i?.clicks ?? "0",
    sort: (a, b) => (a?.clicks ?? 0) - (b?.clicks ?? 0),
    minWidth: "90px",
    maxWidth: "15%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex fill>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    getFilterValue: i => i?.clicks + "",
});

const generateReturnRateColumn = (): IListColumn<Immutable<ItemData> | null> => ({
    field: "returnRate",
    label: translations.get("ReturnRate"),
    render: i => (i?.returnRate ?? "0") + "%",
    sort: (a, b) => (a?.returnRate ?? 0) - (b?.returnRate ?? 0),
    minWidth: "100px",
    maxWidth: "15%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex fill>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    getFilterValue: i => i?.returnRate + "",
});