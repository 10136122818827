import React, {memo} from "react";
import {Props} from "./InfoTooltip.types";
import {CompareModule} from "modules/Compare.module";
import {Button, Flex, InfoIcon, Text, Tooltip} from "@fluentui/react-northstar";

export const InfoTooltip = memo((props: Props) => {
    return (
        <Tooltip
            trigger={
                <Button
                    text iconOnly
                    icon={<InfoIcon outline/>}
                />
            }
            content={
                <Flex column className={"overflow-scroll"} styles={{maxWidth: "300px"}}
                      gap={"gap.smaller"}>
                    {props.content.split("\n").map((line, index) => (
                        <Text
                            key={index}
                            size={"small"}
                            dangerouslySetInnerHTML={{__html: line}}
                            styles={(line.startsWith("-") || line.startsWith(">")) ? {marginLeft: "10px"} : {}}
                        />
                    ))}
                </Flex>
            }
            pointing
            align={"start"}
            styles={{maxWidth: "300px"}}
        />
    )
}, CompareModule.areObjectsEqual);