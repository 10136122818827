import {State} from "./FilePreviewDialog.types";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {translations} from "../../../translations";
import * as microsoftTeams from "@microsoft/teams-js";
import {FileModule} from "../../../modules/File.module";
import {FileData, FileType} from "common";
import {GraphApi} from "../../../apis/Graph/GraphApi";

export const initialState: State = {
    loaded: false,
    error: "",
    file: undefined,
    blockActions: false,
    animationEnded: false,
    iframe: undefined,
    hideActions: false,
}

export const reducer = (config: {
    isTeamsApp: boolean | undefined,
    isOnMobile: boolean,
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    iframeRef: ({setState}, [iframe]: [HTMLIFrameElement]) => {
        setState({iframe}, false);
    },
    open: async (reducerData, _, {file, hideActions}: { file: Immutable<FileData>, hideActions?: boolean }) => {
        const {setState} = reducerData;
        setState({...initialState as Immutable<State>, file, hideActions});
        switch (file.fileType) {
            case FileType.OneNote:
                const previewUrl = await GraphApi.getFilePreviewUrl(file);
                window.open(previewUrl, "_blank");
                break;
            case FileType.Image:
                const downloadUrl = file.content ?? await GraphApi.getFileDownloadUrl(file);
                if (config.isTeamsApp) {
                    microsoftTeams.media.viewImages([{
                        type: microsoftTeams.media.ImageUriType.URL,
                        value: downloadUrl ?? ""
                    }], async (err) => {
                        if (!err?.errorCode) return;
                        config.dialogRef.dispatch("open")();
                        await reducer(config).loadFile(reducerData);
                    });
                } else {
                    config.dialogRef.dispatch("open")();
                    await reducer(config).loadFile(reducerData);
                }
                break;
            case FileType.Word:
            case FileType.Excel:
            case FileType.PowerPoint:
                if (config.isTeamsApp && file.url) await FileModule.openFileInStageView(file.url);
                else {
                    config.dialogRef.dispatch("open")();
                    await reducer(config).loadFile(reducerData);
                }
                break;
            default:
                config.dialogRef.dispatch("open")();
                await reducer(config).loadFile(reducerData);
                break;
        }
    },
    loadFile: async ({state, setState}) => {
        if (!state.file) return;
        await loadFile(state, (error) => setState({loaded: true, error}));
    },
    handleEditFile: async ({state, setState}, _, app: "web" | "desktop") => {
        if (state.blockActions || !state.file) return;
        setState({blockActions: true}, false);
        if (app === "web") await FileModule.openFileInWebBrowser(state.file);
        else await FileModule.openFileInDesktopApp(state.file);
        setState({blockActions: false}, false);
    },
    handleDownload: async ({state, setState}) => {
        if (state.blockActions || !state.file) return;
        setState({blockActions: true}, false);
        await FileModule.downloadFile(state.file.id);
        setState({blockActions: false}, false);
    },
    handleOpenParentFolder: async ({state, setState}) => {
        if (state.blockActions || !state.file) return;
        setState({blockActions: true}, false);
        await FileModule.openFileParentFolder(state.file);
        setState({blockActions: false}, false);
    },
    reset: ({setState}) => {
        setState({...initialState});
    }
}) satisfies MagicReducerObject<State>;

const loadFile = async (state: Immutable<State>, callback: (error: string) => void) => {
    let previewUrl: string | undefined;
    const initializeIframe = () => {
        if (previewUrl === undefined) return;
        const iframe = state.iframe as HTMLIFrameElement;
        let error = "";
        if (!iframe || !previewUrl || !state.file) error = translations.get("AnErrorOccured");
        else {
            if (previewUrl.startsWith("data")) {
                const generatedContent = generateIframeContentFromBase64(state.file);
                iframe.srcdoc = generatedContent.content;
                error = generatedContent.error;
            } else iframe.src = previewUrl;
        }
        callback(error);
    }
    let animationEndTimeout: NodeJS.Timeout | undefined = setTimeout(() => {
        animationEndTimeout = undefined;
        initializeIframe();
    }, 200);
    previewUrl = state.file?.content ?? await getFilePreviewUrl(state.file);
    if (animationEndTimeout === undefined) initializeIframe();
}

const getFilePreviewUrl = async (file: Immutable<FileData> | undefined) => {
    if (!file) return "";
    const previewUrl = await GraphApi.getFilePreviewUrl(file);
    return previewUrl ?? "";
}

const generateIframeContentFromBase64 = (fileData: Immutable<FileData>): { content: string, error: string } => {
    let content: string;
    switch (fileData.fileType) {
        case FileType.Image:
            content = `<img alt="${fileData.name}" src="${fileData.content}" style="max-width: 100%; max-height: 100%"/>`;
            break;
        case FileType.Video:
            content = `<video controls autoplay src="${fileData.content}" style="max-width: 100%; max-height: 100%"/>`;
            break;
        case FileType.Pdf:
            content = `<embed src="${fileData.content}" width="100%" height="100%"/>`;
            break;
        case FileType.Sound:
            content = `<audio src="${fileData.content}" autoplay controls/>`;
            break;
        default:
            return {content: "", error: translations.get("PreviewNotAvailable")}
    }
    const html = `
        <html style="width: 100%; height: 100%;">
            <body style="margin: 0; padding: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; touch-action: pinch-zoom; overflow: auto; -webkit-touch-callout: default;">
                ${content}
            </body>
        </html>
    `;
    return {content: html, error: ""};
}