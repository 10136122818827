import {State} from "./FilterMobileDialog.types";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {
    openMenuIds: [],
}

export const reducer = (dialogRef: MagicReducerRef<typeof Dialog>) => ({
    clearOpenMenus: ({setState}) => {
        setState({openMenuIds: new Array<string>()});
    },
    toggleMenu: ({setState, state}, _, menuId: string) => {
        let openMenuIds = [...state.openMenuIds];
        if (openMenuIds.includes(menuId)) openMenuIds = openMenuIds.filter(id => id !== menuId);
        else openMenuIds.push(menuId);
        setState({openMenuIds});
    }
}) satisfies MagicReducerObject<State>;