import React, {memo, ReactElement} from "react";
import {Props} from "./Images.types";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Images.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Images.styles.scss";
import {Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../../translations";
import {InfoTooltip} from "../../../../others/InfoTooltip/InfoTooltip";

export const Images = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const {data} = props.form.state;

    const imagesCount = Object.keys(data).filter(key => key.startsWith("image") && !!((data as any)[key])).length;

    return (
        <Flex fill column gap={"gap.small"} className={"share-item-dialog-images-part"}>
            <Flex gap={"gap.small"} vAlign={"center"} style={{minHeight: "32px"}}>
                <Text
                    className={"primary-color"}
                    weight={"semibold"}
                    content={translations.get("IllustrativePictures") + " (" + imagesCount + "/4)"}
                    size={"large"}
                />
                <Flex styles={{marginLeft: "-10px", marginBottom: "-5px"}}>
                    <InfoTooltip content={translations.get("ShareItemDialogIllustrationsTooltip")}/>
                </Flex>
            </Flex>
            <Flex fill className={"share-item-dialog-images-part-grid"}>
                {props.form.formItems.image1}
                {props.form.formItems.image2}
                {props.form.formItems.image3}
                {props.form.formItems.image4}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);