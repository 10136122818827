import {Notification} from "common";
import {NotificationsApi} from "../../apis/Notifications/NotificationsApi";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {useQuery, useQueryClient} from "@tanstack/react-query";

const notificationTemplatesCacheKey = "notification-templates";

export const useNotificationTemplatesCache = () => {
    const queryClient = useQueryClient();
    const {data: templates} = useQuery({
        queryKey: [notificationTemplatesCacheKey],
        queryFn: NotificationsApi.getTemplates,
        staleTime: Infinity
    });

    const addTemplate = async (newTemplate: Notification) => {
        const templates = await NotificationsApi.addTemplate(newTemplate);
        updateTemplates(templates);
    }

    const deleteTemplate = async (templateId: string) => {
        const templates = await NotificationsApi.deleteTemplate(templateId);
        updateTemplates(templates);
    }

    const updateTemplates = (newTemplates: Immutable<Array<Notification>>) => {
        queryClient.setQueryData([notificationTemplatesCacheKey], newTemplates);
    };

    return {
        templates,
        addTemplate,
        deleteTemplate,
    }
}